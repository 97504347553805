<template>
  <v-footer
    app
    bottom
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-col
        class="py-4 text-center"
        cols="12"
      >
        <span class="footer-text"> Compliments of Smart Mawaid System © </span>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
<style scoped>
  .footer-text{
    color:#8c9396
  }
.footer {
  bottom: 0 !important;
  left: 0 !important;
  background-color: #f4f4f4;
  text-align: center;
}
</style>
