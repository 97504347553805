<template>
  <v-progress-circular
    v-if="isLoading"
    class="mx-auto my-10 py-10 d-flex align-self-center"
    color="green"
    indeterminate
  />
  <v-chart
    v-else
    class="canvas mx-auto"
    :option="options"
    autoresize
  />
</template>

<script>

export default {
  name: 'PantryOverviewChart',
  props: {
    isLoading: Boolean,
    options: {
      default () {
        return { }
      },
      type: Object
    }
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
  }
}
</script>

<style scoped>
    .canvas {
        height: 220px;
        max-width: 350px;
    }
</style>
