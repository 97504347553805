<template>
  <div id="wrapper">
    <v-row
      class="mx-auto d-flex"
      justify="center"
    >
      <v-col
        cols="10"
      >
        <v-row class="mx-0">
          <v-col
            cols="6"
            lg="4"
            md="6"
            sm="6"
            xs="6"
          >
            <TotalIngredients :is-loading="isLoading" />
          </v-col>
          <v-col
            cols="6"
            lg="4"
            md="6"
            sm="6"
            xs="6"
          >
            <OutOfStock :is-loading="isLoading" />
          </v-col>
          <v-col
            cols="6"
            lg="4"
            md="6"
            sm="6"
            xs="6"
          >
            <InStock :is-loading="isLoading" />
          </v-col>
          <v-col
            cols="6"
            lg="4"
            md="6"
            sm="6"
            xs="6"
          >
            <BelowMinimum :is-loading="isLoading" />
          </v-col>
          <v-col
            cols="6"
            lg="4"
            md="6"
            sm="6"
            xs="6"
          >
            <DeadStock :is-loading="isLoading" />
          </v-col>
        </v-row>
        <v-row
          id="v-step-0"
          class="mx-0"
        >
          <v-col
            cols="12"
            lg="4"
            md="12"
            sm="12"
          >
            <v-card class="graph-card">
              <div class="card-box">
                <PantryOverviewChart
                  :is-loading="solidGraphLoader"
                  :options="purchasedSolidIngredientGraphOptions"
                />
              </div>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="12"
            sm="12"
          >
            <v-card class="graph-card">
              <div class="card-box">
                <PantryOverviewChart
                  :is-loading="liquidGraphLoader"
                  :options="purchasedLiquidIngredientGraphOptions"
                />
              </div>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="12"
            sm="12"
          >
            <v-card class="graph-card">
              <div class="card-box mb-0">
                <PantryOverviewChart
                  :is-loading="expiryGraphLoader"
                  :options="ingredientsOfExpirationGraphOptions"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          class="mx-auto"
          no-gutters
        >
          <v-col
            id="v-step-1"
            cols="12"
            lg="6"
            md="12"
            sm="12"
            class="pa-3"
          >
            <v-card class="mx-auto">
              <div class="card-box">
                <v-row class="pa-2 mx-1">
                  <h4 class="mb-5">
                    Ingredients
                  </h4>
                  <v-spacer />
                  <!--  <IngredientDialog class="d-flex ingredient-dialog invisible flex-row justify-end" />-->
                </v-row>
                <v-data-table
                  height="400px"
                  :headers="header"
                  :loading="isLoading"
                  fixed-header
                  calculate-widths
                  :item-class="outOfStockBg"
                  :items="ingredients"
                  :search="search"
                  multi-sort
                  class="px-3 table-striped table-bordered dt-responsive"
                >
                  <template #top>
                    <v-row
                      v-if="isLoading === false"
                      class="mt-2 d-flex align-start justify-start"
                    >
                      <v-col
                        class="d-flex justify-end align-center"
                        cols="7"
                      >
                        <v-text-field
                          v-model="search"
                          clear-icon="mdi-close-circle-outline"
                          clearable
                          dense
                          label="Search Ingredients"
                          prepend-inner-icon="mdi-magnify"
                          solo
                        />
                      </v-col>
                      <v-col
                        id="v-step-2"
                        cols="1"
                      >
                        <v-btn
                          v-if="isAccessible"
                          class="primary"
                          @click="exportIngredients"
                        >
                          Export
                          <v-icon
                            right
                            dark
                          >
                            mdi-table-arrow-right
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <template #item.totalQty="{item}">
                    {{ getTotalQty(item) }}
                  </template>
                  <template #item.unitId="{item}">
                    {{ getUnit(item) }}
                  </template>
                  <template #item.alias="{item}">
                    {{ getAlias(item) }}
                  </template>
                  <template #item.minQty="{item}">
                    {{ getMinQty(item) }}
                  </template>
                  <!-- <template #item.categoryId="{ item }">
                    {{
                      item.categoryId
                        ? getCategoryById(item.categoryId).categoryName
                        : '-'
                    }}
                  </template>-->
                  <template #item.addToStock="{ item }">
                    <v-btn
                      v-if="item.totalQty === 0 || item.totalQty < item.minQty"
                      small
                      text
                      @click="openDialog(item)"
                    >
                      <span class="outofstock-style">Add to Stock</span>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>
          <v-col
            id="v-step-3"
            cols="12"
            lg="6"
            md="12"
            sm="12"
            class="pa-3"
          >
            <Inventory />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :color="
        snackType === 'success'
          ? 'green'
          : snackType === 'error'
            ? 'red'
            : 'yellow'
      "
      :timeout="1500"
    >
      {{ snackText }}
    </v-snackbar>
  </div>
</template>

<script>
import TotalIngredients from './components/TotalIngredients'
import OutOfStock from './components/OutOfStock'
import DeadStock from './components/DeadStock'
import InStock from './components/InStock'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import BelowMinimum from './components/BelowMinimum'
import Inventory from '../Inventory/Inventory'
import PantryOverviewChart from './components/PantryOverviewChart'
import apiMixin from '../../utilities/mixins/apiMixin'
import roleMixin from '../../utilities/mixins/roleMixin'
import { getFileName } from '@/utilities/fileNameUtil'
import { getMinQtyWithUnit, getTotalQty, getUnit } from '@/utilities/unitConverter'
import moment from 'moment'

const JSONToCSV = require('json2csv').parse

export default {
  name: 'Dashboard',
  components: {
    PantryOverviewChart,
    Inventory,
    BelowMinimum,
    InStock,
    OutOfStock,
    DeadStock,
    TotalIngredients
  },
  mixins: [apiMixin, roleMixin],
  data () {
    return {
      isLoading: false,
      addDialog: false,
      search: '',
      header: [
        {
          id: uuidv4(),
          text: 'Ingredient Name',
          align: 'start',
          sortable: true,
          value: 'ingredientName',
          width: '150px'
        },
        {
          id: uuidv4(),
          text: 'Local Alias',
          align: 'start',
          sortable: true,
          value: 'alias',
          width: '20%'
        },
        {
          id: uuidv4(),
          text: 'Minimum Qty',
          sortable: true,
          value: 'minQty',
          width: '10%'
        },
        {
          id: uuidv4(),
          text: 'Available Qty',
          sortable: true,
          value: 'totalQty',
          width: '10%'
        },
        {
          id: uuidv4(),
          text: 'Unit',
          sortable: true,
          value: 'unitId',
          width: '10%'
        },
        {
          id: uuidv4(),
          text: '',
          sortable: false,
          value: 'addToStock',
          width: '50px'
        }

      ],
      snackText: '',
      snackType: 'success',
      snackbar: false,
      stockInLoader: false,
      editingItem: {},
      expiryData: [],
      expiryIngredients: []
    }
  },
  computed: {
    ...mapGetters({
      ingredients: 'inventory/getIngredientsByCompany',
      allIngredients: 'inventory/getAllIngredients',
      categoryList: 'inventory/getAllCategories',
      getCategoryById: 'inventory/getCategoryById',
      getUnitById: 'inventory/getUnitById',
      getUserInfoByKey: 'auth/getUserInfoByKey',
      getWeeklyStockActivityByType: 'reports/getWeeklyStockActivityByType',
      getProductsByIngredientId: 'inventory/getProductsByIngredientId',
      demoMode: 'config/getDemoMode',
      getBrandById: 'inventory/getBrandById',
      getIngredientByName: 'inventory/getIngredientByName',
      graphData: 'reports/getGraphData',
      purchasedSolidIngredientGraphOptions: 'reports/getPurchasedSolidIngredientGraphOptions',
      purchasedLiquidIngredientGraphOptions: 'reports/getPurchasedLiquidIngredientGraphOptions',
      liquidGraphLoader: 'reports/getLiquidGraphLoader',
      solidGraphLoader: 'reports/getSolidGraphLoader',
      ingredientsOfExpirationGraphOptions: 'reports/getExpiryGraphOptions',
      expiryGraphLoader: 'reports/getExpiryGraphLoader'
    })
  },
  async mounted () {
    this.isLoading = true
    await this.$nextTick()
    if (this.$tours && this.$tours.sm_onboarding) {
      this.$tours.sm_onboarding.start()
    }
    this.isLoading = false
  },

  methods: {
    getAlias (item) {
      if (item.alias && item.alias.length > 0) {
        return item.alias.join(', ')
      } else {
        return ''
      }
    },
    getMinQty (ingredient) {
      return getMinQtyWithUnit(ingredient)
    },
    getTotalQty (ingredient) {
      return getTotalQty(ingredient)
    },
    getUnit (ingredient) {
      return getUnit(ingredient)
    },
    outOfStock () {
      return this.ingredients.filter((ingredient) => {
        return ingredient.totalQty <= 0
      }).length
    },
    inStock () {
      return this.ingredients.filter((product) => {
        const qty = +product.totalQty
        return qty >= product.minQty
      }).length
    },
    belowMinimum () {
      return this.ingredients.filter((product) => {
        const qty = product.totalQty
        return qty < product.minQty && qty > 0
      }).length
    },
    getDaysToExpire (product) {
      const date = product.expiryDate
      if (date) {
        const expiryDate = moment(date, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'M-D-YYYY', 'D-M-YYYY', 'M-DD-YYYY', 'DD-M-YYYY'
        ])
        if (!expiryDate.isValid()) {
          debugger
          return 'NA'
        } else {
          const current = moment().startOf('day')
          // Difference in number of days
          return moment.duration(expiryDate.diff(current)).asDays()
        }
      }
      return 'NA'
    },

    exportIngredients () {
      const vm = this
      const head = ['ingredient', 'local_alias', 'product_name', 'brand', 'price', 'barcode', 'minimum_qty', 'available_qty', 'unit', 'days_to_expire']
      const data = []
      this.ingredients.forEach((ingredient) => {
        let rowData = {}
        const products = vm.getProductsByIngredientId(ingredient.ingredientId)
        if (products.length > 0) {
          products.forEach(product => {
            rowData.product_name = product.productName
            rowData.brand = vm.getBrandById(product.brandId).brandName
            rowData.barcode = product.barcodeId
            rowData.ingredient = ingredient.ingredientName
            rowData.price = product.price
            rowData.local_alias = this.getAlias(ingredient)
            rowData.minimum_qty = this.getMinQty(ingredient)
            rowData.available_qty = this.getTotalQty(ingredient)
            rowData.unit = this.getUnit(ingredient)
            rowData.days_to_expire = this.getDaysToExpire(product)
            data.push(rowData)
            rowData = {}
          })
        } else {
          rowData.product_name = '-'
          rowData.brand = '-'
          rowData.barcode = '-'
          rowData.price = '-'
          rowData.ingredient = ingredient.ingredientName
          rowData.local_alias = this.getAlias(ingredient)
          rowData.minimum_qty = this.getMinQty(ingredient)
          rowData.available_qty = this.getTotalQty(ingredient)
          rowData.unit = this.getUnit(ingredient)
          rowData.days_to_expire = '-'
          data.push(rowData)
          rowData = {}
        }
      })
      const csv = JSONToCSV(data, { fields: head })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = getFileName('ingredients-report', 'csv')
      anchor.click()
      this.showSnackNotification('Ingredients successfully exported', 'success')
    },

    openDialog (item) {
      this.editingItem = item
      this.$router.push('/stock-in')
    },
    showSnackNotification (text, type) {
      this.snackText = text
      this.snackType = type
      this.snackbar = true
    },
    outOfStockBg (item) {
      return item.totalQty === 0 || item.totalQty < item.minQty ? 'outofstock-style' : ''
    }
  }
}
</script>

<style>
.outofstock-style {
  color: #c93030;
}

.graph-card {
  width: 100%;
}
</style>
