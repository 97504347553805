import * as firebase from '../../firebase.config'
import router from '../router/index'

export const auth = {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    unsetCurrentUser (state) {
      localStorage.removeItem('user')
      state.user = null
    },
    setCurrentUser (state, payload) {
      if (payload !== null) {
        localStorage.setItem('user', JSON.stringify(payload))
        state.user = payload
      }
    }
  },
  actions: {
    async fetchCurrentUser ({ commit }) {
      commit('setCurrentUser', JSON.parse(localStorage.getItem('user')))
    },
    async signUserIn ({ commit }, payload) {
      firebase.auth.setPersistence(firebase.authSocial.Auth.Persistence.SESSION)
        .then((_) => firebase.auth.signInWithEmailAndPassword(payload.email, payload.password))
        .then((result) => {
          const currentUser = result
          // console.log(currentUser)
          const newUser = {
            id: currentUser.uid,
            name: currentUser.name || 'Demo',
            email: currentUser.email,
            image: currentUser.photoURL,
            admin_type: currentUser.admin_type || 0,
            company_id: currentUser.company_id || '4',
            contact: currentUser.contact || null,
            currency: currentUser.currency || 'INR',
            date_format: currentUser.date_format ? currentUser.date_format.toUpperCase() : 'YYYY-MM-DD',
            uom: currentUser.uom || '',
            company_name_slug: currentUser.company_name_slug || 'test-jamaat',
            jamaat_slug: currentUser.jamaat_slug || 'test-jamaat',
            company_name: currentUser.company_name || 'Demo Company',
            designation: currentUser.designation || 'Admin'
          }
          commit('setCurrentUser', newUser)
          router.replace('/dashboard')
        })
        .catch(
          error => {
            console.error(error)
          }
        )
    },
    async signUserOut ({ commit }) {
      firebase
        .auth
        .signOut()
        .then(() => {
          commit('unsetCurrentUser')
        })
    },
    async anonymousLogin ({ commit }, payload) {
      firebase.auth.setPersistence(firebase.authSocial.Auth.Persistence.SESSION)
        .then((_) => firebase.auth.signInAnonymously())
        .then(result => {
          // console.log(result)

          const newUser = {
            id: result.user.uid,
            smId: payload.id,
            name: payload.name,
            email: payload.email,
            image: '',
            admin_type: payload.admin_type,
            company_id: payload.company_id,
            contact: payload.contact,
            currency: payload.currency,
            company_name_slug: payload.company_name_slug,
            jamaat_slug: payload.jamaat_slug || 'test-jamaat',
            company_name: payload.company_name,
            designation: payload.designation,
            date_format: payload.date_format.toUpperCase(),
            uom: payload.uom,
            dead_stock_days: payload.dead_stock_days
          }
          commit('setCurrentUser', newUser)
          commit('config/setCompanyDB', 'prod')
          window.location.replace('/dashboard')
        })
        .catch(
          error => {
            console.error(error)
          }
        )
    }
  },
  getters: {
    getCurrentUser (state) {
      return state.user
    },
    getUserInfoByKey: (state) => (key) => {
      if (!state.user) {
        const user = {
          id: null,
          name: '',
          email: '',
          image: '',
          admin_type: 0,
          company_id: 0,
          contact: '',
          currency: 'INR',
          date_format: 'YYYY-MM-DD',
          uom: '',
          company_name_slug: '',
          jamaat_slug: '',

          company_name: '',
          dead_stock_days: '7'
        }
        return user[key]
      }
      return state.user[key]
    }
  }
}
