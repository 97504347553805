// src/firebaseConfig.js
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import store from './src/store/index'

var firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBAE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
const storage = firebase.storage()
firebase.analytics()

// utils
const db = firebase.firestore()
const firestoreNow = () => firebase.firestore.Timestamp.now()
const firestoreDateFrom = (date) => firebase.firestore.Timestamp.fromDate(date)
const fieldValue = firebase.firestore.FieldValue
const auth = firebase.auth()
const authSocial = firebase.auth
const getUserInfoByKey = store.getters['auth/getUserInfoByKey']
// collection references
const productCollection = () => {
  const DB = store.getters['config/getCompanyDB']
  return db.collection(DB + '/product')
}
const brandCollection = () => {
  const DB = store.getters['config/getCompanyDB']
  return db.collection(DB + '/brand')
}
const categoryCollection = () => {
  const DB = store.getters['config/getCompanyDB']
  return db.collection(DB + '/category')
}
const ingredientCollection = () => {
  const DB = store.getters['config/getCompanyDB']
  return db.collection(DB + '/ingredient')
}
const unitCollection = () => {
  const DB = store.getters['config/getCompanyDB']
  return db.collection(DB + '/unit')
}
const stockCollection = () => {
  const DB = store.getters['config/getCompanyDB']
  return db.collection(DB + '/stock')
}
const transactionCollection = () => {
  const DB = store.getters['config/getCompanyDB']
  return db.collection(DB + '/pantry-transaction')
}
const userDefinedProperties = () => {
  const DB = store.getters['config/getCompanyDB']
  return db.collection(DB + '/user_defined_properties')
}
const remarkCollection = () => {
  const DB = store.getters['config/getCompanyDB']
  return db.collection(DB + '/remark')
}
const recipeCollection = () => {
  const DB = store.getters['config/getCompanyDB']
  return db.collection(DB + '/recipe')
}
const configurationCollection = () => db.collection('company/' + getUserInfoByKey('company_name_slug') + '_' + getUserInfoByKey('company_id') + '/configuration')

export {
  fieldValue,
  db,
  auth,
  authSocial,
  productCollection,
  brandCollection,
  storage,
  categoryCollection,
  unitCollection,
  stockCollection,
  userDefinedProperties,
  ingredientCollection,
  remarkCollection,
  firestoreNow,
  firestoreDateFrom,
  configurationCollection,
  recipeCollection,
  transactionCollection
}
