<template>
  <v-app>
    <v-main>
      <Header />
      <router-view />
      <Footer />
    </v-main>
    <Dashboard />
    <StockIn />
    <StockOut />
    <Reports />
    <Audit />
  </v-app>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import Dashboard from './components/Onboarding/Dashboard'
import StockIn from './components/Onboarding/StockIn'
import StockOut from './components/Onboarding/StockOut'
import Reports from './components/Onboarding/Reports'
import Audit from './components/Onboarding/Audit'

export default {
  name: 'App',
  components: {
    Audit,
    Reports,
    Dashboard,
    StockIn,
    StockOut,
    Header,
    Footer
  },
  data: () => ({
    //
  }),
  async created () {
    await this.$store.dispatch('auth/fetchCurrentUser')
    await this.$store.dispatch('config/fetchConfiguration')
    this.googleTranslateElementInit()
  },
  methods: {
    googleTranslateElementInit () {
      window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          includedLanguages: 'ar,fr,hi,de,ur,gu'
        },
        'google_translate_element'
      )
    }
  }
}
</script>
<style>
@import "./assets/css/bootstrap.min.css";
@import "./assets/css/icons.min.css";
@import "./assets/css/app.css";

#wrapper {
  margin-top:150px;
}
body {
  padding: 0;
  overflow: hidden;
}
</style>
