import Vue from 'vue'
import Vuex from 'vuex'
import { inventory } from './inventory.module'
import { reports } from './reports.module'
import { auth } from './auth.module'
import { stockOut } from './stockOut.module'
import { stockIn } from './stockIn.module'
import { config } from './config.module'
import { recipe } from './recipe.module'
import { estimate } from './estimate.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    inventory,
    reports,
    auth,
    stockOut,
    stockIn,
    config,
    recipe,
    estimate
  }
})
