<template>
  <div id="wrapper">
    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card
          id="v-step-4"
          class="mx-auto mt-5"
        >
          <v-row class="mx-auto">
            <div class="d-flex align-center text-center">
              <h4 class="pl-7">
                Estimate Stock By Menu
              </h4>
            </div>
            <v-spacer />
            <div>
              <v-breadcrumbs
                :items="links"
                divider=">"
              />
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card class="mx-auto">
          <div class="card-box">
            <div>
              <v-row class="d-flex justify-end">
                <v-spacer />
                <StockEstimatorByMenuForm @calculateStock="(rp) => calculateStockEstimate(rp,'menu')" />
              </v-row>
              <v-row
                justify="center"
              >
                <v-col cols="12">
                  <v-data-table
                    id="v-step-3"
                    :headers="headers"
                    :items="estimatedIngredientsForPurchase"
                    :search="search"
                    class="px-3 table-striped table-bordered dt-responsive"
                  >
                    <template #top>
                      <v-row class="d-flex align-start justify-content-between mt-2">
                        <v-col
                          class="d-flex align-center"
                          cols="7"
                        >
                          <v-text-field
                            v-model="search"
                            dense
                            clear-icon="mdi-close-circle-outline"
                            clearable
                            label="Search"
                            prepend-inner-icon="mdi-magnify"
                            solo
                          />
                        </v-col>
                        <v-col class="d-flex">
                          <v-menu offset-y>
                            <template #activator="{ on, attrs }">
                              <v-btn
                                :disabled="estimatedIngredientsForPurchase.length === 0 || estimatedIngredientsForCook.length === 0"
                                :loading="exportLoader"
                                outlined
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span><v-icon>mdi-share-variant</v-icon> Export</span>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(item, index) in exportMenu"
                                :key="index"
                                @click="exportIngredients(item.type)"
                              >
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </template>
                    <template #item.alias="{item}">
                      {{ getAlias(item) }}
                    </template>
                    <template #item.qty="{item}">
                      {{ getTotalQty(item, 'qty') }}
                      {{ getUnit(item, 'qty') }}
                    </template>
                    <!--                    <template #item.perPersonQty="{ item }">
                      {{ getTotalQty(item.measure_type, item.perPersonQty) }}
                      {{ getUnit(item.measure_type, item.perPersonQty) }}
                    </template>-->
                    <!--                    <template #item.currentQty="{ item }">
                      {{ getTotalQty(item.measure_type, getCurrentQty(item.ingredientId)) }}
                      {{ getUnit(item.measure_type, getCurrentQty(item.ingredientId)) }}
                    </template>
                    <template #item.deducedQty="{ item }">
                      {{ getTotalQty(item.measure_type, getDeducedQty(item.qty, item.ingredientId)) }}
                      {{ getUnit(item.measure_type, getDeducedQty(item.qty, item.ingredientId)) }}
                    </template>-->
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="1500"
      color="green"
    >
      {{ snackText }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import apiMixin from '../../utilities/mixins/apiMixin'
import { parse as JSONToCSV } from 'json2csv'
import JsPdf from 'jspdf'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import StockEstimatorByMenuForm from '@/views/StockEstimate/components/StockEstimatorByMenuForm'
import { getFileName } from '@/utilities/fileNameUtil'
import { getTotalQty, getUnit } from '@/utilities/unitConverter'

export default {
  name: 'StockEstimateByMenu',
  components: {
    StockEstimatorByMenuForm
  },
  mixins: [apiMixin],
  data () {
    return {
      exportLoader: false,
      exportMenu: [
        {
          title: 'Export PDF for Cook',
          type: 'PDF-COOK'
        },
        {
          title: 'Export PDF for Purchasing Agent',
          type: 'PDF-PURCHASE'
        },
        {
          title: 'Export CSV for Cook',
          type: 'CSV-COOK'
        },
        {
          title: 'Export CSV for Purchasing Agent',
          type: 'CSV-PURCHASE'
        }
      ],
      valid: false,
      notEmptyRule: [(v) => !!v || ' This field is required'],
      countRule: [(v) => !!v || 'This field is required', (v) => v > 0 || 'Value should be greater than 0'],
      errorSnackBar: false,
      snackbar: false,
      snackText: '',
      selectedRecipes: [],
      search: '',
      headers: [
        {
          text: 'Item Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Local Alias',
          sortable: true,
          value: 'alias'
        },
        /* {
          text: 'Qty (per person)',
          sortable: true,
          value: 'perPersonQty'
        }, */
        {
          text: 'Total Qty',
          sortable: true,
          value: 'qty'
        }
        /* {
          text: 'Current Qty',
          sortable: true,
          value: 'currentQty'
        },
        {
          text: 'Qty After Estimation',
          sortable: true,
          value: 'deducedQty'
        } */
      ],
      links: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/dashboard'
        },
        {
          text: 'Estimate Stock By Menu',
          disabled: false,
          href: '/estimate-menu'
        }
      ],
      recipeLoader: false,
      calculationType: 'recipe'
    }
  },
  computed: {
    ...mapGetters({
      recipes: 'recipe/getAllRecipes',
      estimatedIngredients: 'estimate/getEstimateByType',
      getIngredientById: 'inventory/getIngredientById',
      getUserInfoByKey: 'auth/getUserInfoByKey',
      productList: 'inventory/getAllProducts'
    }),
    estimatedIngredientsForCook () {
      return this.estimatedIngredients('cook')
    },
    estimatedIngredientsForPurchase () {
      return this.estimatedIngredients('purchase')
    }
  },
  watch: {},
  created () {

  },
  destroyed () {

  },
  async mounted () {
    await this.$store.dispatch('inventory/fetchAllIngredients')
    await this.$store.dispatch('recipe/fetchAllRecipes')
    this.$store.commit('estimate/setStockEstimate', { finalIngredientList: [], ingredientsByRecipeList: [] })
  },
  methods: {
    getAlias (item) {
      if (item.alias && item.alias.length > 0) {
        return item.alias.join(', ')
      } else {
        return ''
      }
    },
    async calculateStockEstimate (recipes, type) {
      this.selectedRecipes = recipes
      this.calculationType = type
      await this.$store.dispatch('estimate/calculateStockEstimate', {
        recipeList: recipes,
        type
      })
    },
    getTotalQty (ingredient, qtyKey) {
      return getTotalQty(ingredient, qtyKey)
    },
    getUnit (ingredient, qtyKey) {
      return getUnit(ingredient, qtyKey)
    },
    async exportIngredients (type) {
      this.exportLoader = true
      try {
        const fileType = type.split('-')[0]
        const agentType = type.split('-')[1]
        if (agentType === 'COOK') {
          if (fileType === 'CSV') {
            await this.generateCsvForCook()
          } else {
            await this.generatePdfForCook()
          }
        } else {
          if (fileType === 'CSV') {
            await this.generateCsvForPurchase()
          } else {
            await this.generatePdfForPurchase()
          }
        }
        this.exportLoader = false
      } catch (e) {
        this.exportLoader = false
      }
    },
    async generateCsvForPurchase () {
      const head = ['ingredient', 'local_alias', 'product_name', 'brand_name', 'expiry_date', 'minQty', 'qty', 'unit', 'price']
      const data = []
      this.estimatedIngredientsForPurchase.forEach((ingredient) => {
        const ing = this.productList.find(ing => ing.ingredientId === ingredient.ingredientId)
        const rowData = {}
        rowData.ingredient = ingredient.name
        rowData.product_name = ingredient.name
        rowData.brand_name = 'Generic'
        rowData.expiry_date = ''
        rowData.minQty = 100
        rowData.price = ing.price + '/' + ing.priceUnit
        rowData.unit = `${this.getUnit(ingredient, 'qty')}`
        rowData.local_alias = this.getAlias(ingredient)
        /* rowData['Qty (per person)'] = `${this.getTotalQty(ingredient.measure_type, ingredient.perPersonQty)} ${this.getUnit(ingredient.measure_type, ingredient.perPersonQty)}`
        */
        rowData.qty = `${this.getTotalQty(ingredient, 'qty')}`
        /* rowData['Current Qty'] = `${this.getTotalQty(ingredient.measure_type, this.getCurrentQty(ingredient.ingredientId))} ${this.getUnit(ingredient.measure_type, this.getCurrentQty(ingredient.ingredientId))}`
        rowData['Qty After Estimation'] = `${this.getTotalQty(ingredient.measure_type, this.getDeducedQty(ingredient.qty, ingredient.ingredientId))} ${this.getUnit(ingredient.measure_type, this.getDeducedQty(ingredient.qty, ingredient.ingredientId))}`
        */
        data.push(rowData)
      })
      const csv = JSONToCSV(data, { fields: head })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = getFileName('stock-estimate-menu-purchase', 'csv')
      anchor.click()
    },
    async generateCsvForCook () {
      const head = ['ingredient', 'local_alias', 'product_name', 'brand_name', 'expiry_date', 'minQty', 'qty', 'unit', /* 'price' */ 'recipe_name']
      const data = []
      this.estimatedIngredientsForCook.forEach((ingredient) => {
        // const ing = this.productList.find(ing => ing.ingredientId === ingredient.ingredientId)
        const rowData = {}
        rowData.ingredient = ingredient.name
        rowData.product_name = ingredient.name
        rowData.brand_name = 'Generic'
        rowData.expiry_date = ''
        rowData.minQty = 100
        // rowData.price = ing.price + '/' + ing.priceUnit
        rowData.unit = `${this.getUnit(ingredient, 'qty')}`
        rowData.local_alias = this.getAlias(ingredient)
        rowData.recipe_name = ingredient.recipeName
        /* rowData['Qty (per person)'] = `${this.getTotalQty(ingredient.measure_type, ingredient.perPersonQty)} ${this.getUnit(ingredient.measure_type, ingredient.perPersonQty)}`
        */
        rowData.qty = `${this.getTotalQty(ingredient, 'qty')}`
        /* rowData['Current Qty'] = `${this.getTotalQty(ingredient.measure_type, this.getCurrentQty(ingredient.ingredientId))} ${this.getUnit(ingredient.measure_type, this.getCurrentQty(ingredient.ingredientId))}`
        rowData['Qty After Estimation'] = `${this.getTotalQty(ingredient.measure_type, this.getDeducedQty(ingredient.qty, ingredient.ingredientId))} ${this.getUnit(ingredient.measure_type, this.getDeducedQty(ingredient.qty, ingredient.ingredientId))}`
        */
        data.push(rowData)
      })
      const csv = JSONToCSV(data, { fields: head })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = getFileName('stock-estimate-menu-cook', 'csv')
      anchor.click()
    },
    generatePdfForPurchase () {
      const vm = this
      const columns = cloneDeep(this.headers.map(header => {
        const data = {}
        data.title = header.text
        data.dataKey = header.text
        return data
      }))
      columns.push({
        title: 'Price',
        dataKey: 'price'
      })
      const recipeColumns = [
        {
          title: 'Recipe Name',
          dataKey: 'recipeName'
        }, {
          title: 'No of People',
          dataKey: 'noOfPeople'
        }
      ]
      const doc = new JsPdf({
        theme: 'grid',
        orientation: 'portrait',
        unit: 'in',
        format: 'a4'
      })
      // text is placed using x, y coordinates
      doc.setTextColor('#cb8037')
      doc.setFont('helvetica', 'bold').setFontSize(22).text(`${this.getUserInfoByKey('company_name')} Stock Estimate Report`, 0.5, 0.5)
      doc.setLineWidth(0.01).line(0.5, 0.7, 8.0, 0.7)
      // create a line under heading
      doc.setFont('helvetica').setFontSize(10).text(`Date:  ${moment().format(this.getUserInfoByKey('date_format'))}`, 7.0, 0.3)

      doc.setFont('helvetica', 'bold').setFontSize(15).text('Recipes', 0.5, 1.3)
      doc.setLineWidth(0.01).line(0.5, 1.5, 4.0, 1.5)

      const recipeData = cloneDeep(this.selectedRecipes.map((element) => {
        const rowData = {}
        if (vm.calculationType === 'menu') {
          rowData.recipeName = `${element.recipe_title}`
        } else {
          rowData.recipeName = `${element.selectedRecipe.recipe_title} X ${element.noOfDays}`
        }
        rowData.noOfPeople = element.noOfPeople
        return rowData
      }))
      doc.autoTable({
        headStyles: { fillColor: '#cb8037' },
        columns: recipeColumns,
        body: recipeData,
        margin: { left: 0.5, top: 1.8, bottom: 2.0 },
        didDrawPage: function (data) {
          data.settings.margin.top = 1
        }
      })
      // Using autoTable plugin
      const data = cloneDeep(this.estimatedIngredientsForPurchase.map((element) => {
        const ing = this.productList.find(ing => ing.ingredientId === element.ingredientId)
        const rowData = {}
        rowData['Item Name'] = element.name
        rowData['Local Alias'] = this.getAlias(element)
        rowData['Qty (per person)'] = `${this.getTotalQty(element, 'perPersonQty')} ${this.getUnit(element, 'perPersonQty')}`
        rowData['Total Qty'] = `${this.getTotalQty(element, 'qty')} ${this.getUnit(element, 'qty')}`
        /* rowData['Current Qty'] = `${this.getTotalQty(element.measure_type, this.getCurrentQty(element.ingredientId))} ${this.getUnit(element.measure_type, this.getCurrentQty(element.ingredientId))}`
        rowData['Qty After Estimation'] = `${this.getTotalQty(element.measure_type, this.getDeducedQty(element.qty, element.ingredientId))} ${this.getUnit(element.measure_type, this.getDeducedQty(element.qty, element.ingredientId))}`
       */
        rowData.price = `${ing.price} / ${ing.priceUnit}`
        return rowData
      }))
      doc.autoTable({
        headStyles: { fillColor: '#cb8037' },
        columns,
        body: data,
        margin: { left: 0.5, top: 2.5, bottom: 2 },
        didDrawPage: function (data) {
          data.settings.margin.top = 1
        }
      })
      this.addFooters(doc)
      // Creating footer and saving file
      doc.save(getFileName('stock-estimate-menu-report-purchase', 'pdf'))
    },
    generatePdfForCook () {
      const columns = cloneDeep(this.headers.map(header => {
        const data = {}
        data.title = header.text
        data.dataKey = header.text
        return data
      }))
      const doc = new JsPdf({
        theme: 'grid',
        orientation: 'portrait',
        unit: 'in',
        format: 'a4'
      })
      // text is placed using x, y coordinates
      doc.setTextColor('#cb8037')
      doc.setFont('helvetica', 'bold').setFontSize(22).text(`${this.getUserInfoByKey('company_name')} Stock Estimate Report`, 0.5, 0.5)
      doc.setLineWidth(0.01).line(0.5, 0.7, 8.0, 0.7)
      // create a line under heading
      doc.setFont('helvetica').setFontSize(10).text(`Date:  ${moment().format(this.getUserInfoByKey('date_format'))}`, 7.0, 0.3)

      // Using autoTable plugin
      const data = cloneDeep(this.estimatedIngredientsForCook.map((element) => {
        const rowData = {}
        rowData['Item Name'] = element.name
        rowData['Local Alias'] = this.getAlias(element)
        rowData['Qty (per person)'] = `${this.getTotalQty(element, 'perPersonQty')} ${this.getUnit(element, 'perPersonQty')}`
        rowData['Total Qty'] = `${this.getTotalQty(element, 'qty')} ${this.getUnit(element, 'qty')}`
        rowData['Recipe Name'] = element.recipeName
        /* rowData['Current Qty'] = `${this.getTotalQty(element.measure_type, this.getCurrentQty(element.ingredientId))} ${this.getUnit(element.measure_type, this.getCurrentQty(element.ingredientId))}`
        rowData['Qty After Estimation'] = `${this.getTotalQty(element.measure_type, this.getDeducedQty(element.qty, element.ingredientId))} ${this.getUnit(element.measure_type, this.getDeducedQty(element.qty, element.ingredientId))}`
       */
        return rowData
      }))
      const groupedData = this.groupBy(data, 'Recipe Name')
      groupedData.forEach(data => {
        const finalY = doc.lastAutoTable.finalY || 1
        doc.setFont('helvetica', 'bold').setFontSize(11).text('Recipe Name : ' + data[0]['Recipe Name'], 0.5, (finalY + 0.5))
        doc.autoTable({
          startY: finalY + 1,
          styles: { fontSize: 9 },
          headStyles: { fillColor: '#cb8037' },
          columns,
          body: data,
          margin: { left: 0.5, top: 1.5, bottom: 1.5 }
        })
      })
      this.addFooters(doc)
      // Creating footer and saving file
      doc.save(getFileName('stock-estimate-menu-report-cook', 'pdf'))
    },
    groupBy (arr, prop) {
      const map = new Map(Array.from(arr, obj => [obj[prop], []]))
      arr.forEach(obj => map.get(obj[prop]).push(obj))
      return Array.from(map.values())
    },
    addFooters (doc) {
      const pageCount = doc.internal.getNumberOfPages()

      doc.setFont('helvetica')
      doc.setFontSize(16)
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.setLineWidth(0.1).line(0.5, doc.internal.pageSize.height - 1, 8.0, doc.internal.pageSize.height - 1)
        doc.text(
          `\u00A9 ${moment().year()} — SM Pantry`,
          0.5,
          doc.internal.pageSize.height - 0.5
        )
        doc.text(
          `Page ${i}`,
          7.0,
          doc.internal.pageSize.height - 0.5, 'left'
        )
      }
    },
    getCurrentQty (ingredientId) {
      if (typeof this.getIngredientById(ingredientId) !== 'undefined') {
        return this.getIngredientById(ingredientId).totalQty
      } else {
        return 0
      }
    },
    getDeducedQty (qty, ingredientId) {
      if (typeof this.getIngredientById(ingredientId) !== 'undefined') {
        return this.getIngredientById(ingredientId).totalQty - qty
      } else {
        return 0
      }
    }
  }
}
</script>

<style scoped></style>
