import { mapGetters } from 'vuex'
const roleMixin = {
  computed: {
    ...mapGetters({
      getUserInfoByKey: 'auth/getUserInfoByKey'
    }),
    isAccessible () {
      const adminId = this.getUserInfoByKey('admin_type')
      return adminId === '1' || adminId === '2' || adminId === '5'
    }
  }
}

export default roleMixin
