<template>
  <v-tour
    v-if="onboardingStatus('reportOnboarding')"
    name="report"
    :options="options"
    :steps="steps"
    :callbacks="callbacks"
  />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Reports',
  data () {
    return {
      callbacks: {
        onFinish: this.finishOnboarding,
        onSkip: this.finishOnboarding
      },
      options: {
        useKeyboardNavigation: true,
        startTimeout: 1000,
        labels: {
          buttonSkip: 'Skip',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish'
        }
      },
      steps: [
        {
          target: '#v-step-0',
          header: {
            title: 'Select your ingredient!'
          },
          content: 'Choose one or more ingredients to fetch report data',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '#v-step-1',
          header: {
            title: 'Select your date'
          },
          content: 'Select a date or a range between custom dates to get the report for',
          params: {
            placement: 'left',
            enableScrolling: false
          }
        },
        {
          target: '#v-step-2',
          header: {
            title: 'Get analytical insights based on consumption!'
          },
          content: 'Displays forecast consumptions based on the actual consumption of an ingredient for efficient purchasing',
          params: {
            placement: 'bottom'
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
      onboardingStatus: 'config/getOnboardingStatus'
    })
  },
  mounted: function () {

  },
  methods: {
    async finishOnboarding () {
      await this.$store.dispatch('config/finishOnboarding', {
        key: 'reportOnboarding',
        companyId: this.user.company_name_slug + '_' + this.user.company_id
      })
    }
  }
}
</script>

<style scoped>

</style>
