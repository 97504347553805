<template>
  <div id="wrapper">
    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card class="mx-auto mt-5">
          <v-row class="mx-auto">
            <div class="d-flex align-center text-center">
              <h4 class="pl-7">
                Consumption Report
              </h4>
            </div>
            <v-spacer />
            <div>
              <v-breadcrumbs
                :items="links"
                divider=">"
              />
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- end page title -->

    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card class="mx-auto">
          <div class="card-box">
            <div class="align-center text-center">
              <v-form
                ref="reportForm"
                v-model="valid"
              >
                <v-row no-gutters>
                  <v-col
                    class="mt-1"
                    cols="2"
                  >
                    <span>Select Ingredients : </span>
                  </v-col>
                  <v-col
                    id="v-step-0"
                    cols="3"
                  >
                    <v-autocomplete
                      v-model="selectedIngredients"
                      :items="ingredientList"
                      dense
                      :rules="notEmptyRule"
                      item-text="ingredientName"
                      multiple
                      outlined
                    />
                  </v-col>
                  <v-col
                    class="mt-1"
                    cols="2"
                  >
                    <span>Show results for : </span>
                  </v-col>
                  <v-col
                    id="v-step-1"
                    cols="3"
                  >
                    <v-select
                      ref="dateSelector"
                      v-model="date"
                      :items="dateOptions"
                      :rules="notEmptyRule"
                      item-text="name"
                      item-value="dates"
                      dense
                      outlined
                      @click="removeCustom"
                    >
                      <template #append-item>
                        <v-divider class="mb-2" />
                        <v-menu
                          ref="menu"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          min-width="auto"
                          offset-y
                          transition="scale-transition"
                        >
                          <template #activator="{ on, attrs }">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-model="date"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Custom Date
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>

                          <v-date-picker
                            v-model="date"
                            no-title
                            range
                            scrollable
                          >
                            <v-spacer />
                            <v-btn
                              color="primary"
                              text
                              @click="closeDatePicker"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="primary"
                              text
                              @click="saveDate()"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    v-if="selectedIngredients.length > 0 && date.length > 0"
                    cols="2"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      class="primary"
                      @click="generateReport"
                    >
                      Generate Report
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="Object.keys(consumptionReport).length > 0"
                    :style="
                      Object.keys(consumptionReport).length > 0
                        ? ''
                        : 'padding-top:200px;'
                    "
                    class="canvas"
                    cols="12"
                  >
                    <v-chart :option="chartOptions" />
                  </v-col>

                  <v-col
                    v-if="Object.keys(consumptionReport).length > 0"
                    class="canvas mt-10"
                    cols="12"
                  >
                    <v-chart :option="dayStatOptions" />
                  </v-col>
                  <v-col
                    v-if="Object.keys(consumptionReport).length === 0"
                    cols="12"
                    class="
                      canvas
                      d-flex
                      justify-center
                      align-center
                      text-center
                    "
                  >
                    <span class="font-weight-bold font-30 text-center my-20">
                      No Data Available</span>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card class="mx-auto">
          <div class="card-box">
            <div
              id="v-step-2"
              class="d-flex justify-content-center align-center text-center"
            >
              <iframe
                :width="getWidth"
                :height="getHeight"
                src="https://datastudio.google.com/embed/reporting/d5c9974f-887c-4d41-b394-d59c8abfbb17/page/Zz9ZC"
                style="border:0; "
                allowfullscreen
              />
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import apiMixin from '../../utilities/mixins/apiMixin'

export default {
  name: 'ConsumptionReport',
  mixins: [apiMixin],
  data () {
    return {
      menu2: false,
      notEmptyRule: [(v) => !!v || ' This field is required'],
      valid: false,
      selectedIngredients: [],
      date: [],
      enumeratedDays: [],
      dateOptions: [
        {
          name: 'Last 7 Days',
          dates: [
            moment().subtract(7, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
          ]
        },
        {
          name: 'Last 30 Days',
          dates: [
            moment().subtract(30, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
          ]
        }
      ],
      stockData: [],
      links: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/inventory'
        },
        {
          text: 'Reports',
          disabled: false,
          href: '/reports'
        }
      ],
      consumptionData: [],
      dayStatData: []
    }
  },
  computed: {
    ...mapGetters({
      ingredientList: 'inventory/getIngredientsByCompany',
      getCategoryById: 'inventory/getIngredientById',
      getIngredientByName: 'inventory/getIngredientByName',
      consumptionReport: 'reports/getConsumptionReport',
      getUserInfoByKey: 'auth/getUserInfoByKey'
    }),
    getHeight () {
      return window.innerHeight
    },
    getWidth () {
      return window.innerWidth
    },
    dayStatOptions () {
      const vm = this
      return {
        title: {
          text: 'Day Wise Consumption'
        },
        toolbox: {
          itemSize: 25,
          feature: {
            saveAsImage: {}
          }
        },
        legend: {
          data: this.selectedIngredients
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            let str = ''
            params.forEach(param => {
              const ingredient = vm.getIngredientByName(param.seriesName)
              str += `<strong>${param.seriesName}</strong> : ${param.data} ${ingredient.measure_type === 'V' ? 'Ltr' : 'Kg'} </br>`
            })
            return str
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLabel: {
              interval: this.enumeratedDays.length > 20 ? 3 : 0
            },
            axisTick: {
              alignWithLabel: true
            },
            data: this.enumeratedDays
          }
        ],
        yAxis: [
          {
            axisLine: {
              show: true
            },
            axisTick: {
              show: true
            },
            axisLabel: {
              show: true
            },
            type: 'value'
          }
        ],
        series: this.dayStatData
      }
    },
    chartOptions () {
      const vm = this
      return {
        title: {
          text: 'Total Consumption'
        },
        toolbox: {
          itemSize: 25,
          feature: {
            saveAsImage: {}
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            const ingredient = vm.getIngredientByName(params[0].name)
            return `<strong>${params[0].name} </strong><br/> <br/> ${params[0].seriesName} :
                    ${params[0].value} ${ingredient.measure_type === 'V' ? 'Ltr' : 'Kg'}`
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.selectedIngredients,
            axisLabel: {
              interval: 0
            },
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            axisLine: {
              show: true
            },
            axisTick: {
              show: true
            },
            axisLabel: {
              show: true
            },
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Qty',
            type: 'bar',
            barWidth: '60%',
            data: this.consumptionData
          }
        ]
      }
    }
  },

  async mounted () {
    this.$tours.report.start()
  },
  methods: {
    async generateReport () {
      await this.$store.dispatch('reports/fetchConsumptionReport', {
        ingredientList: this.selectedIngredients,
        dateRange: this.date
      })
      this.generateTotalConsumptionReport()
      this.generateDayWiseReport()
    },
    generateTotalConsumptionReport () {
      for (const ingredient of this.selectedIngredients) {
        let totalQty = 0
        for (const reportData of this.consumptionReport[ingredient]) {
          totalQty += reportData.qty
        }
        this.consumptionData.push(totalQty)
      }
    },
    generateDayWiseReport () {
      const reportData = []
      const dateFormat = this.getUserInfoByKey('date_format')
      this.enumeratedDays = this.enumerateDaysBetweenDates(
        this.date[0],
        this.date[1]
      )
      for (const ingredient of this.selectedIngredients) {
        const data = []
        this.consumptionReport[ingredient].sort((a, b) => {
          const c = moment(a.timeStamp.toDate()).format(dateFormat)
          const d = moment(b.timeStamp.toDate()).format(dateFormat)
          return c - d
        })
        for (const date of this.enumeratedDays) {
          const index = this.consumptionReport[ingredient].findIndex(
            (report) => moment(report.timeStamp.toDate()).format(dateFormat) === date
          )
          if (index === -1) {
            data.push(0)
          } else {
            data.push(this.consumptionReport[ingredient][index].qty)
          }
        }
        reportData.push({
          name: ingredient,
          type: 'bar',
          barGap: 0,
          data
        })
      }
      this.dayStatData = reportData
    },

    enumerateDaysBetweenDates (startDate, endDate) {
      const dates = []
      const dateFormat = this.getUserInfoByKey('date_format')
      const currDate = moment(startDate)
      const lastDate = moment(endDate)
      dates.push(moment(startDate).format(dateFormat))
      while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().format(dateFormat))
      }
      dates.push(moment(endDate).format(dateFormat))
      return dates
    },
    removeCustom () {
      this.date = []
      const custom = this.dateOptions.find((option) => option.name === 'Custom')
      if (custom) {
        this.dateOptions.pop()
      }
    },
    closeDatePicker () {
      this.menu2 = false
      this.$refs.dateSelector.isMenuActive = false
    },
    saveDate () {
      this.$refs.menu.save(this.date)
      const data = {
        name: 'Custom',
        dates: this.date
      }
      this.dateOptions.push(data)
      this.closeDatePicker()
    }
  }
}
</script>

<style scoped>
.content-page {
  margin-left: 0 !important;
  padding-bottom: 50px;
}

.canvas {
  height: 500px;
  width: 100%;
}
</style>
