<template>
  <v-main class="hero">
    <v-container fluid>
      <v-row
        align="center"
        justify="center"
        style="height: 100vh"
      >
        <v-col cols="8">
          <v-card class="mx-10">
            <div class="card-box">
              <v-container class="my-10  pa-5 ">
                <v-row
                  class="mb-3 pa-5 d-flex align-center justify-center"
                >
                  <h1 class="font-weight-bold font-36">
                    SM Pantry
                  </h1>
                </v-row>
                <v-row class="my-2 mx-5 d-flex align-center justify-center">
                  <span
                    class="font-weight-bold font-20"
                  >Logout successful. Please wait while we redirect you to Smart Mawaid...</span>
                </v-row>
                <v-row
                  class="my-2 pa-5 mb-10 d-flex align-center justify-center"
                >
                  <v-progress-circular
                    v-if="formLoader"
                    indeterminate
                  />
                </v-row>
              </v-container>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'Logout',
  data () {
    return {
      formLoader: true
    }
  },
  mounted () {
    setTimeout(() => {
      window.location.replace(`${process.env.VUE_APP_SMP_MAIN_URL}`)
    }, 1500)
  }
}
</script>

<style scoped>
.hero {
  background: url("../../assets/images/bg-new.jpg");
  background-size: cover;
  height: 95vh;
}
</style>
