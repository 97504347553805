<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      style="z-index: 20001"
      transition="dialog-bottom-transition"
      @keydown.esc="dialog = false"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          dense
          class="primary ma-3"
          v-bind="attrs"
          v-on="on"
        >
          + Fetch Ingredients By Menu
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          <v-btn
            dark
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="dialog-header">Select Menus to Stock Out</span>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-card-text>
            <v-row align="center">
              <v-col cols="3">
                <div>
                  <span>Show results for : </span>
                </div>
                <v-select
                  ref="dateSelector"
                  v-model="date"
                  :items="dateOptions"
                  :rules="notEmptyRule"
                  item-text="name"
                  item-value="dates"
                  dense
                  outlined
                  @click="removeCustom"
                >
                  <template #append-item>
                    <v-divider class="mb-2" />
                    <v-menu
                      ref="menu"
                      v-model="customDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                    >
                      <template #activator="{ on, attrs }">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-model="date"
                              v-bind="attrs"
                              v-on="on"
                            >
                              Custom Date
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <v-date-picker
                        v-model="date"
                        no-title
                        range
                        scrollable
                      >
                        <v-spacer />
                        <v-btn
                          color="primary"
                          text
                          @click="closeDatePicker"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="saveDate()"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="2"
                class="pt-0"
              >
                <v-btn
                  :disabled="!valid || isMenuLoading"
                  color="primary"
                  @click="findMenus"
                >
                  Find Menus
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-row
          justify="center"
        >
          <v-col cols="12">
            <v-data-table
              id="v-step-3"
              v-model="selectedMenus"
              :loading="isMenuLoading"
              :headers="headers"
              :sort-by="['thaali_date']"
              :sort-desc="[true]"
              :items="menuList"
              item-key="thaali_id"
              show-select
              :search="search"
              class="px-3 table-striped table-bordered dt-responsive"
            >
              <template #top>
                <v-row class="d-flex align-start justify-content-between mt-2">
                  <v-col
                    class="d-flex align-center"
                    cols="8"
                  >
                    <v-text-field
                      v-model="search"
                      dense
                      clear-icon="mdi-close-circle-outline"
                      clearable
                      label="Search"
                      prepend-inner-icon="mdi-magnify"
                      solo
                    />
                  </v-col>
                </v-row>
              </template>
              <template #item.recipes="{item}">
                <span v-for="(recipe,index) in item.recipes">{{
                  recipe.recipe_title
                }} {{ index < item.recipes.length - 1 ? ',' : '' }} </span>
              </template>
              <template #item.noOfPeople="{item}">
                <v-text-field
                  v-model.number="item.noOfPeople"
                  class="pt-3"
                  :rules="countRule"
                  outlined
                  type="number"
                  label="No of People"
                  dense
                />
              </template>
              <template #item.app_response="{item}">
                <span
                  :class="item.app_response == 1 ? 'text-success':'text-danger'"
                  class="font-weight-bold"
                >{{ item.app_response == 1 ? 'Received' : 'Not Received' }}</span>
              </template>
              <template #item.stocked_out="{item}">
                <span
                  :class="item.stocked_out == 1 ? 'text-success':'text-danger'"
                  class="font-weight-bold"
                >{{ item.stocked_out == 1 ? 'Completed' : 'Not Completed' }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!canCalculateIngredients"
            @click="calculateStock"
          >
            Calculate Ingredients
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { cloneDeep } from 'lodash'

export default {
  name: 'StockOutByMenuForm',
  data () {
    return {
      dialog: false,
      search: '',
      date: [],
      enumeratedDays: [],
      customDate: false,
      dateOptions: [
        {
          name: 'Last 7 Days',
          dates: [
            moment().subtract(7, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
          ]
        },
        {
          name: 'Last 30 Days',
          dates: [
            moment().subtract(30, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
          ]
        }
      ],
      form: {
        noOfPeople: ''
      },
      headers: [
        {
          text: 'Thaali Date',
          sortable: true,
          value: 'thaali_date'
        },
        {
          text: 'Menu Name',
          align: 'start',
          sortable: true,
          value: 'menu_name'
        },
        {
          text: 'Recipes',
          sortable: true,
          value: 'recipes'
        },
        {
          text: 'No of People',
          sortable: true,
          value: 'noOfPeople'
        },
        {
          text: 'App Response Status',
          sortable: true,
          value: 'app_response'
        },
        {
          text: 'Stockout Status',
          sortable: true,
          value: 'stocked_out'
        }
      ],
      recipeLoader: false,
      recipeList: [],
      valid: false,
      countRule: [
        (v) => !!v || 'No of People is required',
        (v) => !!v || 'No of Days is required'
      ],
      notEmptyRule: [
        (v) => !!v || 'Please select at least one recipe'
      ],
      isMenuLoading: false,
      selectedMenus: []
    }
  },
  computed: {
    ...mapGetters({
      recipes: 'recipe/getAllRecipes',
      menuList: 'estimate/menuList',
      getUserInfoByKey: 'auth/getUserInfoByKey'
    }),
    canCalculateIngredients () {
      return this.selectedMenus.length > 0
    }
  },
  methods: {
    async findMenus () {
      this.isMenuLoading = true
      await this.$store.dispatch('estimate/findMenus', {
        company_id: this.getUserInfoByKey('company_id'),
        from_date: this.date[0],
        to_date: this.date[1]
      })
      this.isMenuLoading = false
    },
    closeDialog () {
      this.dialog = false
    },
    calculateStock () {
      try {
        const allMenuRecipes = this.selectedMenus.flatMap(menu => {
          return menu.recipes.map(recipe => {
            recipe.noOfPeople = menu.noOfPeople
            return recipe
          })
        })
        allMenuRecipes.forEach(recipe => {
          const recipeDetails = this.recipes.find(mainRecipe => mainRecipe.recipe_id === recipe.recipe_id)
          recipeDetails.noOfPeople = recipe.noOfPeople
          this.recipeList.push(cloneDeep(recipeDetails))
        })
        this.$emit('calculateStock', this.recipeList)
        this.closeDialog()
      } catch (e) {
        console.error(e)
        this.$emit('calculateStock', [])
        this.closeDialog()
      }
    },
    enumerateDaysBetweenDates (startDate, endDate) {
      const dates = []
      const dateFormat = this.getUserInfoByKey('date_format')
      const currDate = moment(startDate)
      const lastDate = moment(endDate)
      dates.push(moment(startDate).format(dateFormat))
      while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().format(dateFormat))
      }
      dates.push(moment(endDate).format(dateFormat))
      return dates
    },
    removeCustom () {
      this.date = []
      const custom = this.dateOptions.find((option) => option.name === 'Custom')
      if (custom) {
        this.dateOptions.pop()
      }
    },
    closeDatePicker () {
      this.customDate = false
      this.$refs.dateSelector.isMenuActive = false
    },
    saveDate () {
      this.$refs.menu.save(this.date)
      const data = {
        name: 'Custom',
        dates: this.date
      }
      this.dateOptions.push(data)
      this.closeDatePicker()
    }
  }
}
</script>

<style scoped>
.multiply-col {
  margin-right: -25px;
  margin-left: -25px;
}
</style>
