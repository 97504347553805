import * as firebase from '../../firebase.config'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

export const stockIn = {
  namespaced: true,
  state: {
    stockInList: []
  },
  mutations: {},
  actions: {
    fetchProductByBarcodeId ({ commit }, barcodeId) {
      try {
        const product = this.state.inventory.productList.find((product) => product.barcodeId === barcodeId)
        if (product) {
          return product
        } else {
          return {}
        }
      } catch (e) {
        console.error(e)
        return {}
      }
    },
    async addStockInItem ({ commit, dispatch }, stockInItems) {
      try {
        const priceUpdateData = []
        let totalPrice = 0
        const batchedData = chunk(stockInItems, 100)
        for (const items of batchedData) {
          const batch = firebase.db.batch()
          for (const stockInItem of items) {
            totalPrice += stockInItem.price
            const increment = firebase.fieldValue.increment(stockInItem.qty)
            const ingredientRef = await firebase.ingredientCollection().doc(
              stockInItem.ingredientId
            )
            batch.update(ingredientRef, { totalQty: increment, minQty: stockInItem.minQty, minQtyUnit: stockInItem.minQtyUnit })
            const stockInRef = firebase.stockCollection().doc(stockInItem.id)
            batch.set(stockInRef, {
              timeStamp: firebase.firestoreNow(),
              ...stockInItem,
              qty: Math.abs(stockInItem.qty)
            })
            const productRef = firebase.productCollection().doc(stockInItem.productId)
            if (stockInItem.expiryDate) {
              batch.update(productRef, {
                expiryDate: stockInItem.expiryDate
              })
            }
            if (stockInItem.price > 0) {
              batch.update(productRef, {
                price: (stockInItem.price / Math.abs(stockInItem.rawQty)).toFixed(2),
                priceUnit: stockInItem.unit
              })
              priceUpdateData.push({
                ingredientId: stockInItem.ingredientId,
                price: (stockInItem.price / Math.abs(stockInItem.rawQty)).toFixed(2),
                name: stockInItem.productName,
                priceUnit: stockInItem.unit.toLowerCase()
              })
            }
          }
          await batch.commit()
        }
        await dispatch('inventory/updatePriceInSmartMawaid',
          priceUpdateData,
          { root: true }
        )
        await dispatch('updatePriceInIncomeExpense', { id: uuidv4(), price: totalPrice })
        return true
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
        return false
      }
    },
    async updatePriceInIncomeExpense (_, { id, price }) {
      try {
        await firebase.transactionCollection().doc(id).set({
          createdAt: new Date(),
          transactionId: id,
          transactionType: 'EXPENSE',
          status: 'unpaid',
          fromPantry: true,
          paymentDetails: [],
          date: moment().format('YYYY-MM-DD'),
          amount: price,
          expenseType: 'category',
          to: `FOOD EXPENSE - ${moment().format('YYYY-MM-DD')}`,
          ledgerId: '',
          refNo: '-',
          remarks: `food expense from pantry on ${moment().format('YYYY-MM-DD')}`,
          category: `FOOD EXPENSE - ${moment().format('YYYY-MM-DD')}`
        })
        return true
      } catch (e) {
        return false
      }
    }
  },
  getters: {
    getStockInList (state) {
      return state.stockOutList
    }
  }
}
const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )
