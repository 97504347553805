<template>
  <div id="wrapper">
    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card class="mx-auto mt-5">
          <v-row class="mx-auto">
            <div class="d-flex align-center text-center">
              <h4 class="pl-7">
                Audit
              </h4>
            </div>
            <v-spacer />
            <div>
              <v-breadcrumbs
                :items="links"
                divider=">"
              />
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card class="mx-auto">
          <div class="card-box">
            <v-row
              class="mx-auto mb-5"
            >
              <h4>
                Audit Ingredients
              </h4>
              <v-spacer />
              <v-btn
                id="v-step-0"
                color="primary"
                class="mx-2"
                @click="generatePDF"
              >
                Download PDF
              </v-btn>
              <v-btn
                id="v-step-0"
                color="primary"
                class="mx-2"
                @click="generateCsv"
              >
                Download CSV
              </v-btn>
            </v-row>
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="ingredientList"
              :search="search"
              class="px-3 table-striped table-bordered dt-responsive"
            >
              <template #top>
                <v-row class="d-flex align-start justify-start mt-2">
                  <v-col
                    class="d-flex justify-center align-center"
                    cols="7"
                  >
                    <v-text-field
                      v-model="search"
                      clear-icon="mdi-close-circle-outline"
                      clearable
                      label="Search"
                      prepend-inner-icon="mdi-magnify"
                      solo
                    />
                  </v-col>
                </v-row>
              </template>
              <template #item.unit="{ item }">
                {{ getUnit(item) }}
              </template>
              <template #item.totalQty="{ item }">
                {{ getTotalQty(item) }}
              </template>
              <!--              <template #item.minQty="{ item }">
                {{ `${item.minQty}   (${getUnit(item.measure_type, item.minQty)})` }}
              </template>-->
              <template
                v-if="isAccessible"
                #item.action="{ item }"
              >
                <v-btn
                  id="v-step-1"
                  class="primary"
                  @click="openAuditDialog(item)"
                >
                  Audit
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
      <v-snackbar
        v-model="snackbar"
        :color="
          snackType === 'success'
            ? 'green'
            : snackType === 'error'
              ? 'red'
              : 'yellow'
        "
        :timeout="1500"
      >
        {{ snackText }}
      </v-snackbar>
    </v-row>
    <v-dialog
      v-if="auditDialog && auditItem"
      v-model="auditDialog"
      max-width="500px"
      @keydown.esc="closeAuditDialog"
    >
      <v-card>
        <v-form
          ref="auditForm"
          v-model="valid"
        >
          <v-card-title>Audit</v-card-title>
          <v-card-text>
            <h5 class="mb-4 mx-2">
              {{ getAvailableQty() }}
            </h5>
            <v-row class="my-2 mx-2">
              <!--              <UnitDropdown
                v-model="unitType"
                :measure-type="auditItem.measure_type"
              />-->
            </v-row>
            <v-row
              class="my-2 mx-2"
              dense
            >
              <v-text-field
                v-model.number="auditItem.actualQty"
                type="number"
                outlined
                :rules="actualQtyRules"
                autofocus
                dense
                label="Actual Qty*"
                required
              >
                <template #append>
                  <v-autocomplete
                    v-model="unitType"
                    :items="getIngredientUnit(auditItem)"
                    item-text="unit"
                    label="Unit"
                    dense
                    item-value="unit"
                  />
                </template>
              </v-text-field>
            </v-row>
            <h5 class="mb-4 mx-2">
              {{
                getHintText()
              }}
            </h5>
            <v-row class="my-2 mx-2">
              <RemarkDialog v-model="auditItem.remark" />
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="closeAuditDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              :loading="formLoader"
              color="primary"
              :disabled="!validateForm()"
              text
              @click="addAuditItem"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import configureMeasurements, { mass, volume } from 'convert-units'
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import JsPdf from 'jspdf'
import 'jspdf-autotable'
import RemarkDialog from './components/RemarkDialog'
import moment from 'moment'
import { volumeToML, weightToGram } from '@/utilities/convertToGramsUtil'
import apiMixin from '../../utilities/mixins/apiMixin'
import roleMixin from '../../utilities/mixins/roleMixin'
import { parse as JSONToCSV } from 'json2csv'
import { getFileName } from '@/utilities/fileNameUtil'
import { getTotalQty, getUnit } from '@/utilities/unitConverter'

export default {
  name: 'Audit',
  components: {
    RemarkDialog
  },
  mixins: [apiMixin, roleMixin],
  data () {
    return {
      convert: configureMeasurements({
        volume,
        mass
      }),
      unitType: 'g',
      formLoader: false,
      valid: true,
      isLoading: false,
      search: '',
      auditItem: {},
      auditDialog: false,
      snackbar: false,
      snackText: '',
      snackType: '',
      actualQtyRules: [(v) => !!v || 'Quantity is required'],
      remarkRules: [(v) => !!v || 'Remark is required'],
      links: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/inventory'
        },
        {
          text: 'Audit',
          disabled: false,
          href: '/audit'
        }
      ],
      headers: [
        {
          text: 'Ingredient',
          align: 'start',
          sortable: true,
          value: 'ingredientName'
        },
        /*  {
          text: 'Minimum Quantity Allowed',
          sortable: false,
          value: 'minQty'
        }, */
        {
          text: 'Total Quantity',
          sortable: false,
          value: 'totalQty'
        },
        {
          text: 'Unit',
          sortable: false,
          value: 'unit',
          width: '10%'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          width: '10%'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ingredientList: 'inventory/getIngredientsByCompany',
      getCategoryById: 'inventory/getCategoryById',
      getUserInfoByKey: 'auth/getUserInfoByKey'
    })
  },
  async mounted () {
    this.isLoading = true
    this.isLoading = false
  },
  methods: {
    getHintText () {
      return ''
    //  return getHintValue(this.unitType, this.auditItem.actualQty || 0)
    },
    async generateCsv () {
      const head = JSON.parse(JSON.stringify(this.headers.map((header) => header.value)))
      head.pop()
      const data = this.ingredientList.map((ingredient) => {
        const unit = this.getUnit(ingredient)
        return {
          ingredientName: ingredient.ingredientName,
          //  minQty: `${ingredient.minQty} (${this.getUnit(ingredient.measure_type, ingredient.minQty)})`,
          totalQty: this.getTotalQty(ingredient),
          unit: unit
        }
      })
      const csv = JSONToCSV(data, { fields: head })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = getFileName('audit-report', 'csv')
      anchor.click()
    },
    addFooters (doc) {
      const pageCount = doc.internal.getNumberOfPages()

      doc.setFont('helvetica')
      doc.setFontSize(16)
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.setLineWidth(0.1).line(0.5, doc.internal.pageSize.height - 1, 8.0, doc.internal.pageSize.height - 1)
        doc.text(
            `\u00A9 ${moment().year()} — SM Pantry`,
            0.5,
            doc.internal.pageSize.height - 0.5
        )
        doc.text(
          `Page ${i}`,
          7.0,
          doc.internal.pageSize.height - 0.5, 'left'
        )
      }
    },
    getTotalQty (ingredient) {
      return getTotalQty(ingredient)
    },
    getIngredientUnit (ingredient) {
      if (ingredient.measure_type === 'C') {
        return ['count', 'dozen']
      } else {
        return ingredient.uom.filter(unit =>
          unit.unit === 'gram' ||
          unit.unit === 'kg' ||
          unit.unit === 'ml' ||
          unit.unit === 'liter' ||
          unit.unit === 'fl-oz' ||
          unit.unit === 'pound'
        )
      }
    },
    getUnit (ingredient) {
      return getUnit(ingredient)
    },
    generatePDF () {
      const columns = [
        { title: 'Ingredient', dataKey: 'ingredientName' },
        //   { title: 'Minimum Quantity Allowed', dataKey: 'minQty' },
        { title: 'Total Quantity', dataKey: 'totalQty' }
      ]
      const doc = new JsPdf({
        theme: 'grid',
        orientation: 'portrait',
        unit: 'in',
        format: 'letter'
      })
      // text is placed using x, y coordinates
      doc.setTextColor('#cb8037')
      doc.setFont('helvetica', 'bold').setFontSize(26).text(`${this.getUserInfoByKey('company_name')} Audit Report`, 0.5, 1.0)
      doc.setLineWidth(0.03).line(0.5, 1.3, 8.0, 1.3)
      // create a line under heading
      doc.setFont('helvetica', 'bold').setFontSize(20).text(`Date:  ${moment().format(this.getUserInfoByKey('date_format'))}`, 0.5, 2.0)
      // create a line under heading
      doc.setLineWidth(0.01).line(0.5, 2.2, 8.0, 2.2)
      // Using autoTable plugin
      const data = this.ingredientList.map((element) => {
      //  const minQty = `${element.minQty}   (${this.getUnit(element.measure_type, element.minQty)})`
        const ingredientName = element.ingredientName
        const totalQty = `${this.getTotalQty(element)}   (${this.getUnit(element)})`
        return {
          // minQty: minQty,
          ingredientName: ingredientName,
          totalQty: totalQty
        }
      })
      doc.autoTable({
        headStyles: { fillColor: '#cb8037' },
        columns,
        body: data,
        margin: { left: 0.5, top: 2.5, bottom: 2 },
        didDrawPage: function (data) {
          data.settings.margin.top = 1
        }
      })
      this.addFooters(doc)
      // Creating footer and saving file
      doc.save(getFileName('audit-report', 'pdf'))
    },
    resetAuditForm () {
      this.$refs.auditForm.reset()
    },
    openAuditDialog (item) {
      this.auditItem = { ...item }
      this.auditDialog = true
    },
    closeAuditDialog () {
      this.auditDialog = false
      this.unitType = 'g'
    },
    getAvailableQty () {
      if (!this.auditItem) {
        return ''
      }
      return `Qty in system: ${this.getTotalQty(this.auditItem)} ${this.getUnit(this.auditItem)}`
    },
    showSnackNotification (text, type) {
      this.snackText = text
      this.snackType = type
      this.snackbar = true
    },
    convertedValue (unitType, qty) {
      if (unitType.toLowerCase() === 'count') {
        return parseInt(qty)
      }
      if (unitType.toLowerCase() === 'dozen') {
        return parseInt(qty * 12)
      }
      if (this.auditItem.measure_type === 'V') {
        try {
          return parseFloat(volumeToML(unitType, qty))
        } catch (e) {
          return parseFloat(qty)
        }
      } else {
        return parseFloat(weightToGram(unitType, qty))
      }
    },
    validateForm () {
      return this.auditItem.remark && this.$refs.auditForm.validate()
    },
    async addAuditItem () {
      this.formLoader = true
      try {
        if (this.validateForm()) {
          const auditItem = {}
          auditItem.id = uuidv4()
          auditItem.type = 'RECONCILIATION'
          let actualQty = 0
          actualQty = this.convertedValue(this.unitType, this.auditItem.actualQty)
          auditItem.qty = this.auditItem.totalQty - actualQty
          auditItem.ingredientId = this.auditItem.ingredientId
          auditItem.ingredientName = this.auditItem.ingredientName
          auditItem.remark = this.auditItem.remark
          const response = await this.$store.dispatch(
            'stockOut/addAuditItem',
            auditItem
          )
          if (response) {
            await this.$store.dispatch('inventory/fetchAllIngredients')
            this.resetAuditForm()
          } else {
            this.showSnackNotification(
              'Error while auditing ingredient',
              'error'
            )
          }
          this.formLoader = false
          this.closeAuditDialog()
          this.showSnackNotification(
            'Ingredient audited successfully',
            'success'
          )
        }
      } catch (e) {
        this.showSnackNotification('Error while auditing ingredient', 'error')
        this.formLoader = false
      }
    }
  }
}
</script>

<style scoped></style>
