import Vue from 'vue'
import VueRouter from 'vue-router'
import Stockout from '../views/Stockout/Stockout.vue'
import StockIn from '../views/StockIn/StockIn.vue'
import ConsumptionReport from '../views/Reports/ConsumptionReport.vue'
import Login from '../views/login/Login.vue'
import SSOLogin from '../views/login/SSOLogin.vue'
import Audit from '../views/audit/Audit.vue'
import Dashboard from '../views/Dashboard/Dashboard.vue'
import Logout from '../views/logout/Logout'
import { auth } from '../../firebase.config'
import Guide from '../views/Guide/Guide.vue'
import MenuManifest from '@/views/MenuManifest/MenuManifest'
import StockOutMenu from '@/views/Stockout/StockOutMenu'
import StockEstimateByRecipe from '@/views/StockEstimate/StockEstimateByRecipe'
import StockEstimateByMenu from '@/views/StockEstimate/StockEstimateByMenu'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/logout'
  },
  {
    path: '/',
    redirect: '/logout'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stock-in',
    name: 'Stock In',
    component: StockIn,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stock-out',
    name: 'Stock Out',
    component: Stockout,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: ConsumptionReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/sso/login',
    name: 'SSOLogin',
    component: SSOLogin,
    meta: {
      requiresAuth: false,
      ssoLogin: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/audit',
    name: 'Audit',
    component: Audit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/estimate-recipe',
    name: 'Estimate Stock By Recipe',
    component: StockEstimateByRecipe,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/estimate-menu',
    name: 'Estimate Stock By Menu',
    component: StockEstimateByMenu,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stock-out-menu',
    name: 'Stock Out By Menu',
    component: StockOutMenu,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-guide',
    name: 'User Guide',
    component: Guide,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/menu-manifest',
    name: 'Menu Manifest',
    component: MenuManifest,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes,
  linkExactActiveClass: 'active'
})
router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const ssoLogin = to.matched.some((record) => record.meta.ssoLogin)
  document.title = `${to.name} -  Smart Mawaid System`
  if (ssoLogin) {
    next()
    return
  }
  if (requiresAuth && !currentUser) {
    next('/logout')
  }
  next()
})
export default router
