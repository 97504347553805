<template>
  <div id="wrapper">
    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card
          id="v-step-4"
          class="mx-auto mt-5"
        >
          <v-row class="mx-auto">
            <div class="d-flex align-center text-center">
              <h4 class="pl-7">
                Menu Manifest
              </h4>
            </div>
            <v-spacer />
            <div>
              <v-breadcrumbs
                :items="links"
                divider=">"
              />
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card class="mx-auto">
          <div class="card-box">
            <div>
              <v-row class="d-flex justify-end align-items-center">
                <v-col cols="3">
                  <div>
                    <span>Show results for : </span>
                  </div>
                  <v-form v-model="valid">
                    <v-select
                      ref="dateSelector"
                      v-model="date"
                      :items="dateOptions"
                      :rules="notEmptyRule"
                      item-text="name"
                      item-value="dates"
                      dense
                      outlined
                      @click="removeCustom"
                    >
                      <template #append-item>
                        <v-divider class="mb-2" />
                        <v-menu
                          ref="menu"
                          v-model="customDate"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          min-width="auto"
                          offset-y
                          transition="scale-transition"
                        >
                          <template #activator="{ on, attrs }">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-model="date"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Custom Date
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>

                          <v-date-picker
                            v-model="date"
                            no-title
                            range
                            scrollable
                          >
                            <v-spacer />
                            <v-btn
                              color="primary"
                              text
                              @click="closeDatePicker"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="primary"
                              text
                              @click="saveDate()"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </template>
                    </v-select>
                  </v-form>
                </v-col>
                <v-col
                  cols="2"
                  class="pt-0"
                >
                  <v-btn
                    :disabled="!valid || isMenuLoading"
                    color="primary"
                    @click="findMenus"
                  >
                    Find Menus
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
              <v-row
                justify="center"
              >
                <v-col cols="12">
                  <v-data-table
                    id="v-step-3"
                    :loading="isMenuLoading"
                    :headers="headers"
                    :items="menuList"
                    :search="search"
                    class="px-3 table-striped table-bordered dt-responsive"
                  >
                    <template #top>
                      <v-row class="d-flex align-start justify-content-between mt-2">
                        <v-col
                          class="d-flex align-center"
                          cols="8"
                        >
                          <v-text-field
                            v-model="search"
                            dense
                            clear-icon="mdi-close-circle-outline"
                            clearable
                            label="Search"
                            prepend-inner-icon="mdi-magnify"
                            solo
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <template #item.action="{item}">
                      <MenuManifestForm
                        ref="manifestDialog"
                        v-model="item.thaali_date"
                      />
                    </template>
                    <template #item.recipes="{item}">
                      <span v-for="(recipe,index) in item.recipes">{{
                        recipe.recipe_title
                      }} {{ index < item.recipes.length - 1 ? ',' : '' }} </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="1500"
      color="green"
    >
      {{ snackText }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import apiMixin from '../../utilities/mixins/apiMixin'
import { gramToKilogram, mlToLitre } from '@/utilities/convertToGramsUtil'
import moment from 'moment'
import MenuManifestForm from '@/components/MenuManifest'

export default {
  name: 'MenuManifest',
  components: {
    MenuManifestForm
  },
  mixins: [apiMixin],
  data () {
    return {
      valid: false,
      notEmptyRule: [(v) => !!v || ' This field is required'],
      countRule: [(v) => !!v || 'This field is required', (v) => v > 0 || 'Value should be greater than 0'],
      date: [],
      enumeratedDays: [],
      dateOptions: [
        {
          name: 'Last 7 Days',
          dates: [
            moment().subtract(7, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
          ]
        },
        {
          name: 'Last 30 Days',
          dates: [
            moment().subtract(30, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
          ]
        }
      ],
      errorSnackBar: false,
      snackbar: false,
      snackText: '',
      selectedRecipes: [],
      search: '',
      headers: [
        {
          text: 'Menu Name',
          align: 'start',
          sortable: true,
          value: 'menu_name'
        },
        {
          text: 'Thaali Date',
          sortable: true,
          value: 'thaali_date'
        },
        {
          text: 'Recipes',
          sortable: true,
          value: 'recipes'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      isManifestLoading: false,
      customDate: false,
      links: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/dashboard'
        },
        {
          text: 'Menu Manifest',
          disabled: false,
          href: '/menu-manifest'
        }
      ],
      isMenuLoading: false
    }
  },
  computed: {
    ...mapGetters({
      menuManifest: 'inventory/getMenuManifest',
      isManifestAvailable: 'inventory/isManifestAvailable',
      recipes: 'recipe/getAllRecipes',
      menuList: 'estimate/menuList',
      getUserInfoByKey: 'auth/getUserInfoByKey'
    })
  },
  watch: {},
  created () {

  },
  destroyed () {

  },
  async mounted () {

  },
  methods: {
    getTotalQty (unitType, qty) {
      if (unitType === 'W') {
        if (qty > 1000) {
          return parseFloat(gramToKilogram(qty)).toFixed(2)
        } else {
          return parseFloat(qty).toFixed(2)
        }
      } else {
        if (qty > 1000) {
          return parseFloat(mlToLitre(qty)).toFixed(2)
        } else {
          return parseFloat(qty).toFixed(2)
        }
      }
    },
    getUnit (unitType, qty) {
      if (unitType === 'W') {
        if (qty > 1000) {
          return 'kg'
        } else {
          return 'gram'
        }
      } else {
        if (qty > 1000) {
          return 'liter'
        } else {
          return 'ml'
        }
      }
    },
    enumerateDaysBetweenDates (startDate, endDate) {
      const dates = []
      const dateFormat = this.getUserInfoByKey('date_format')
      const currDate = moment(startDate)
      const lastDate = moment(endDate)
      dates.push(moment(startDate).format(dateFormat))
      while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().format(dateFormat))
      }
      dates.push(moment(endDate).format(dateFormat))
      return dates
    },
    removeCustom () {
      this.date = []
      const custom = this.dateOptions.find((option) => option.name === 'Custom')
      if (custom) {
        this.dateOptions.pop()
      }
    },
    closeDatePicker () {
      this.cutomDate = false
      this.$refs.dateSelector.isMenuActive = false
    },
    saveDate () {
      this.$refs.menu.save(this.date)
      const data = {
        name: 'Custom',
        dates: this.date
      }
      this.dateOptions.push(data)
      this.closeDatePicker()
    },
    async findMenus () {
      this.isMenuLoading = true
      await this.$store.dispatch('estimate/findMenus', {
        company_id: this.getUserInfoByKey('company_id'),
        from_date: this.date[0],
        to_date: this.date[1]
      })
      this.isMenuLoading = false
    },
    async fetchMenuManifest (date) {
      this.isManifestLoading = true
      await this.$store.dispatch('inventory/fetchMenuManifest', {
        date
      })
      this.isManifestLoading = false
    }
  }
}
</script>

<style scoped></style>
