import { cloneDeep } from 'lodash'
import axios from 'axios'

export const estimate = {
  namespaced: true,
  state: {
    ingredients: [],
    ingredientsByRecipe: [],
    menus: []
  },
  actions: {
    calculateStockEstimate ({ commit, dispatch }, { recipeList, type }) {
      try {
        const finalIngredientList = []
        const ingredientsByRecipeList = []
        // Traverses through each recipe and takes out servingQty for that particular recipe
        // then traverses through all the ingredients of the recipe and calculates per person quantity by dividing total ingredient qty by serving qty
        // And push a new estimated quantity value by multiplying per person quantity with number of people & no of days

        recipeList.forEach(recipe => {
          let selectedRecipe = {}
          if (type === 'recipe') {
            selectedRecipe = recipe.selectedRecipe
          } else {
            selectedRecipe = recipe
          }
          const servingQty = parseInt(selectedRecipe.servings)
          const count = parseInt(recipe.noOfPeople)
          let days = 1
          if (type === 'recipe') {
            days = parseInt(recipe.noOfDays)
          }
          selectedRecipe.ingredients.forEach(ingredient => {
            const totalQty = parseFloat(ingredient.totalQty)
            const perPersonQty = totalQty / servingQty
            ingredient.perPersonQty = perPersonQty
            ingredient.qty = perPersonQty * count * days
            ingredient.personCount = count
            ingredient.days = days
            finalIngredientList.push(cloneDeep(ingredient))
            ingredientsByRecipeList.push(cloneDeep({ ...ingredient, recipeName: selectedRecipe.recipe_title }))
          })
        })

        /* The result will contain duplicates as many recipes will have same ingredients.
        So we need to merge estimated quantity of all the matching ingredients and create a final list */

        const finalList = finalIngredientList.reduce((previousIngredient, currentIngredient) => {
          const { name, qty, perPersonQty } = currentIngredient
          const existing = previousIngredient.find(i => i.name === name)
          if (existing) {
            existing.perPersonQty += perPersonQty
            existing.qty += qty
          } else {
            previousIngredient.push(currentIngredient)
          }
          return previousIngredient
        }, [])
        finalList.forEach(ingredient => { ingredient.deducedQty = ingredient.totalQty - ingredient.qty })
        commit('setStockEstimate', { finalIngredientList: finalList, ingredientsByRecipeList: ingredientsByRecipeList })
      } catch (e) {
        console.error(e)
      }
    },
    async findMenus ({ commit, dispatch }, data) {
      const res = await axios.post(
        `${process.env.VUE_APP_SMP_BASE_URL}/api/menu/getmenufromdates`,
        {
          company_id: data.company_id,
          from_date: data.from_date,
          to_date: data.to_date
        }
      )
      // Token dependent
      if (res.data.status) {
        const data = res.data.data
        data.forEach(menu => {
          menu.noOfPeople = menu.count || 0
        })
        commit('setMenus', data)
      } else {
        commit('setMenus', [])
      }
    }
  },

  mutations: {
    setStockEstimate (state, val) {
      state.ingredients = val.finalIngredientList
      state.ingredientsByRecipe = val.ingredientsByRecipeList
    },
    setMenus (state, val) {
      state.menus = val
    }
  },
  getters: {
    getEstimateByType: (state) => (type) => {
      if (type.toLowerCase() === 'cook') {
        return state.ingredientsByRecipe.sort(function (a, b) {
          return a.recipeName.localeCompare(b.recipeName)
        })
      } else {
        return state.ingredients.sort(function (a, b) {
          return a.name.localeCompare(b.name)
        })
      }
    },
    menuList: (state) => {
      return state.menus.map(menu => {
        return menu
      }).sort(function (a, b) {
        return a.menu_name.localeCompare(b.menu_name)
      })
    }
  }
}
