<template>
  <div id="wrapper">
    <v-row
      class="mx-auto"
      justify="center"
    >
      <v-col
        cols="10"
      >
        <v-card class="mx-auto mt-5">
          <v-row class="mx-auto">
            <div class="d-flex align-center text-center">
              <h4 class="pl-7">
                Stock Out
              </h4>
            </div>
            <v-spacer />
            <div>
              <v-breadcrumbs
                :items="links"
                divider=">"
              />
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      class="mx-auto"
      justify="center"
    >
      <v-col cols="10">
        <v-card class="mx-auto mt-5">
          <v-row class="mx-auto">
            <div class="d-flex align-center text-center">
              <h4 class="pl-7">
                Pending Stock Outs - <v-chip
                  color="primary"
                  dark
                >
                  {{ pendingStockOutList.length }}
                </v-chip>
              </h4>
            </div>
            <v-spacer />
            <div
              v-if="pendingStockOutList.length > 0"
              class="pa-2"
            >
              <ApproveDialog />
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      class="mx-auto"
      justify="center"
    >
      <v-col cols="10">
        <v-card class="mx-auto">
          <div class="card-box">
            <div>
              <v-row justify="end">
                <v-col
                  id="v-step-0"
                  cols="6"
                  md="2"
                  lg="2"
                  class=" mb-6"
                >
                  <v-dialog
                    v-model="addDialog"
                    max-width="500px"
                    persistent
                    style="z-index: 20001"
                    transition="dialog-bottom-transition"
                    @keydown.esc="addDialog = false"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        dense
                        class="d-flex align-center primary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        New Stock Out
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>Add Quantity</v-card-title>
                      <v-form
                        ref="form"
                        v-model="valid"
                      >
                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              <v-select
                                v-model="selectedMeal"
                                dense
                                :items="meals"
                                :rules="mealTypeRule"
                                label="Meal*"
                                outlined
                                required
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-autocomplete
                                v-model="selectedIngredient"
                                dense
                                :items="ingredientList"
                                :rules="ingredientRule"
                                item-text="ingredientName"
                                item-value="ingredientValue"
                                label="Ingredient*"
                                outlined
                                required
                                return-object
                              />
                            </v-col>
                            <v-col cols="12">
                              <UnitDropdown
                                v-model="unitType"
                                :measure-type="selectedIngredient.measure_type"
                              />
                            </v-col>
                            <v-col>
                              <h5 class="mb-4 mx-2">
                                {{ getAvailableQty() }}
                              </h5>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model.number="stockOutQuantity"
                                dense
                                autofocus
                                :rules="qtyRules"
                                label="Quantity"
                                outlined
                                required
                                type="number"
                              />
                            </v-col>
                            <v-col>
                              <h5 class="mb-4 mx-2">
                                {{
                                  getHintText()
                                }}
                              </h5>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-form>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          text
                          @click="closeDialog"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary"
                          :disabled="!valid"
                          text
                          @click="addToStockList"
                        >
                          Add to List
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-snackbar
                    v-model="errorSnackBar"
                    absolute
                    center
                    color="error"
                    top
                  >
                    Ingredient is out of stock.
                  </v-snackbar>
                </v-col>
                <v-col
                  id="v-step-1"
                  cols="6"
                  md="2"
                  lg="2"
                  class="mb-6"
                >
                  <BulkUploadDialog ref="bulkUpload" />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12">
                  <v-data-table
                    id="v-step-2"
                    :headers="headers"
                    :items="stockOutList"
                    :search="search"
                    class="px-3 table-striped table-bordered dt-responsive"
                  >
                    <template #top>
                      <v-row class="d-flex align-start justify-start mt-2">
                        <v-col
                          class="d-flex justify-center align-center"
                          cols="7"
                        >
                          <v-text-field
                            v-model="search"
                            class="mt-4"
                            clear-icon="mdi-close-circle-outline"
                            clearable
                            label="Search"
                            prepend-inner-icon="mdi-magnify"
                            solo
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <template #item.alias="{item}">
                      {{ getAlias(item) }}
                    </template>
                    <template #item.qty="{item}">
                      {{ getTotalQty(item,'qty') }}
                    </template>
                    <template #item.action="{ item }">
                      <v-icon
                        small
                        @click="deleteFromStockOutList(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <template #item.unit="{ item }">
                      {{ getUnit(item, 'qty') }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center justify-center">
                <v-btn
                  :disabled="stockOutList.length === 0"
                  :loading="saveStockLoader"
                  class="mt-10 primary"
                  @click="stockOut"
                >
                  Save and Stock out
                </v-btn>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="1500"
      color="green"
    >
      {{ snackText }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

import BulkUploadDialog from './components/BulkUploadDialog'
import ApproveDialog from './components/ApproveDialog'
import UnitDropdown from '@/components/UnitDropdown'
import {
  getRemoveHintValue,
  volumeToML,
  weightToGram
} from '@/utilities/convertToGramsUtil'
import apiMixin from '../../utilities/mixins/apiMixin'
import { getTotalQty, getUnit } from '@/utilities/unitConverter'

export default {
  name: 'Stockout',
  components: {
    BulkUploadDialog,
    ApproveDialog,
    UnitDropdown
  },
  mixins: [apiMixin],
  data () {
    return {
      valid: true,
      qtyRules: [(v) => !!v || 'Quantity is required', (v) => v > 0 || 'Invalid value', (v) => this.convertedValue(this.unitType, v) <= this.selectedIngredient.totalQty || 'Quantity is greater than available qty'],
      mealTypeRule: [(v) => !!v || 'Meal Type is required'],
      ingredientRule: [(v) => !!Object.keys(v).length > 0 || 'Ingredient is required'],
      unitType: 'g',
      errorSnackBar: false,
      snackbar: false,
      snackText: '',
      addDialog: false,
      stockOutQuantity: 0,
      selectedIngredient: { measure_type: 'g' },
      stockOutList: [],
      meals: ['LUNCH', 'DINNER'],
      selectedMeal: undefined,
      search: '',
      headers: [
        {
          text: 'Item Name',
          align: 'start',
          sortable: true,
          value: 'ingredientName'
        },
        {
          text: 'Local Alias',
          sortable: true,
          value: 'alias'
        },
        {
          text: 'Meal Type',
          sortable: false,
          value: 'mealType'
        },
        {
          text: 'Qty to stock out',
          sortable: false,
          value: 'qty'
        },
        {
          text: 'Unit',
          sortable: false,
          value: 'unit'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      links: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/inventory'
        },
        {
          text: 'Stock out',
          disabled: false,
          href: '/stock-out'
        }
      ],
      saveStockLoader: false
    }
  },
  computed: {
    ...mapGetters({
      ingredientList: 'inventory/getIngredientsByCompany',
      getUserInfoByKey: 'auth/getUserInfoByKey',
      pendingStockOutList: 'stockOut/getPendingStockOutList'
    })
  },
  async mounted () {
    await this.$store.dispatch('stockOut/fetchAllPendingStockOuts')
    //   this.$tours.stockout.start()
  },
  methods: {
    getAlias (item) {
      if (item.alias && item.alias.length > 0) {
        return item.alias.join(', ')
      } else {
        return ''
      }
    },
    closeDialog () {
      this.stockOutQuantity = 0
      this.selectedIngredient = { measure_Type: 'g' }
      this.selectedMeal = ''
      this.addDialog = false
    },
    getHintText () {
      return getRemoveHintValue(this.unitType, this.stockOutQuantity || 0)
    },
    getTotalQty (ingredient, qtyKey) {
      return getTotalQty(ingredient, qtyKey)
    },
    getUnit (ingredient, qtyKey) {
      return getUnit(ingredient, qtyKey)
    },
    convertedValue (unitType, qty) {
      if (unitType.toLowerCase() === 'count') {
        return parseInt(qty)
      }
      if (unitType.toLowerCase() === 'dozen') {
        return parseInt(qty * 12)
      }
      if (this.selectedIngredient.measure_type === 'V') {
        try {
          return parseFloat(volumeToML(unitType, qty))
        } catch (e) {
          return parseFloat(qty)
        }
      } else {
        return parseFloat(weightToGram(unitType, qty))
      }
    },
    async stockOut () {
      this.saveStockLoader = true
      for (let i = this.stockOutList.length - 1; i >= 0; i--) {
        delete this.stockOutList[i].alias
        await this.$store.dispatch('stockOut/addStockOutItem', this.stockOutList[i])
        this.deleteFromStockOutList(this.stockOutList[i])
      }
      this.saveStockLoader = false
      await this.$store.dispatch('inventory/fetchAllIngredients')
      this.snackText = 'Ingredients successfully stocked out.'
      this.snackbar = true
    },
    deleteFromStockOutList (item) {
      const index = this.stockOutList.findIndex(
        (cat) => cat.ingredientId === item.ingredientId
      )
      this.stockOutList.splice(index, 1)
    },
    addToStockList () {
      if (this.$refs.form.validate()) {
        const stockOutItem = {}
        stockOutItem.id = uuidv4()
        stockOutItem.type = 'STOCK_OUT'
        stockOutItem.qty = this.convertedValue(this.unitType, this.stockOutQuantity)
        stockOutItem.unit = this.unitType
        stockOutItem.mealType = this.selectedMeal
        stockOutItem.ingredientId = this.selectedIngredient.ingredientId
        stockOutItem.ingredientName = this.selectedIngredient.ingredientName
        stockOutItem.alias = this.selectedIngredient.alias
        this.stockOutList.push(stockOutItem)
        this.addDialog = false
      }
    },
    getAvailableQty () {
      if (!this.selectedIngredient) {
        return ''
      }
      return `Total Available Qty : ${this.getTotalQty(this.selectedIngredient, 'totalQty')} ${this.getUnit(this.selectedIngredient, 'totalQty' || 0)}`
    },
    checkAndShow () {
      if (this.selectedMeal && this.selectedIngredient.ingredientId) {
        if (this.selectedIngredient.totalQty <= 0) {
          this.errorSnackBar = true
          return false
        }
        this.errorSnackBar = false
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style>
.v-messages__message {
  font-size: 16px !important;
}
</style>
