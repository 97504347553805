<template>
  <v-select
    dense
    :value="unitType"
    :items="getAvailableUnits()"
    item-text="plural"
    item-value="abbr"
    label="Unit"
    outlined
    @change="$emit('input', $event)"
    @input="$emit('input', $event)"
  />
</template>
<script>
import configureMeasurements, { mass, volume } from 'convert-units'

export default {
  name: 'UnitDropdown',
  props: {
    measureType: {
      default () {
        return 'W'
      },
      type: String
    }
  },
  data () {
    return {
      convert: configureMeasurements({
        volume,
        mass
      }),
      unitType: 'g',
      massUnits: ['kg', 'g', 'oz', 't'],
      volumeUnits: ['l', 'ml']
    }
  },
  methods: {
    getAvailableUnits () {
      if (this.measureType === 'C') {
        return [
          {
            abbr: 'Count',
            measure: 'Count',
            plural: 'Count',
            singular: 'Count',
            system: 'metric'
          },
          {
            abbr: 'Dozen',
            measure: 'Dozen',
            plural: 'Dozen',
            singular: 'Dozen',
            system: 'metric'
          }
        ]
      }
      if (this.measureType === 'V') {
        return this.convert.list('volume').filter((element) => this.volumeUnits.includes(element.abbr))
      } else {
        return this.convert.list('mass').filter((element) => this.massUnits.includes(element.abbr))
      }
    }
  }
}
</script>

<style scoped>

</style>
