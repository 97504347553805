export const numberFormatter = (num) => {
  try {
    // Check if the number is a whole number
    const decimalPlaces = Number.isInteger(num) ? 2 : 4

    // Convert the number to a string with the appropriate number of decimal places
    let str = num.toFixed(decimalPlaces)

    // Split the string into an array of characters
    const beforeDecimal = str.split('.')[0]
    const afterDecimal = str.split('.')[1]
    const arr = beforeDecimal.split('')

    // Reverse the array so we can add commas from right to left
    arr.reverse()

    // Loop through the array and add commas every three digits
    for (let i = 3; i < arr.length; i += 4) {
      arr.splice(i, 0, ',')
    }

    // Reverse the array again and join it back into a string
    str = arr.reverse().join('') + '.' + afterDecimal

    // Return the string
    return str
  } catch (e) {
    return '0.00'
  }
}
