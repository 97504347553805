<template>
  <div id="wrapper">
    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card
          id="v-step-4"
          class="mx-auto mt-5"
        >
          <v-row class="mx-auto">
            <div class="d-flex align-center text-center">
              <h4 class="pl-7">
                User Guide
              </h4>
            </div>
            <v-spacer />
            <div>
              <v-breadcrumbs
                :items="links"
                divider=">"
              />
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card class="mx-auto">
          <div class="card-box">
            <v-progress-circular
              v-if="isLoading"
              class="my-3 mx-auto d-flex justify-center"
              color="#d96c07"
              indeterminate
            />
            <!-- <div
              v-else
              v-html="getSupportGuideHTML"
            /> -->
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Guide',
  components: {

  },
  data () {
    return {
      isLoading: false,
      links: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/dashboard'
        },
        {
          text: 'Guide',
          disabled: false,
          href: '/user-guide'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getSupportGuideHTML: 'reports/getSupportGuideHTML'
    })
  },
  watch: {

  },
  created () {

  },
  destroyed () {

  },
  async mounted () {
    this.isLoading = true
    await this.fetchGuide()
    this.isLoading = false
  },
  methods: {
    async fetchGuide () {
      await this.$store.dispatch('reports/fetchSupportGuide')
    }
  }
}
</script>

<style scoped>
  h4{
    margin: 10px 0;
    padding-top: 0 !important;
    font-size:18px;
    color: #313a46;
    font-weight: 600;
  }
</style>
