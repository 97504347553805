<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      style="z-index: 20001"
      transition="dialog-bottom-transition"
      @keydown.esc="dialog = false"
    >
      <template #activator="{ on, attrs }">
        <div class="d-flex py-3">
          <v-btn
            color="primary"
            dark
            dense
            v-bind="attrs"
            v-on="on"
          >
            Bulk Stock out
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          <v-btn
            dark
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="dialog-header">Import CSV</span>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-container>
          <v-stepper v-model="stepper">
            <v-stepper-header>
              <v-stepper-step
                :complete="stepper > 1"
                step="1"
              >
                Upload CSV
              </v-stepper-step>

              <v-divider />

              <v-stepper-step
                :complete="stepper > 2"
                step="2"
              >
                Preview
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card>
                  <v-row
                    align="center"
                    justify="center"
                  >
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-row no-gutters>
                        <p>
                          Download Sample file with list of all available
                          ingredients. <br><br>
                          * Only the ingredients present in the csv will be
                          parsed. To add new ingredients please add a new
                          category.
                        </p>
                      </v-row>
                      <v-row no-gutters>
                        <v-btn
                          class="primary mx-2 my-2"
                          @click="generateCsv"
                        >
                          <span>Download Sample CSV</span>
                          <v-icon class="ml-1">
                            mdi-cloud-download
                          </v-icon>
                        </v-btn>
                      </v-row>
                      <v-divider />
                      Please select the CSV file
                    </v-col>
                    <v-col
                      align="center"
                      cols="12"
                      justify="center"
                      md="6"
                      offset="3"
                    >
                      <v-row>
                        <v-file-input
                          v-model="file"
                          :full-width="false"
                          :show-size="1000"
                          accept="text/csv"
                          color="primary"
                          counter

                          label="File input"
                          outlined
                          placeholder="Select your file"
                          prepend-icon=""
                          suffix="Browse"
                        >
                          <template #selection="{ text }">
                            <v-chip
                              color="primary"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                        <v-btn
                          :disabled="!file"
                          class="primary mx-2"
                          @click="parseCSV"
                        >
                          Upload File
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col />
                  </v-row>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card>
                  <v-row>
                    <v-col cols="12">
                      <span>List of Parsed Ingredients to Stock Out</span>
                    </v-col>
                    <v-col cols="12">
                      <v-form v-model="validityStatus">
                        <v-data-table
                          :headers="headers"
                          :items="parsedData"
                          hide-default-footer
                          :items-per-page="1000"
                          :search="search"
                          class="px-3 table-striped table-bordered dt-responsive"
                        >
                          <template #top>
                            <v-row class="d-flex justify-start align-start mt-2">
                              <v-col
                                class="d-flex justify-center align-center"
                                cols="7"
                              >
                                <v-text-field
                                  v-model="search"
                                  clear-icon="mdi-close-circle-outline"
                                  clearable
                                  label="Search"
                                  prepend-inner-icon="mdi-magnify"
                                  solo
                                />
                              </v-col>
                            </v-row>
                          </template>
                          <template #item.ingredient="{ item }">
                            <v-autocomplete
                              v-if="getItem(item)"
                              v-model="item.ingredient"
                              :items="ingredientList"
                              :rules="[rules.required]"
                              dense
                              class="ingredient-text-field"
                              item-text="ingredientName"
                              item-value="ingredientName"
                              outlined
                              @change="checkIngredient(item)"
                            />
                            <span v-else>{{ item.ingredient }}</span>
                          </template>
                          <template #item.meal_type="{ item }">
                            <v-select
                              v-model="item.meal_type"
                              :items="mealTypeList"
                              :rules="[rules.required]"
                              dense
                              class="ingredient-text-field"
                              outlined
                              @change="checkMealType(item)"
                            />
                          </template>
                          <template #item.stockout_qty="{ item }">
                            <v-text-field
                              v-model.number="item.stockout_qty"
                              persistent-hint
                              :hint="getAvailableQty(item.ingredient)"
                              :rules="[rules.required,qtyRule(item)]"
                              class="qty-text-field"
                              outlined
                              dense
                              type="number"
                              @input="checkQty(item)"
                            />
                          </template>
                          <template #item.reason="{ item }">
                            <ul v-for="reason in item.reason">
                              <li class="reason-text">
                                {{ reason }}
                              </li>
                            </ul>
                          </template>
                          <template #item.alias="{ item }">
                            {{ getAlias(item) }}
                          </template>
                          <template #item.unit="{ item }">
                            <v-select
                              v-if="
                                item.reason.includes(UNIT_ERROR['EMPTY']) ||
                                  item.reason.includes(
                                    UNIT_ERROR['NOT_FOUND']
                                  )
                              "
                              v-model="item.unit"
                              :items="unitList(item.ingredientId)"
                              :rules="[rules.required]"
                              dense
                              class="ingredient-text-field"
                              outlined
                              @change="checkUnit(item)"
                            />
                            <span v-else>{{ item.unit }}</span>
                          </template>
                          <template #item.type="{ item }">
                            <v-icon
                              v-if="item.type === 'Valid'"
                              color="green darken-2"
                            >
                              mdi-check-circle
                            </v-icon>
                            <v-icon
                              v-else
                              color="red darken-2"
                            >
                              mdi-alert-circle
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-form>
                    </v-col>
                  </v-row>
                  <v-row class="py-5 mx-2">
                    <v-btn
                      :disabled="isValid()"
                      :loading="stockoutLoader"
                      class="mx-2"
                      color="primary"
                      @click="stockOut"
                    >
                      Stock Out
                    </v-btn>

                    <v-btn
                      class="mx-2"
                      text
                      @click="stepper = 1"
                    >
                      Go back
                    </v-btn>
                  </v-row>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-container>
      </v-card>
      <v-snackbar
        v-model="snackbar"
        :color="
          snackType === 'success'
            ? 'green'
            : snackType === 'error'
              ? 'red'
              : 'yellow'
        "
        :timeout="1500"
      >
        {{ snackText }}
      </v-snackbar>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Papa from 'papaparse'
import { v4 as uuidv4 } from 'uuid'
import configureMeasurements, { mass, volume } from 'convert-units'
import { volumeToML, weightToGram } from '@/utilities/convertToGramsUtil'
import { getFileName } from '@/utilities/fileNameUtil'

const JSONToCSV = require('json2csv').parse
const convert = configureMeasurements({
  volume,
  mass
})

export default {
  name: 'BulkUploadDialog',
  props: {
    open: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      snackbar: false,
      snackText: '',
      validityStatus: false,
      search: '',
      stockoutLoader: false,
      mealTypeList: ['LUNCH', 'DINNER'],
      rules: {
        required: (value) => !!value || 'Required.'
      },
      headers: [
        {
          text: 'Ingredient',
          align: 'start',
          sortable: true,
          value: 'ingredient'
        },
        {
          text: 'Local Alias',
          sortable: true,
          value: 'local_alias'
        },
        {
          text: 'Meal Type',
          sortable: true,
          value: 'meal_type'
        },
        {
          text: 'Qty to Stockout',
          sortable: true,
          value: 'stockout_qty'
        },
        {
          text: 'Unit',
          sortable: false,
          value: 'unit'
        },
        {
          text: 'Valid',
          value: 'type'
        },
        {
          text: 'Reason',
          sortable: false,
          value: 'reason'
        }
      ],
      stepper: 1,
      file: null,
      snackType: '',
      nameRules: [(v) => !!v || 'Name is required'],
      dialog: false,
      parsedData: [],
      massUnits: ['kg', 'g', 'oz', 't'],
      volumeUnits: ['l', 'ml'],
      INGREDIENT_ERROR: {
        NOT_FOUND: 'Ingredient not found in database',
        EMPTY: 'Ingredient is empty'
      },
      QTY_ERROR: {
        EMPTY: 'No qty given to stockout',
        MORE_THAN_AVAILABLE: 'Available qty is lesser than qty to stockout'
      },
      MEAL_TYPE_ERROR: {
        EMPTY: 'Meal type is empty'
      },
      UNIT_ERROR: {
        EMPTY: 'Unit is empty',
        NOT_FOUND: 'Unit not found in database'
      }
    }
  },
  computed: {
    ...mapGetters({
      ingredientList: 'inventory/getIngredientsByCompany',
      getIngredientByName: 'inventory/getIngredientByName'
    })
  },
  mounted () {
  },
  methods: {
    getAlias (item) {
      if (item.alias && item.alias.length > 0) {
        return item.alias.join(', ')
      } else {
        return ''
      }
    },
    convertedValue (ingredientId, unit, qty) {
      const ingredient = this.ingredientList.find(
        (ingredient) => ingredient.ingredientId === ingredientId
      )
      const unitLowerCase = unit.toLowerCase().trim()
      try {
        if (ingredient.measure_type === 'C') {
          return qty
        }
        if (ingredient.measure_type === 'V') {
          const unitType = convert.list('volume').find(u => u.abbr.toLowerCase() === unitLowerCase || u.singular.toLowerCase() === unitLowerCase || u.plural.toLowerCase() === unitLowerCase).abbr
          return volumeToML(unitType, qty)
        } else {
          const unitType = convert.list('mass').find(u => u.abbr.toLowerCase() === unitLowerCase || u.singular.toLowerCase() === unitLowerCase || u.plural.toLowerCase() === unitLowerCase).abbr
          return weightToGram(unitType, qty)
        }
      } catch (e) {
        return qty
      }
    },
    unitList (ingredientId) {
      if (ingredientId) {
        const ingredient = this.ingredientList.find(
          (ingredient) => ingredient.ingredientId === ingredientId
        )
        if (ingredient.measure_type === 'W') {
          return convert.list('mass').filter((element) => this.massUnits.includes(element.abbr)).map(unit => unit.plural)
        } else {
          return convert.list('volume').filter((element) => this.volumeUnits.includes(element.abbr)).map(unit => unit.plural)
        }
      }
    },
    getItem (item) {
      return item.reason.includes(this.INGREDIENT_ERROR.EMPTY) || item.reason.includes(this.INGREDIENT_ERROR.NOT_FOUND)
    },
    closeDialog () {
      this.stepper = 1
      this.dialog = false
    },
    qtyRule (item) {
      let rule = ''
      if (item.ingredient) {
        const qty = this.getIngredientByName(item.ingredient).totalQty
        rule = v => (!!v && v) <= qty || ''
      }
      return rule
    },
    getAvailableQty (name) {
      const byName = this.getIngredientByName(name)
      if (byName) {
        return 'In stock : ' + byName.totalQty || 0
      } else {
        return 'In stock : 0'
      }
    },
    showSnackNotification (text, type) {
      this.snackText = text
      this.snackType = type
      this.snackbar = true
    },
    isValid () {
      return !this.validityStatus && this.parsedData.flatMap(data => data.reason).length !== 0
    },
    async generateCsv () {
      const head = ['ingredient', 'local_alias', 'meal_type', 'stockout_qty', 'unit']
      const data = this.ingredientList.map((ingredient) => {
        const mealType = ['LUNCH', 'DINNER'][Math.floor(Math.random() * 2)]
        let unit
        if (ingredient.measure_type === 'W') {
          unit = 'g'
        } else if (ingredient.measure_type === 'V') {
          unit = 'ml'
        } else {
          unit = 'count'
        }
        return {
          ingredient: ingredient.ingredientName,
          local_alias: this.getAlias(ingredient),
          meal_type: mealType,
          unit: unit,
          stockout_qty: 0
        }
      })
      const csv = JSONToCSV(data, { fields: head })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = getFileName('stockout-sample', 'csv')
      anchor.click()
    },
    parseCSV () {
      const vm = this
      Papa.parse(this.file, {
        worker: true,
        header: true,
        dynamicTyping: true,
        complete: function (results) {
          vm.parsedData = results.data.filter((ingredientData) => ingredientData.stockout_qty > 0)
          results.data.forEach((ingredientData) => {
            ingredientData.reason = []
            ingredientData.id = uuidv4()
            vm.checkValue(ingredientData)
          })
          vm.stepper = 2
        }
      })
    },
    checkValue (ingredientData) {
      this.checkIngredient(ingredientData)
      this.checkQty(ingredientData)
      this.checkMealType(ingredientData)
      this.checkUnit(ingredientData)
    },
    checkUnit (ingredientData) {
      if (!ingredientData.unit) {
        ingredientData.type = 'Invalid'
        ingredientData.unit = ''
        if (ingredientData.reason.length === 0) {
          ingredientData.reason.push(this.UNIT_ERROR.EMPTY)
        }
      } else {
        const index = this.ingredientList.findIndex(
          (ingredient) => ingredient.ingredientName.toLowerCase() === ingredientData.ingredient.toLowerCase()
        )
        if (index !== -1) {
          const measureType = this.ingredientList[index].measure_type
          let availableUnits = []
          if (measureType === 'C') {
            return
          }
          if (measureType === 'W') {
            availableUnits = convert.list('mass')
          } else {
            availableUnits = convert.list('volume')
          }
          const filterList = availableUnits.filter(unit => unit.abbr.toLowerCase() === ingredientData.unit.toLowerCase() ||
                  unit.singular.toLowerCase() === ingredientData.unit.toLowerCase() ||
                  unit.plural.toLowerCase() === ingredientData.unit.toLowerCase())
          if (filterList.length === 0) {
            ingredientData.reason.push(this.UNIT_ERROR.NOT_FOUND)
          } else {
            ingredientData.ingredientId = this.ingredientList[index].ingredientId
            const index1 = ingredientData.reason.findIndex(
              (err) => err === this.UNIT_ERROR.NOT_FOUND
            )
            const index2 = ingredientData.reason.findIndex(
              (err) => err === this.UNIT_ERROR.EMPTY
            )
            if (index1 !== -1) {
              ingredientData.reason.splice(index1, 1)
            }
            if (index2 !== -1) {
              ingredientData.reason.splice(index2, 1)
            }
          }
        } else {
          ingredientData.unit = ''
          ingredientData.reason.push(this.UNIT_ERROR.EMPTY)
        }
      }
    },
    checkQty (ingredientData) {
      if (!ingredientData.stockout_qty) {
        ingredientData.type = 'Invalid'
        ingredientData.reason.push(this.QTY_ERROR.EMPTY)
      } else {
        const index = this.ingredientList.find(
          (ingredient) => ingredient.ingredientName === ingredientData.ingredient
        )
        if (index) {
          const totalQty = index.totalQty
          if (ingredientData.stockout_qty > totalQty) {
            const reasonIndex = ingredientData.reason.findIndex(reason => reason === this.QTY_ERROR.MORE_THAN_AVAILABLE)
            if (reasonIndex === -1) {
              ingredientData.reason = [...new Set(ingredientData.reason), this.QTY_ERROR.MORE_THAN_AVAILABLE]
              ingredientData.type = 'Invalid'
            }
          } else {
            if (ingredientData.reason.includes(this.QTY_ERROR.EMPTY) || ingredientData.reason.includes(this.QTY_ERROR.MORE_THAN_AVAILABLE)) {
              ingredientData.reason = ingredientData.reason.filter(reason => reason !== this.QTY_ERROR.EMPTY && reason !== this.QTY_ERROR.MORE_THAN_AVAILABLE)
            } else {
              if (ingredientData.reason.length === 0) {
                ingredientData.type = 'Valid'
              }
            }
          }
        } else {
          ingredientData.reason = [...new Set(ingredientData.reason), this.QTY_ERROR.EMPTY]
          ingredientData.type = 'Invalid'
        }
      }
    },
    checkMealType (ingredientData) {
      if (!ingredientData.meal_type || !this.mealTypeList.includes(ingredientData.meal_type)) {
        ingredientData.reason = [...new Set(ingredientData.reason), this.MEAL_TYPE_ERROR.EMPTY]
        ingredientData.type = 'Invalid'
        ingredientData.meal_type = ''
      } else {
        if (ingredientData.reason.includes(this.MEAL_TYPE_ERROR.EMPTY)) {
          ingredientData.reason = ingredientData.reason.filter(reason => reason !== this.MEAL_TYPE_ERROR.EMPTY)
        } else {
          if (ingredientData.reason.length === 0) {
            ingredientData.type = 'Valid'
          }
        }
      }
    },
    checkIngredient (ingredientData) {
      if (!ingredientData.ingredient) {
        ingredientData.ingredient = ''
        ingredientData.type = 'Invalid'
        if (ingredientData.reason.length === 0) {
          ingredientData.reason.push(this.INGREDIENT_ERROR.EMPTY)
        }
      } else {
        const index = this.ingredientList.findIndex(
          (ingredient) =>
            ingredient.ingredientName.toLowerCase() === ingredientData.ingredient.toLowerCase()
        )
        if (index === -1) {
          ingredientData.type = 'Invalid'
          ingredientData.ingredient = ''
          if (ingredientData.reason.length === 0) {
            ingredientData.reason.push(this.INGREDIENT_ERROR.NOT_FOUND)
          }
        } else {
          ingredientData.ingredientId = this.ingredientList[index].ingredientId
          const index1 = ingredientData.reason.findIndex(
            (err) => err === this.INGREDIENT_ERROR.NOT_FOUND
          )
          const index2 = ingredientData.reason.findIndex(
            (err) => err === this.INGREDIENT_ERROR.EMPTY
          )
          if (index1 !== -1) {
            ingredientData.reason.splice(index1, 1)
          }
          if (index2 !== -1) {
            ingredientData.reason.splice(index2, 1)
          }
        }
      }
    },
    async stockOut () {
      this.stockoutLoader = true
      const filteredData = this.parsedData.filter(
        (data) => data.type === 'Valid'
      )
      for (let i = filteredData.length - 1; i >= 0; i--) {
        const stockOutItem = {}
        stockOutItem.id = filteredData[i].id
        stockOutItem.type = 'STOCK_OUT'
        stockOutItem.qty = this.convertedValue(filteredData[i].ingredientId, filteredData[i].unit, filteredData[i].stockout_qty)
        stockOutItem.mealType = filteredData[i].meal_type
        stockOutItem.ingredientId = filteredData[i].ingredientId
        stockOutItem.ingredientName = filteredData[i].ingredient
        await this.$store.dispatch('stockOut/addStockOutItem', stockOutItem)
      }
      this.stockoutLoader = false
      this.parsedData = []
      await this.$store.dispatch('inventory/fetchAllIngredients')
      this.showSnackNotification('Ingredients successfully stocked out.', 'success')
    }
  }
}
</script>

<style scoped>
    .v-input--selection-controls {
        margin-top: 0 !important;
    }

    .dialog-header {
        font-size: 1.25rem;
        font-weight: 500;
    }

    .center-items {
        align-items: center;
        justify-content: center;
    }

    .qty-text-field {
        padding-top: 13px;
        max-width: 90px;
    }

    .ingredient-text-field {
        padding-top: 13px;
        max-width: 150px;
    }

    .reason-text {
        color: red;
    }
</style>
