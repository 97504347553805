<template>
  <v-tour
    v-if="onboardingStatus('dashboardOnboarding')"
    name="sm_onboarding"
    :options="options"
    :steps="steps"
    :callbacks="callbacks"
  />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Dashboard',
  data () {
    return {
      callbacks: {
        onFinish: this.finishOnboarding,
        onSkip: this.finishOnboarding
      },
      options: {
        useKeyboardNavigation: true,
        startTimeout: 1000,
        labels: {
          buttonSkip: 'Skip',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish'
        }
      },
      steps: [
        {
          target: '#v-step-0',
          header: {
            title: 'See your data Visually!'
          },
          content: 'See top 5 purchased ingredients and ingredients getting expired',
          params: {
            placement: 'top',
            enableScrolling: false
          }
        },
        {
          target: '#v-step-1',
          header: {
            title: 'Check your pantry levels'
          },
          content: 'See what\'s available in your pantry',
          params: {
            placement: 'left'
          }
        },
        {
          target: '#v-step-2',
          header: {
            title: 'Download your data locally'
          },
          content: 'Export all of your data in csv format',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#v-step-3',
          header: {
            title: 'All products on display'
          },
          content: 'See what products are available to stock in',
          params: {
            placement: 'right'
          }
        },
        {
          target: '#v-step-4',
          content: 'Create a new product by clicking this button',
          params: {
            enableScrolling: false
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
      onboardingStatus: 'config/getOnboardingStatus'
    })
  },
  mounted: function () {

  },
  methods: {
    async finishOnboarding () {
      await this.$store.dispatch('config/finishOnboarding', {
        key: 'dashboardOnboarding',
        companyId: this.user.company_name_slug + '_' + this.user.company_id
      })
    }
  }
}
</script>

<style scoped>

</style>
