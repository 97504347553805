<template>
  <v-card class="mx-auto">
    <v-row class="d-flex justify-center my-3">
      <v-col v-if="!isLoading">
        <v-row @click="showIngredients">
          <v-col
            class="d-flex justify-center"
            cols="4"
          >
            <v-icon
              class="font-color"
              size="50"
            >
              mdi-food-variant
            </v-icon>
          </v-col>
          <v-col
            class=""
            cols="8"
          >
            <h2 class="font-color">
              {{ ingredients.length }}
            </h2>
            <p class="text-uppercase mb-1 font-13 font-weight-bold font-color">
              Total Ingredients
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-progress-circular
        v-if="isLoading"
        class="my-3"
        color="#d96c07"
        indeterminate
      />
    </v-row>
    <IngredientsTableDialog
      :dialog="dialog"
      title="total-ingredients-list"
      :ingredients="ingredients"
      :is-loading="isLoading"
      @closeDialog="closeDialog"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import IngredientsTableDialog from '@/views/Dashboard/components/IngredientsTableDialog'

export default {
  name: 'TotalIngredients',
  components: { IngredientsTableDialog },
  props: {
    isLoading: Boolean
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters({ ingredients: 'inventory/getIngredientsByCompany' })
  },
  methods: {
    showIngredients () {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
    .my-3 {
        padding: 1.25rem;
    }
    .font-color {
        color: #d96c07;
        cursor: pointer;
    }
</style>
