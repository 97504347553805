<template>
  <v-main class="hero">
    <v-container fluid>
      <v-row
        align="center"
        justify="center"
        style="height: 100vh"
      >
        <v-col cols="8">
          <v-card class="mx-10">
            <div class="card-box">
              <v-container class="my-10  pa-5 ">
                <v-row
                  class="mb-3 pa-5 d-flex align-center justify-center"
                >
                  <h1 class="font-weight-bold font-36">
                    SM Pantry
                  </h1>
                </v-row>
                <v-row class="my-2 mx-5 d-flex align-center justify-center">
                  <span
                    class="font-weight-bold font-24"
                  >Please Wait while we Log you in...</span>
                </v-row>
                <v-row
                  class="my-2 pa-5 mb-10 d-flex align-center justify-center"
                >
                  <v-progress-circular
                    v-if="formLoader"
                    indeterminate
                  />
                </v-row>
              </v-container>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      :color="snackColor"
    >
      {{ snackText }}
    </v-snackbar>
  </v-main>
</template>

<script>
import router from '../../router/index'
import axios from 'axios'

export default {
  name: 'SSOLogin',
  data () {
    return {
      snackText: '',
      snackbar: false,
      snackColor: 'green',
      formLoader: false,
      valid: true
    }
  },
  async created () {
    this.formLoader = true
    const vm = this
    try {
      localStorage.removeItem('user')
      const token = decodeURIComponent(encodeURIComponent(vm.$route.query.token).split('%20').join('+'))
      const res = await axios.post(
        `${process.env.VUE_APP_SMP_BASE_URL}/api/user`,
        {
          token: token
        }
      )
      // Token dependent
      if (res.data.status) {
        const { data } = res.data
        this.snackText = 'User login successful, redirecting to dashboard.'
        this.snackColor = 'green'
        this.snackbar = true
        await this.$store.dispatch('auth/anonymousLogin', data)
        await this.$store.dispatch('config/fetchConfiguration')
        this.formLoader = false
      } else {
        this.snackText = 'unauthorized user, try again'
        this.snackColor = 'red'
        this.snackbar = true
        await router.push('/logout')
      }
    } catch (e) {
      this.snackText = 'error occurred while logging in, try again'
      this.snackColor = 'red'
      this.snackbar = true
      this.formLoader = true
      await router.push('/logout')
    }
  }
}
</script>

<style scoped>
.hero {
  background: url("../../assets/images/bg-new.jpg");
  background-size: cover;
  height: 95vh;
}
</style>
