import { gramToKilogram, mlToLitre } from '@/utilities/convertToGramsUtil'

export const getMinQtyWithUnit = (ingredient) => {
  const selectedIngredient = JSON.parse(JSON.stringify(ingredient))
  const minQty = selectedIngredient.minQty
  if (selectedIngredient.measure_type === 'C') {
    return `${selectedIngredient.minQty.toFixed(0)} (Count)`
  }
  if (typeof selectedIngredient.minQtyUnit !== 'undefined') {
    return `${selectedIngredient.minQty} (${selectedIngredient.minQtyUnit})`
  } else {
    if (selectedIngredient.measure_type === 'W') {
      if (minQty > 1000) {
        return `${gramToKilogram(minQty)} (kg)`
      } else {
        return `${selectedIngredient.minQty} (gram)`
      }
    } else {
      if (minQty > 1000) {
        return `${mlToLitre(minQty)} (liter)`
      } else {
        return `${selectedIngredient.minQty} (ml)`
      }
    }
  }
}
export const getTotalQty = (ingredient, qtyKey = 'totalQty') => {
  try {
    const selectedIngredient = JSON.parse(JSON.stringify(ingredient))
    const qty = selectedIngredient[qtyKey]
    if (selectedIngredient.measure_type === 'C') {
      if (qty >= 12) {
        return (qty / 12).toFixed(0)
      } else {
        return qty.toFixed(0)
      }
    }
    if (selectedIngredient.measure_type === 'W') {
      if (qty >= 1000 || qty <= -1000) {
        return gramToKilogram(qty)
      } else {
        return selectedIngredient[qtyKey].toFixed(2)
      }
    } else {
      if (qty >= 1000 || qty <= -1000) {
        return mlToLitre(qty)
      } else {
        return selectedIngredient[qtyKey].toFixed(2)
      }
    }
  } catch (e) {
    return 0.00
  }
}
export const getUnit = (ingredient, qtyKey = 'totalQty') => {
  const selectedIngredient = JSON.parse(JSON.stringify(ingredient))
  const qty = selectedIngredient[qtyKey]
  if (selectedIngredient.measure_type === 'C') {
    if (qty >= 12) {
      return 'dozen'
    } else {
      return 'count'
    }
  }
  if (selectedIngredient.measure_type === 'W') {
    if (qty >= 1000 || qty <= -1000) {
      return 'kg'
    } else {
      return 'gram'
    }
  } else {
    if (qty >= 1000 || qty <= -1000) {
      return 'liter'
    } else {
      return 'ml'
    }
  }
}
