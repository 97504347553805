<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      style="z-index: 20001"
      transition="dialog-bottom-transition"
      @keydown.esc="dialog = false"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          dense
          class="primary ma-3"
          v-bind="attrs"
          v-on="on"
        >
          + Estimate by Recipe
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          <v-btn
            dark
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="dialog-header">Add Items for Stock Estimator</span>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="form.selectedRecipe"
                  multiple
                  label="Select Recipe"
                  :items="recipeListForDropdown"
                  :loading="recipeLoader"
                  dense
                  return-object
                  :rules="notEmptyRule"
                  item-text="recipe_title"
                  item-value="recipe_id"
                  outlined
                >
                  <template #selection="{ item,index }">
                    <span v-if="index > 0 && index <=5">,</span>
                    <span v-if="index <= 5">{{ item.recipe_title }}</span>
                    <span
                      v-if="index === 5"
                      class="grey--text text-caption"
                    >
                      (+{{ form.selectedRecipe.length - 5 }} others)
                    </span>
                  </template>
                  <template #prepend-item>
                    <v-text-field
                      v-model="recipeSearchTerm"
                      outlined
                      autofocus
                      class="mx-2 px-2"
                      label="Search Recipe"
                      dense
                    />
                    <v-divider class="my-2" />
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggle"
                    >
                      <v-list-item-action>
                        <v-icon :color="form.selectedRecipe.length > 0 ? 'primary' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2" />
                  </template>
                </v-select>
              </v-col>
              <div class="font-weight-bold pt-4 text-h6">
                X
              </div>
              <v-col cols="2">
                <v-text-field
                  v-model="form.noOfDays"
                  :rules="countRule"
                  type="number"
                  label="Recipe Count"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="form.noOfPeople"
                  :rules="countRule"
                  outlined
                  type="number"
                  label="No of People"
                  dense
                />
              </v-col>
              <v-col cols="2">
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  @click="addToList"
                >
                  Add To List
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-row
          justify="center"
        >
          <v-col cols="12">
            <v-data-table
              id="v-step-3"
              :headers="headers"
              :items="recipeList"
              :search="search"
              class="px-3 table-striped table-bordered dt-responsive"
            >
              <template #top>
                <v-row class="d-flex align-start justify-content-between mt-2">
                  <v-col
                    class="d-flex align-center"
                    cols="8"
                  >
                    <v-text-field
                      v-model="search"
                      dense
                      clear-icon="mdi-close-circle-outline"
                      clearable
                      label="Search"
                      prepend-inner-icon="mdi-magnify"
                      solo
                    />
                  </v-col>
                </v-row>
              </template>
              <template #item.name="{item}">
                {{ item.selectedRecipe.recipe_title }}
              </template>
              <template #item.noOfDays="{item}">
                <v-text-field
                  v-model="item.noOfDays"
                  :rules="countRule"
                  type="number"
                  dense
                  outlined
                />
              </template>
              <template #item.noOfPeople="{item}">
                <v-text-field
                  v-model="item.noOfPeople"
                  :rules="countRule"
                  type="number"
                  dense
                  outlined
                />
              </template>
              <template #item.action="{ item }">
                <v-icon
                  small
                  @click="deleteFromRecipeList(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!valid"
            @click="calculateStock"
          >
            Calculate Stock
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'StockEstimatorForm',
  data () {
    return {
      dialog: false,
      recipeSearchTerm: '',
      filteredRecipes: [],
      search: '',
      form: {
        selectedRecipe: [],
        noOfPeople: '',
        noOfDays: ''
      },
      headers: [
        {
          text: 'Recipe Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Recipe Count',
          sortable: true,
          value: 'noOfDays'
        },
        {
          text: 'No of People',
          sortable: true,
          value: 'noOfPeople'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      recipeLoader: false,
      recipeList: [],
      valid: false,
      countRule: [
        (v) => !!v || 'this field is required'
      ],
      notEmptyRule: [
        (v) => !!v || 'Please select at least one recipe'
      ]
    }
  },
  computed: {
    ...mapGetters({
      recipes: 'recipe/getAllRecipes',
      getUserInfoByKey: 'auth/getUserInfoByKey'
    }),
    recipeListForDropdown () {
      if (this.recipeSearchTerm) {
        return this.filteredRecipes
      } else {
        return this.recipes
      }
    },
    allRecipesSelected () {
      return this.form.selectedRecipe.length === this.recipes.length
    },
    icon () {
      if (this.allRecipesSelected) return 'mdi-close-box'
      if (!this.allRecipesSelected && this.form.selectedRecipe.length > 0) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  watch: {
    recipeSearchTerm (newVal) {
      if (newVal) {
        const searchQuery = newVal.toLowerCase()
        let recipes = []
        recipes = this.recipes.filter(option =>
          option.recipe_title.toLowerCase().includes(searchQuery)
        )
        this.filteredRecipes = recipes
      } else {
        // Reset the options array if no search query is provided
        this.filteredRecipes = this.recipes
      }
    }
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.allRecipesSelected) {
          this.form.selectedRecipe = []
        } else {
          this.form.selectedRecipe = this.recipes.slice()
        }
      })
    },
    addToList () {
      this.form.selectedRecipe.forEach((recipe) => {
        this.recipeList.push(cloneDeep({
          selectedRecipe: recipe,
          noOfPeople: this.form.noOfPeople,
          noOfDays: this.form.noOfDays
        }))
      })
    },
    closeDialog () {
      this.dialog = false
    },
    deleteFromRecipeList (item) {
      const index = this.recipeList.findIndex(
        (recipe) => recipe.selectedRecipe.recipe_id === item.selectedRecipe.recipe_id
      )
      this.recipeList.splice(index, 1)
    },
    calculateStock () {
      this.$emit('calculateStock', this.recipeList)
      this.closeDialog()
    }
  }
}
</script>

<style scoped>
.multiply-col {
  margin-right: -25px;
  margin-left: -25px;
}
</style>
