import * as firebase from '../../firebase.config'
import store from '@/store/index'
import moment from 'moment'
export const config = {
  namespaced: true,
  state: {
    demoMode: false,
    stockoutOnboarding: false,
    auditOnboarding: false,
    dashboardOnboarding: false,
    stockinOnboarding: false,
    reportOnboarding: false,
    companyDB: 'company/demo'
  },
  mutations: {
    async setConfigurationData (state, payload) {
      state.demoMode = payload.demoMode
      const userInfo = store.getters['auth/getUserInfoByKey']
      const format = userInfo('date_format')
      const dateRange = [
        moment().subtract(7, 'days').format(format),
        moment().format(format)
      ]
      await store.dispatch('reports/getHighlyPurchasedIngredients', { dateRange: dateRange, format: format })
      const expirationRange = [
        moment().format(format),
        moment().add(7, 'days').format(format)
      ]
      await store.dispatch('reports/getIngredientsOfExpiration', { dateRange: expirationRange, format: format })
      state.onboardingDone = payload.onboardingDone
      state.stockoutOnboarding = payload.stockoutOnboarding
      state.auditOnboarding = payload.auditOnboarding
      state.dashboardOnboarding = payload.dashboardOnboarding
      state.stockinOnboarding = payload.stockinOnboarding
      state.reportOnboarding = payload.reportOnboarding
    },
    setDemoMode (state, val) {
      state.demoMode = val
    },
    setOnboardingStatus (state, { key, value }) {
      state[key] = value
    },
    async setCompanyDB (state, dbType) {
      const userInfo = store.getters['auth/getUserInfoByKey']
      const companyName = userInfo('company_name_slug')
      const companyId = userInfo('company_id')
      if (dbType === 'prod') {
        state.companyDB = `company/${companyName}_${companyId}`
      } else {
        state.companyDB = `company/${companyName}_${companyId}/dummy_data/${companyName}_${companyId}`
      }
      await store.dispatch('inventory/fetchAllIngredients')
      //  await store.dispatch('inventory/fetchAllUserDefinedProperties')
      await store.dispatch('inventory/fetchAllProducts')
      await store.dispatch('inventory/fetchAllBrands')
    }
  },
  actions: {
    async restartOnboarding ({ commit }, companyId) {
      const batch = firebase.db.batch()
      const configRef = await firebase.configurationCollection().doc(companyId)
      batch.update(configRef, {
        stockoutOnboarding: true,
        auditOnboarding: true,
        dashboardOnboarding: true,
        stockinOnboarding: true,
        reportOnboarding: true
      })
      await batch.commit()
      commit('setOnboardingStatus', { key: 'stockoutOnboarding', value: true })
      commit('setOnboardingStatus', { key: 'auditOnboarding', value: true })
      commit('setOnboardingStatus', { key: 'dashboardOnboarding', value: true })
      commit('setOnboardingStatus', { key: 'stockinOnboarding', value: true })
      commit('setOnboardingStatus', { key: 'reportOnboarding', value: true })
    },
    async fetchConfiguration ({ commit }) {
      try {
        let configurationData = {}
        firebase.configurationCollection().get().then((snapshot) => {
          snapshot.forEach((doc) => {
            configurationData = doc.data()
          })
          commit('setCompanyDB', 'prod')
          if (Object.keys(configurationData).length) {
            if (configurationData.demoMode) {
              commit('setCompanyDB', 'demo')
            }
            commit('setConfigurationData', configurationData)
          }
        })
      } catch (e) {
        console.error(e)
      }
    },
    async resetData ({ commit }, company) {
      try {
        const configRef = await firebase.configurationCollection().doc(`${company.company_name}_${company.company_id}`)
        await configRef.update({
          demoMode: company.demoMode
        })
        await commit('setDemoMode', company.demoMode)
        if (company.demoMode) {
          commit('setCompanyDB', 'demo')
        } else {
          commit('setCompanyDB', 'prod')
        }
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async finishOnboarding ({ commit }, { key, companyId }) {
      try {
        const batch = firebase.db.batch()
        const configRef = await firebase.db.collection(`company/${companyId}/configuration`).doc(companyId)
        const data = {}
        data[key] = false
        batch.update(configRef, data)
        await batch.commit()
        commit('setOnboardingStatus', { key: key, value: false })
      } catch (e) {
        console.error(e)
      }
    }
  },
  getters: {
    getDemoMode (state) {
      return state.demoMode
    },
    getOnboardingStatus: (state) => (key) => {
      return state[key]
    },
    getCompanyDB: (state) => {
      return state.companyDB
    }
  }
}
