import { render, staticRenderFns } from "./ConsumptionReport.vue?vue&type=template&id=622e8438&scoped=true"
import script from "./ConsumptionReport.vue?vue&type=script&lang=js"
export * from "./ConsumptionReport.vue?vue&type=script&lang=js"
import style0 from "./ConsumptionReport.vue?vue&type=style&index=0&id=622e8438&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "622e8438",
  null
  
)

export default component.exports