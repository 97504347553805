<template>
  <v-row class="mx-3">
    <v-dialog
      v-model="dialog"
      class="overflow-hidden"
      fullscreen
      persistent
      style="z-index: 20001"
      transition="dialog-bottom-transition"
      @keydown.esc="dialog = false"
    >
      <template #activator="{ on, attrs }">
        <div class="d-flex pb-3 pt-3">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Approve
          </v-btn>
        </div>
      </template>
      <v-toolbar
        color="primary"
        dark
      >
        <v-btn
          dark
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Approve Pending Stock Outs</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-container>
          <div class="card-box">
            <v-row class="mx-2 my-2">
              <span class="font-weight-bold font-24">Pending Stock outs</span>
            </v-row>
            <v-data-table
              id="datatable"
              v-model="selected"
              :headers="headers"
              multi-sort
              :sort-by="['date']"
              :sort-desc="[true]"
              :items="getPendingItems()"
              show-select
              class="px-3 table-striped table-bordered dt-responsive"
              :search="search"
              @toggle-select-all="selectAllToggle"
            >
              <template #top>
                <v-row class="d-flex justify-start align-start mt-2">
                  <v-col
                    class="d-flex justify-center align-center"
                    cols="7"
                  >
                    <v-text-field
                      v-model="search"
                      clear-icon="mdi-close-circle-outline"
                      clearable
                      label="Search StockOuts"
                      solo
                    />
                  </v-col>
                  <v-col
                    class="d-flex justify-center align-center"
                    cols="5"
                  >
                    <v-btn
                      v-if="selected.length > 0"
                      class="mb-6"
                      color="primary"
                      dark
                      @click="confirmStockOut"
                    >
                      Approve Selected
                    </v-btn>
                    <v-btn
                      v-if="selected.length > 0"
                      class="mb-6 ml-3"
                      color="error"
                      dark
                      @click="rejectConfirm = true"
                    >
                      Reject
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template #item.ingredientId="{ item }">
                <v-select
                  v-if="
                    item.reason.includes(INGREDIENT_ERROR['EMPTY']) ||
                      item.reason.includes(
                        INGREDIENT_ERROR['NOT_FOUND']
                      )
                  "
                  v-model="item.ingredientId"
                  :items="ingredientList"
                  :rules="[rules.required]"
                  class="ingredient-text-field"

                  item-text="ingredientName"
                  item-value="ingredientId"
                  outlined
                  @input="checkIngredient(item)"
                />
                <span v-else>{{ getIngredientValue(item) }}</span>
              </template>
              <template #item.qty="{ item }">
                <v-text-field
                  v-if="
                    item.reason.includes(QTY_ERROR['EMPTY']) ||
                      item.reason.includes(
                        QTY_ERROR['MORE_THAN_AVAILABLE']
                      )
                  "
                  v-model.number="item.qty"
                  :rules="[rules.required, rules.greaterThenZero]"
                  class="qty-text-field pt-5"

                  outlined
                  type="number"
                  :hint="getAvailableQty(item.ingredientId)"
                  @input="checkQty(item)"
                />
                <span v-else>{{ getUnit(item.ingredientId, item.qty) }} </span>
              </template>
              <template #item.reason="{ item }">
                <ul v-for="reason in item.reason">
                  <li class="reason-text">
                    {{ reason }}
                  </li>
                </ul>
              </template>
              <template #item.valid="{ item }">
                <v-icon
                  v-if="item.valid === 'Valid'"
                  color="green darken-2"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-else
                  color="red darken-2"
                >
                  mdi-alert-circle
                </v-icon>
              </template>
            </v-data-table>
          </div>
        </v-container>
      </v-card>
      <v-snackbar
        v-model="snackbar"
        :timeout="1500"
        color="green"
      >
        {{ snackText }}
      </v-snackbar>
    </v-dialog>
    <v-dialog
      v-model="dialogConfirm"
      max-width="500px"
      @keydown.esc="dialogConfirm = false"
    >
      <v-card>
        <v-card-title>Are you sure you want to Approve the<br> selected items?</v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="dialogConfirm = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="stockOut"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="rejectConfirm"
      max-width="600px"
      @keydown.esc="rejectConfirm = false"
    >
      <v-card>
        <v-card-title>Are you sure you want to Reject the selected items?</v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="rejectConfirm = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="rejectLoader"
            color="primary"
            :loading="rejectLoader"
            text
            @click="rejectStockOut"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
// import { getUnit } from '@/utilities/unitConverter'
import { getTotalQty, getUnit } from '@/utilities/convertToGramsUtil'

export default {
  name: 'ApproveDialog',
  components: {},
  data () {
    return {
      selected: [],
      rejectLoader: false,
      rejectConfirm: false,
      snackbar: false,
      snackText: '',
      search: '',
      headers: [
        {
          text: 'Stockout Date',
          align: 'start',
          sortable: true,
          value: 'date',
          sort: (a, b) => {
            if (moment(a, 'DD-MM-YYYY').isBefore(moment(b, 'DD-MM-YYYY'))) { // a comes first
              return -1
            } else if (moment(b, 'DD-MM-YYYY').isBefore(moment(a, 'DD-MM-YYYY'))) { // b comes first
              return 1
            } else { // equal, so order is irrelevant
              return 0 // note: sort is not necessarily stable in JS
            }
          }
        },
        {
          text: 'Ingredient',
          align: 'start',
          sortable: true,
          value: 'ingredientId'
        },
        {
          text: 'Qty to Stockout',
          sortable: true,
          value: 'qty'
        },
        {
          text: 'Valid',
          value: 'valid'
        },
        {
          text: 'Reason',
          sortable: false,
          value: 'reason'
        }
      ],
      snackType: '',
      dialog: false,
      dialogConfirm: false,
      rules: {
        required: (value) => !!value || 'Required.'
      },
      INGREDIENT_ERROR: {
        NOT_FOUND: 'Ingredient not found in database',
        EMPTY: 'Ingredient is empty'
      },
      QTY_ERROR: {
        EMPTY: 'No qty given to stockout',
        MORE_THAN_AVAILABLE: 'Available qty in system is lesser than qty to stockout'
      }
    }
  },
  computed: {
    ...mapGetters({
      pendingStockOutList: 'stockOut/getPendingStockOutList',
      ingredientList: 'inventory/getIngredientsByCompany',
      getIngredientById: 'inventory/getIngredientById'
    })
  },
  methods: {
    getPendingItems () {
      return this.pendingStockOutList.map((element) => {
        element.reason = []
        this.checkIngredient(element)
        this.checkQty(element)
        return element
      })
    },
    getIngredientValue (item) {
      const ingredientValue = this.getIngredientById(item.ingredientId)
      return ingredientValue.ingredientName
    },
    closeDialog () {
      this.dialog = false
    },
    showSnackNotification (text, type) {
      this.snackText = text
      this.snackType = type
      this.snackbar = true
    },
    getAvailableQty (id) {
      if (
        this.getIngredientById(id) &&
          Object.keys(this.getIngredientById(id)).length > 0
      ) {
        return 'Qty in stock : ' + this.getIngredientById(id).totalQty || 0
      } else {
        return 'Qty in stock : 0'
      }
    },
    checkQty (ingredientData) {
      // console.log(this.ingredientList)
      const index = this.ingredientList.findIndex(
        (ingredient) => ingredient.ingredientId === ingredientData.ingredientId
      )
      if (index !== -1) {
        const totalQty = this.ingredientList[index].totalQty
        if (
          ingredientData.qty > totalQty
        ) {
          ingredientData.valid = 'Invalid'
          if (ingredientData.reason.length === 0) {
            ingredientData.reason.push(this.QTY_ERROR.MORE_THAN_AVAILABLE)
          }
        } else if (ingredientData.qty === 0 || totalQty - ingredientData.qty <= 0) {
          ingredientData.valid = 'Invalid'
          if (ingredientData.reason.length === 0) {
            ingredientData.reason.push(this.QTY_ERROR.MORE_THAN_AVAILABLE)
          }
        } else {
          ingredientData.valid = 'Valid'
          if (ingredientData.reason) {
            const index1 = ingredientData.reason.findIndex(
              (err) => err === this.QTY_ERROR.MORE_THAN_AVAILABLE
            )
            ingredientData.reason.splice(index1, 1)
          }
        }
      }
    },
    checkIngredient (ingredientData) {
      if (!ingredientData.ingredientId) {
        ingredientData.valid = 'Invalid'
        if (ingredientData.reason.length === 0) {
          ingredientData.reason.push(this.INGREDIENT_ERROR.EMPTY)
        }
      } else {
        const index = this.ingredientList.findIndex(
          (ingredient) =>
            ingredient.ingredientId === ingredientData.ingredientId
        )
        if (index === -1) {
          ingredientData.valid = 'Invalid'
          if (ingredientData.reason.length === 0) {
            ingredientData.reason.push(this.INGREDIENT_ERROR.NOT_FOUND)
          }
        } else {
          ingredientData.ingredientId = this.ingredientList[index].ingredientId
          if (ingredientData.reason) {
            const index1 = ingredientData.reason.findIndex(
              (err) => err === this.INGREDIENT_ERROR.NOT_FOUND
            )
            const index2 = ingredientData.reason.findIndex(
              (err) => err === this.INGREDIENT_ERROR.EMPTY
            )
            ingredientData.reason.splice(index1, 1)
            ingredientData.reason.splice(index2, 1)
          }
          ingredientData.valid = 'Valid'
        }
      }
    },
    select (ingredientData) {
    },
    confirmStockOut () {
      this.dialogConfirm = true
    },
    selectAllToggle (props) {
      if (this.selected.length !== this.pendingStockOutList.length) {
        this.selected = []
        const self = this
        props.items.forEach(item => {
          if (item.valid === 'Valid') {
            self.selected.push(item)
          }
        })
      } else this.selected = []
    },
    async rejectStockOut () {
      this.rejectLoader = true
      const data = this.selected
      for (let i = data.length - 1; i >= 0; i--) {
        await this.$store.dispatch('stockOut/deleteStockOutItem', data[i].id)
        this.deleteFromPendingStockOutList(data[i])
      }
      this.selected = []
      await this.$store.dispatch('inventory/fetchAllIngredients')
      this.rejectConfirm = false
      this.showSnackNotification('Ingredients have been rejected for stockout.', 'success')
      this.rejectLoader = false
    },
    async stockOut () {
      const data = this.selected
      for (let i = data.length - 1; i >= 0; i--) {
        const stockOutItem = {}
        stockOutItem.id = data[i].id
        stockOutItem.type = 'STOCK_OUT'
        stockOutItem.qty = data[i].qty
        stockOutItem.ingredientId = data[i].ingredientId
        stockOutItem.ingredientName = this.getIngredientById(data[i].ingredientId).ingredientName
        await this.$store.dispatch('stockOut/addStockOutItem', stockOutItem)
        this.deleteFromPendingStockOutList(data[i])
      }
      this.selected = []
      await this.$store.dispatch('inventory/fetchAllIngredients')
      this.dialogConfirm = false
      this.showSnackNotification('Ingredients successfully stocked out.', 'success')
    },
    deleteFromPendingStockOutList (item) {
      const index = this.pendingStockOutList.findIndex(
        (cat) => cat.id === item.id
      )
      this.pendingStockOutList.splice(index, 1)
    },
    getUnit (ingredientId, qty) {
      console.log(ingredientId, qty)
      const ingredient = this.ingredientList.find(ing => ing.ingredientId === ingredientId)
      if (!ingredient) {
        return 'N/A'
      }
      const quantity = getTotalQty(ingredient.measure_type, qty)
      const unit = getUnit(ingredient.measure_type, qty)
      return quantity + ' ' + unit
    }
  }
}
</script>

<style>

.qty-text-field {
  padding-top: 13px;
  max-width: 90px;
}

.ingredient-text-field {
  padding-top: 13px;
  max-width: 150px;
}

.reason-text {
  color: red;
}
</style>
