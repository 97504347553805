<template>
  <div>
    <v-card class="mx-auto">
      <div class="card-box">
        <v-row class="pa-2 mx-1">
          <h4 class="mb-5">
            Manage Products
          </h4>
        </v-row>
        <v-data-table
          id="datatable"
          height="400px"
          :headers="headers"
          :items="productList"
          fixed-header
          :loading="isLoading"
          :search="search"
          calculate-widths
          multi-sort
          class="px-3 table-striped table-bordered dt-responsive"
        >
          <template #top>
            <v-row
              v-if="isLoading === false"
              class="mt-2"
              justify="center"
              align="start"
            >
              <v-col
                md="6"
                lg="6"
                sm="12"
                xs="12"
              >
                <v-text-field
                  v-model="search"
                  clear-icon="mdi-close-circle-outline"
                  clearable
                  dense
                  label="Search Products"
                  prepend-inner-icon="mdi-magnify"
                  solo
                />
              </v-col>
              <v-col
                id="v-step-4"
                md="3"
                lg="3"
                sm="6"
                xs="6"
              >
                <ProductDialog
                  ref="productDialog"
                />
              </v-col>
              <v-spacer />
              <!--              <v-col
                md="1"
                lg="1"
                sm="6"
                xs="6"
                class="px-2"
                align="end"
              >
                <v-menu>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-settings</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-subheader>Show / Hide Columns</v-subheader>
                    <v-list-item
                      v-for="item in headers"
                      :key="item.id"
                    >
                      <v-checkbox
                        v-model="disabledHeaders"
                        :label="item.text"
                        :value="item"
                      />
                    </v-list-item>
                    &lt;!&ndash;                    <v-list-item class="mx-3">
                      <UserDefinedPropertiesDailog />
                    </v-list-item>&ndash;&gt;
                  </v-list>
                </v-menu>
              </v-col>-->
            </v-row>
          </template>
          <template #item.brandId="{ item }">
            {{ getBrandName(item) }}
          </template>
          <template #item.ingredientId="{ item }">
            {{
              Object.keys(getIngredientByName(item.productName)).length > 0 ? getIngredientByName(item.productName).ingredientName : '-'
            }}
          </template>
          <template #item.expiryDate="{ item }">
            {{
              getDaysToExpire(item.expiryDate)
            }}
          </template>
          <template #item.isPerishable="{ item }">
            {{ getPersihableValue(item.isPerishable) }}
          </template>
          <template #item.price="{ item }">
            {{ item.price && parseFloat(item.price) === 0 ? 'NA' : formatNumber(item.price) + ' / ' + item.priceUnit }}
          </template>
          <template
            v-if="isAccessible"
            #item.action="{ item }"
          >
            <v-icon
              class="mr-2"
              size="25"
              @click.stop="editProduct(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              class="mr-2"
              size="25"
              @click.stop="deleteProduct(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="text-h5"
        >
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="dialogDelete = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="1500"
      color="green"
    >
      {{ snackText }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import ProductDialog from './components/ProductDialog'
import roleMixin from '../../utilities/mixins/roleMixin'
import { numberFormatter } from '@/utilities/numberFormatter'

export default {
  name: 'Inventory',
  components: { ProductDialog },
  mixins: [roleMixin],
  data () {
    return {
      snackText: '',
      snackbar: false,
      snackType: 'success',
      search: '',
      isLoading: true,
      signOutLoader: false,
      dialogDelete: false,
      productDialog: false,
      deletingProduct: {}
    }
  },
  computed: {
    ...mapGetters({
      productList: 'inventory/getAllProducts',
      getBrandById: 'inventory/getBrandById',
      getUnitById: 'inventory/getUnitById',
      getUserInfoByKey: 'auth/getUserInfoByKey',
      getIngredientById: 'inventory/getIngredientById',
      getIngredientByName: 'inventory/getIngredientByName',
      getPriceByProductId: 'inventory/getPriceByProductId'
    }),
    currency () {
      return this.getUserInfoByKey('currency')
    },
    headers () {
      return [
        {
          text: 'Product Name',
          align: 'start',
          sortable: true,
          value: 'productName',
          width: '200px'
        },
        {
          text: 'Brand',
          value: 'brandId',
          width: '100px'
        },
        {
          text: 'Barcode ID',
          value: 'barcodeId',
          width: '100px'
        },
        {
          text: 'Ingredient',
          value: 'ingredientId',
          width: '120px'
        },
        {
          text: `Price (${this.currency})`,
          value: 'price',
          width: '120px'
        },
        {
          text: 'Perishable',
          value: 'isPerishable',
          width: '110px'
        },
        {
          text: 'Days to Expire',
          value: 'expiryDate',
          width: '110px'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'action',
          width: '100px'
        }
      ]
    }
  },
  async mounted () {
    this.isLoading = false
  },
  methods: {
    getBrandName (item) {
      if (item.brandId) {
        const value = this.getBrandById(item.brandId)
        return value ? value.brandName || '-' : '-'
      }
      return '-'
    },
    getPersihableValue (isPerishable) {
      if (isPerishable) {
        return 'Yes'
      }
      return 'No'
    },
    getDaysToExpire (date) {
      if (date) {
        const expiryDate = moment(date, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'M-D-YYYY', 'D-M-YYYY', 'M-DD-YYYY', 'DD-M-YYYY'
        ])
        if (!expiryDate.isValid()) {
          debugger
          return 'NA'
        } else {
          const current = moment().startOf('day')
          // Difference in number of days
          return moment.duration(expiryDate.diff(current)).asDays()
        }
      }
      return 'NA'
    },
    deleteProduct (item) {
      this.dialogDelete = true
      this.deletingProduct = item
    },
    async deleteItemConfirm () {
      const response = await this.$store.dispatch(
        'inventory/deleteProduct',
        this.deletingProduct
      )
      this.dialogDelete = false
      if (response) {
        this.snackText = 'Product successfully deleted'
        this.snackType = 'success'
        this.snackbar = true
      } else {
        this.snackText = 'Product successfully deleted'
        this.snackType = 'success'
        this.snackbar = true
      }
    },
    editProduct (item) {
      this.$refs.productDialog.dialogType = 'edit'
      this.$refs.productDialog.openDialog(item)
    },
    formatNumber (price) {
      return numberFormatter(parseFloat(price))
    }
  }
}
</script>
<style scoped>
#wrapper {
  margin-top: 20px;
}
</style>
