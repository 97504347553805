import * as firebase from '../../firebase.config'
import store from './index'
import moment from 'moment'
import { gramToKilogram, mlToLitre } from '@/utilities/convertToGramsUtil'
import axios from 'axios'

export const reports = {
  namespaced: true,
  state: {
    consumptionReport: [],
    weeklyStockActivity: [],
    graphData: {},
    solidGraphLoader: true,
    guideHTML: '',
    liquidGraphLoader: true,
    purchasedSolidIngredientGraphOptions: {
      title: {
        textStyle: {
          fontSize: 14
        },
        text: 'Top 5 Purchased Solid Ingredients',
        subtext: '(Last 7 days)'
      },
      tooltip: {
        trigger: 'axis',
        formatter: '{b0} <br/> <br/>{a0} : {c0} Kg<br />{a1} : {c1} Kg<br />{a2} : {c2} Kg<br />',
        axisPointer: {
          type: 'shadow'
        }
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: { show: true }
        }
      },
      legend: {
        textStyle: {
          fontSize: 10
        },
        top: 50,
        data: ['Available', 'Min Qty', 'Purchased Qty']
      },
      grid: {
        left: '2%',
        right: '4%',
        bottom: '3%',
        top: 100,
        containLabel: true

      },
      xAxis: [

        {
          type: 'category',
          data: [],
          axisLabel: {
            interval: 0,
            fontSize: 9,
            formatter: function (value, index) {
              return value.replace(/(.{10})..+/, '$1...')
            }
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          axisLine: {
            show: true
          },
          axisTick: {
            show: false
          },
          splitNumber: 5,
          type: 'value',
          axisLabel: {
            formatter: '{value} kg'
          }
        }

      ],
      series: [
        {
          name: 'Available',
          type: 'bar',
          stack: 'qty',
          data: []
        },
        {
          name: 'Min Qty',
          type: 'bar',
          stack: 'qty',
          data: []
        },
        {
          name: 'Purchased Qty',
          type: 'bar',
          stack: 'qty',
          data: []
        }
      ]
    },
    purchasedLiquidIngredientGraphOptions: {
      title: {
        textStyle: {
          fontSize: 14
        },
        text: 'Top 5 Purchased Fluid Ingredients',
        subtext: '(Last 7 days)'
      },
      tooltip: {
        trigger: 'axis',
        formatter: '{b0} <br/> <br/>{a0} : {c0} Ltr<br />{a1} : {c1} Ltr<br />{a2} : {c2} Ltr<br />',
        axisPointer: {
          type: 'shadow'
        }
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: { show: true }
        }
      },
      legend: {
        textStyle: {
          fontSize: 10
        },
        top: 50,
        data: ['Available', 'Min Qty', 'Purchased Qty']
      },
      grid: {
        left: '2%',
        right: '4%',
        bottom: '3%',
        top: 100,
        containLabel: true

      },
      xAxis: [

        {
          type: 'category',
          data: [],
          axisLabel: {
            interval: 0,
            fontSize: 9,
            formatter: function (value, index) {
              return value.replace(/(.{10})..+/, '$1...')
            }
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          axisLine: {
            show: true
          },
          axisTick: {
            show: false
          },
          splitNumber: 5,
          type: 'value',
          axisLabel: {
            formatter: '{value} ltr'
          }
        }

      ],
      series: [
        {
          name: 'Available',
          type: 'bar',
          stack: 'qty',
          barWidth: '50%',
          data: []
        },
        {
          name: 'Min Qty',
          type: 'bar',
          stack: 'qty',
          barWidth: '30%',
          data: []
        },
        {
          name: 'Purchased Qty',
          type: 'bar',
          stack: 'qty',
          barWidth: '30%',
          data: []
        }
      ]
    },
    expiryGraphLoader: true,
    expiryGraphData: {},
    ingredientsOfExpirationGraphOptions: {
      title: {
        textStyle: {
          fontSize: 14
        },
        text: 'Top 5 Ingredients Expiring',
        subtext: '(Within 7 days)'
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: { show: true }
        }
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const getter = store.getters['inventory/getIngredientByName']
          const ingredient = getter(params[0].name)
          return `${params[0].name} <br/> <br/> ${params[0].seriesName} :
                    ${params[0].value} ${ingredient.measure_type === 'V' ? 'Ltr' : 'Kg'}`
        },
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: [],
          axisLabel: {
            interval: 0,
            fontSize: 9,
            formatter: function (value, index) {
              return value.replace(/(.{10})..+/, '$1...')
            }
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          axisLine: {
            show: true
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          type: 'value'
        }
      ],
      series: [
        {
          name: 'In Stock Qty',
          type: 'bar',
          barWidth: '60%',
          data: []
        }
      ]
    }
  },
  actions: {
    async fetchSupportGuide ({ commit }) {
      try {
        const res = await axios.get('https://docs.google.com/feeds/download/documents/export/Export?id=1OWxNCiEJf26PytwLwLBWJ8QxbDIVURqUTa7IjZJL8wI&exportFormat=html')
        commit('setGuideHTML', res.data)
      } catch (e) {
        console.error(e)
      }
    },
    async fetchConsumptionReport ({ commit }, { ingredientList, dateRange }) {
      try {
        const consumptionReport = Object.create(null)
        const ingredientInfoById = store.getters['inventory/getIngredientById']
        for (const ingredient of ingredientList) {
          consumptionReport[ingredient] = []
          const snapshot = await firebase.stockCollection()
            .where('ingredientName', '==', ingredient)
            .where('type', '==', 'STOCK_OUT')
            .get()
          if (snapshot.docs) {
            snapshot.docs.forEach((val) => {
              const data = val.data()
              const selectedIngredient = ingredientInfoById(data.ingredientId)
              if (selectedIngredient.measure_type === 'V') {
                data.qty = mlToLitre(data.qty)
              } else {
                data.qty = gramToKilogram(data.qty)
              }
              if (dateRange.length === 1) {
                if (moment(data.timeStamp.toDate()).isSame(moment(dateRange[0], 'YYYY-MM-DD'), 'day')) {
                  consumptionReport[ingredient] = [
                    ...consumptionReport[ingredient],
                    ...[data]
                  ]
                }
              } else {
                if (
                  moment(data.timeStamp.toDate()).isSameOrAfter(moment(dateRange[0], 'YYYY-MM-DD'), 'day') &&
                        moment(data.timeStamp.toDate()).isSameOrBefore(moment(dateRange[1], 'YYYY-MM-DD'), 'day')
                ) {
                  consumptionReport[ingredient] = [
                    ...consumptionReport[ingredient],
                    ...[data]
                  ]
                }
              }
            })
          }
        }
        commit('setConsumptionReport', consumptionReport)
      } catch (e) {
      }
    },
    async getStockData ({ commit }, { type, dateRange }) {
      try {
        const userInfo = store.getters['auth/getUserInfoByKey']
        const dateFormat = userInfo('date_format')
        const query = await firebase.stockCollection().where('type', '==', type)
        const snapshot = await query.get()
        if (snapshot.docs) {
          snapshot.docs.forEach(doc => {
            const data = doc.data()
            const date = moment(data.timeStamp.toDate())
            const afterDate = moment(dateRange[0], dateFormat)
            const beforeDate = moment(dateRange[1], dateFormat)
            if (date.isSameOrAfter(afterDate) && date.isSameOrBefore(beforeDate)) {
              commit('setWeeklyStockActivity', data)
            }
          })
        }
      } catch (e) {
      }
    },
    async getHighlyPurchasedIngredients ({ commit }, { dateRange, format }) {
      const solidIngredients = {}
      const liquidIngredients = {}
      const ingredientInfoById = store.getters['inventory/getIngredientById']
      const stockSnapshot = await firebase.stockCollection()
        .where('type', '==', 'STOCK_IN')
        .get()
      if (!stockSnapshot.empty) {
        stockSnapshot.docs.forEach(doc => {
          const data = doc.data()
          const date = moment(data.timeStamp.toDate())
          const afterDate = moment(dateRange[0], format)
          const beforeDate = moment(dateRange[1], format)
          if (date.isSameOrAfter(afterDate, 'day') && date.isSameOrBefore(beforeDate, 'day')) {
            const type = ingredientInfoById(data.ingredientId).measure_type
            if (type === 'W') {
              if (!solidIngredients[data.ingredientId]) {
                solidIngredients[data.ingredientId] = []
              }
              solidIngredients[data.ingredientId].push(data)
            } else
            if (type === 'V') {
              if (!liquidIngredients[data.ingredientId]) {
                liquidIngredients[data.ingredientId] = []
              }
              liquidIngredients[data.ingredientId].push(data)
            }
          }
        })
      }
      Object.keys(solidIngredients).forEach(ingredientId => {
        solidIngredients[ingredientId].totalStockInQty = solidIngredients[ingredientId].reduce((a, b) => a + (b.qty || 0), 0)
        solidIngredients[ingredientId].availableQty = ingredientInfoById(ingredientId).totalQty
        solidIngredients[ingredientId].minQty = Math.round(ingredientInfoById(ingredientId).minQty)
        solidIngredients[ingredientId].ingredientName = ingredientInfoById(ingredientId).ingredientName
        solidIngredients[ingredientId].measure_type = ingredientInfoById(ingredientId).measure_type
      })
      Object.keys(liquidIngredients).forEach(ingredientId => {
        liquidIngredients[ingredientId].totalStockInQty = liquidIngredients[ingredientId].reduce((a, b) => a + (b.qty || 0), 0)
        liquidIngredients[ingredientId].availableQty = ingredientInfoById(ingredientId).totalQty
        liquidIngredients[ingredientId].minQty = Math.round(ingredientInfoById(ingredientId).minQty)
        liquidIngredients[ingredientId].ingredientName = ingredientInfoById(ingredientId).ingredientName
        liquidIngredients[ingredientId].measure_type = ingredientInfoById(ingredientId).measure_type
      })
      const sortedSolidIngredients = sortHighlyPurchasedIngredients(solidIngredients)
      const sortedLiquidIngredients = sortHighlyPurchasedIngredients(liquidIngredients)

      if (sortedSolidIngredients.length > 5) {
        sortedSolidIngredients.length = 5
      }
      if (sortedLiquidIngredients.length > 5) {
        sortedLiquidIngredients.length = 5
      }
      commit('setGraphData', {
        solid: {
          ingredients: sortedSolidIngredients.map(ingredient => ingredient.ingredientName),
          totalStockInQty: sortedSolidIngredients.map(ingredient =>
            ingredient.totalStockInQty === 0 ? 0 : gramToKilogram(ingredient.totalStockInQty)),
          availableQty: sortedSolidIngredients.map(ingredient => ingredient.availableQty === 0 ? 0 : gramToKilogram(ingredient.availableQty)),
          minQty: sortedSolidIngredients.map(ingredient => ingredient.minQty === 0 ? 0 : gramToKilogram(ingredient.minQty))
        },
        liquid: {
          ingredients: sortedLiquidIngredients.map(ingredient => ingredient.ingredientName),
          totalStockInQty: sortedLiquidIngredients.map(ingredient => ingredient.totalStockInQty === 0 ? 0 : mlToLitre(ingredient.totalStockInQty)),
          availableQty: sortedLiquidIngredients.map(ingredient => ingredient.availableQty === 0 ? 0 : mlToLitre(ingredient.availableQty)),
          minQty: sortedLiquidIngredients.map(ingredient => ingredient.minQty === 0 ? 0 : mlToLitre(ingredient.minQty))
        }
      })
    },
    async getIngredientsOfExpiration ({ commit }, { dateRange, format }) {
      const ingredients = []
      const ingredientInfoById = store.getters['inventory/getIngredientById']
      const ingredientInfoByName = store.getters['inventory/getIngredientByName']
      const stockSnapshot = await firebase.stockCollection()
        .where('type', '==', 'STOCK_IN')
        .where('expiryDate', '!=', '')
        .get()
      if (!stockSnapshot.empty) {
        stockSnapshot.docs.forEach(doc => {
          const data = doc.data()
          const date = moment(data.expiryDate)
          const afterDate = moment(dateRange[0], format)
          const beforeDate = moment(dateRange[1], format)
          if (date.isSameOrAfter(afterDate) && date.isSameOrBefore(beforeDate)) {
            const ingredient = ingredientInfoById(data.ingredientId)
            ingredients.push({ date: data.expiryDate, availableQty: ingredient.totalQty, ingredientName: ingredient.ingredientName })
          }
        })
      }
      const uniqueIngredients = Array.from(new Set(ingredients.map(ingredient => ingredient.ingredientName)))
      if (uniqueIngredients.length > 5) {
        uniqueIngredients.length = 5
      }
      const availableQtyArr = []
      uniqueIngredients.forEach(ingredientName => {
        availableQtyArr.push({ totalQty: ingredientInfoByName(ingredientName).totalQty, measureType: ingredientInfoByName(ingredientName).measure_type })
      })
      commit('setExpiryGraphData', {
        ingredients: uniqueIngredients,
        availableQty: availableQtyArr
      })
    }
  },

  mutations: {
    setGuideHTML (state, val) {
      state.guideHTML = val
    },
    setConsumptionReport (state, val) {
      state.consumptionReport = val
    },
    setWeeklyStockActivity (state, val) {
      state.weeklyStockActivity.push(val)
    },
    setGraphData (state, val) {
      state.graphData = val
      const { solid, liquid } = val
      state.purchasedSolidIngredientGraphOptions.xAxis[0].data = solid.ingredients
      state.purchasedSolidIngredientGraphOptions.series[0].data = solid.availableQty
      state.purchasedSolidIngredientGraphOptions.series[1].data = solid.minQty
      state.purchasedSolidIngredientGraphOptions.series[2].data = solid.totalStockInQty
      state.purchasedLiquidIngredientGraphOptions.xAxis[0].data = liquid.ingredients
      state.purchasedLiquidIngredientGraphOptions.series[0].data = liquid.availableQty
      state.purchasedLiquidIngredientGraphOptions.series[1].data = liquid.minQty
      state.purchasedLiquidIngredientGraphOptions.series[2].data = liquid.totalStockInQty
      state.solidGraphLoader = false
      state.liquidGraphLoader = false
    },
    setExpiryGraphData (state, val) {
      state.expiryGraphData = val
      const { ingredients, availableQty } = val
      const expiryData = availableQty.map(ingredient => {
        if (ingredient.measureType === 'V') {
          ingredient.totalQty = mlToLitre(ingredient.totalQty)
        } else {
          ingredient.totalQty = gramToKilogram(ingredient.totalQty)
        }
        return ingredient.totalQty
      })
      state.ingredientsOfExpirationGraphOptions.xAxis[0].data = ingredients
      state.ingredientsOfExpirationGraphOptions.xAxis[0].axisLabel.interval = ingredients.length > 5 ? 1 : 0
      state.ingredientsOfExpirationGraphOptions.series[0].data = expiryData
      state.expiryGraphLoader = false
    }

  },
  getters: {
    getSupportGuideHTML: (state) => {
      return state.guideHTML
    },
    getConsumptionReport: (state) => {
      return state.consumptionReport
    },
    getGraphData: (state) => {
      return state.graphData
    },
    getPurchasedSolidIngredientGraphOptions: (state) => {
      return state.purchasedSolidIngredientGraphOptions
    },
    getPurchasedLiquidIngredientGraphOptions: (state) => {
      return state.purchasedLiquidIngredientGraphOptions
    },
    getWeeklyStockActivityByType: (state) => (type) => {
      return state.weeklyStockActivity.filter(stock => stock.type === type)
    },
    getSolidGraphLoader: (state) => state.solidGraphLoader,
    getLiquidGraphLoader: (state) => state.liquidGraphLoader,
    getExpiryGraphLoader: (state) => state.expiryGraphLoader,
    getExpiryGraphOptions: (state) => {
      return state.ingredientsOfExpirationGraphOptions
    }
  }
}

function sortHighlyPurchasedIngredients (obj) {
  var arr = []
  for (var prop in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop)) {
      arr.push({
        ingredientName: obj[prop].ingredientName,
        minQty: obj[prop].minQty,
        availableQty: obj[prop].availableQty,
        totalStockInQty: obj[prop].totalStockInQty
      })
    }
  }
  arr.sort(function (a, b) { return b.value - a.value })
  // arr.sort(function(a, b) { a.value.toLowerCase().localeCompare(b.value.toLowerCase()); }); //use this to sort as strings
  return arr // returns array
}
