<template>
  <v-dialog
    :value="dialog"
    style="margin-top: 10px;z-index: 1000"
    max-width="900"
    @close="()=>$emit('closeDialog')"
    @cancel="()=>$emit('closeDialog')"
    @click:outside="()=>$emit('closeDialog')"
  >
    <v-card class="mx-auto">
      <div class="pa-5">
        <v-row class="pa-2 mx-1">
          <h4 class="mb-5">
            Ingredients
          </h4>
          <v-spacer />
        </v-row>
        <v-data-table
          height="250px"
          :headers="header"
          :loading="isLoading"
          fixed-header
          calculate-widths
          :item-class="outOfStockBg"
          :items="ingredients"
          :search="search"
          multi-sort
          class="px-3 table-striped table-bordered dt-responsive"
        >
          <template #top>
            <v-row
              v-if="isLoading === false"
              class="mt-2 d-flex align-start justify-start"
            >
              <v-col
                class="d-flex justify-end align-center"
                cols="7"
              >
                <v-text-field
                  v-model="search"
                  clear-icon="mdi-close-circle-outline"
                  clearable
                  dense
                  label="Search Ingredients"
                  prepend-inner-icon="mdi-magnify"
                  solo
                />
              </v-col>
              <v-col
                id="v-step-2"
                cols="1"
              >
                <v-btn
                  v-if="isAccessible"
                  class="primary"
                  @click="exportIngredients"
                >
                  Export
                  <v-icon
                    right
                    dark
                  >
                    mdi-table-arrow-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template #item.totalQty="{item}">
            {{ getTotalQty(item) }}
          </template>
          <template #item.alias="{item}">
            {{ getAlias(item) }}
          </template>
          <template #item.unitId="{item}">
            {{ getUnit(item) }}
          </template>
          <template #item.minQty="{item}">
            {{ getMinQty(item) }}
          </template>
          <template #item.addToStock="{ item }">
            <v-btn
              v-if="item.totalQty === 0 || item.totalQty < item.minQty"
              small
              text
              @click="openDialog(item)"
            >
              <span class="outofstock-style">Add to Stock</span>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import roleMixin from '@/utilities/mixins/roleMixin'
import { v4 as uuidv4 } from 'uuid'
import { parse as JSONToCSV } from 'json2csv'
import { mapGetters } from 'vuex'
import { getFileName } from '@/utilities/fileNameUtil'
import { getMinQtyWithUnit, getTotalQty, getUnit } from '@/utilities/unitConverter'

export default {
  name: 'IngredientsTableDialog',
  mixins: [roleMixin],
  props: {
    title: {
      type: String,
      default: 'ingredient-report'
    },
    dialog: {
      type: Boolean,
      default: false
    },
    ingredients: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: '',
      header: [
        {
          id: uuidv4(),
          text: 'Ingredient Name',
          align: 'start',
          sortable: true,
          value: 'ingredientName',
          width: '150px'
        },
        {
          id: uuidv4(),
          text: 'Local Alias',
          align: 'start',
          sortable: true,
          value: 'alias',
          width: '20%'
        },
        {
          id: uuidv4(),
          text: 'Minimum Qty',
          sortable: true,
          value: 'minQty',
          width: '10%'
        },
        {
          id: uuidv4(),
          text: 'Available Qty',
          sortable: true,
          value: 'totalQty',
          width: '10%'
        },
        {
          id: uuidv4(),
          text: 'Unit',
          sortable: true,
          value: 'unitId',
          width: '10%'
        },
        {
          id: uuidv4(),
          text: '',
          sortable: false,
          value: 'addToStock',
          width: '50px'
        }

      ],
      editingItem: {}
    }
  },
  computed: {
    ...mapGetters({
      getProductsByIngredientId: 'inventory/getProductsByIngredientId',
      getBrandById: 'inventory/getBrandById'
    })
  },
  methods: {
    getAlias (item) {
      if (item.alias && item.alias.length > 0) {
        return item.alias.join(', ')
      } else {
        return ''
      }
    },
    outOfStockBg (item) {
      return item.totalQty === 0 || item.totalQty < item.minQty ? 'outofstock-style' : ''
    },
    exportIngredients () {
      const vm = this
      const head = ['ingredient', 'local_alias', 'product_name', 'brand_name', 'expiry_date', 'minQty', 'qty', 'unit', 'price']
      const data = []
      this.ingredients.forEach((ingredient) => {
        let rowData = {}
        const products = vm.getProductsByIngredientId(ingredient.ingredientId)
        if (products.length > 0) {
          products.forEach(product => {
            rowData.ingredient = ingredient.ingredientName
            rowData.local_alias = this.getAlias(ingredient)
            rowData.product_name = ingredient.ingredientName
            rowData.brand_name = vm.getBrandById(product.brandId).brandName
            rowData.expiry_date = product.expiryDate || ''
            rowData.minQty = this.getTotalQty(ingredient, 'minQty')
            rowData.qty = this.getTotalQty(ingredient, 'totalQty')
            rowData.unit = this.getUnit(ingredient, 'totalQty')
            rowData.price = product.price
            rowData.barcode = product.barcodeId
            data.push(rowData)
            rowData = {}
          })
        }
      })
      const csv = JSONToCSV(data, { fields: head })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = getFileName(this.title, 'csv')
      anchor.click()
    },
    getMinQty (ingredient) {
      return getMinQtyWithUnit(ingredient)
    },
    getTotalQty (ingredient, key) {
      return getTotalQty(ingredient, key)
    },
    getUnit (ingredient, key) {
      return getUnit(ingredient, key)
    },
    openDialog (item) {
      this.editingItem = item
      this.$router.push('/stock-in')
    }
  }
}
</script>

<style scoped>
.outofstock-style {
  color: #c93030;
}
</style>
