import * as firebase from '../../firebase.config'
import { v4 as uuidv4 } from 'uuid'
import store from './index'
import axios from 'axios'

export const inventory = {
  namespaced: true,
  state: {
    productList: [],
    brandList: [],
    categoryList: [],
    ingredientList: [],
    headers: [
      {
        id: uuidv4(),
        text: 'Product Name',
        align: 'start',
        sortable: true,
        value: 'productName',
        width: '200px'
      },
      {
        id: uuidv4(),
        text: 'Brand',
        value: 'brandId',
        width: '100px'
      },
      {
        id: uuidv4(),
        text: 'Barcode ID',
        value: 'barcodeId',
        width: '100px'
      },
      {
        id: uuidv4(),
        text: 'Ingredient',
        value: 'ingredientId',
        width: '120px'
      },
      {
        id: uuidv4(),
        text: 'Price',
        value: 'price',
        width: '120px'
      },
      // {
      //   id: uuidv4(),
      //   text: 'Days to Expiry',
      //   value: 'expiryDate',
      //   width: '130px'
      // },
      {
        id: uuidv4(),
        text: 'Perishable',
        value: 'isPerishable',
        width: '110px'
      },
      {
        id: uuidv4(),
        text: 'Actions',
        sortable: false,
        value: 'action',
        width: '100px'
      }
    ],
    stockInList: [],
    unitList: [],
    productData: {
      productId: uuidv4(),
      barcodeId: '',
      productName: '',
      categoryId: '',
      unitId: '',
      qty: '',
      minQty: '',
      expiryDate: '',
      vendorId: ''
    },
    menuManifest: {
      menu: {},
      ingredients: [],
      recipes: []
    },
    displayManifest: false
  },
  actions: {
    async fetchProductByBarcodeId ({ commit }, barcodeId) {
      try {
        const productList = []
        firebase.productCollection()
          .where('barcodeId', '==', barcodeId)
          .get()
          .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
              productList.push(doc.data())
            })
            if (productList) {
              commit('addToScannedProduct', productList[0])
            }
            return true
          })
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async fetchAllProducts ({ commit }) {
      try {
        const productList = []
        let product = {}
        const productSnapshot = await firebase.productCollection().get()
        for (const doc of productSnapshot.docs) {
          product = doc.data()
          productList.push(product)
        }
        commit('setAllProducts', productList)
      } catch (e) {
        console.error(e)
      }
    },
    async stockInIngredient ({ dispatch }, form) {
      try {
        const batch = firebase.db.batch()
        const increment = firebase.fieldValue.increment(form.totalQty)
        const ingredientRef = await firebase.ingredientCollection().doc(
          form.ingredientId
        )
        batch.update(ingredientRef, { totalQty: increment })
        await batch.commit()
        dispatch('fetchAllIngredients')
        return true
      } catch (e) {
        return false
      }
    },
    async addProduct ({ commit, dispatch }, { productData, type }) {
      try {
        const product = JSON.parse(JSON.stringify(productData))
        const batch = firebase.db.batch()
        const productRef = await firebase.productCollection().doc(
          product.productId
        )
        batch.set(productRef, product)
        batch.commit().then(() => {
          if (type === 'new') {
            commit('addToProductList', product)
          } else {
            commit('updateProductList', product)
          }
        })
        const priceData = JSON.parse(JSON.stringify(product))
        await dispatch('updatePriceInSmartMawaid', { ingredientId: priceData.ingredientId, price: priceData.price, name: priceData.productName, priceUnit: priceData.priceUnit.toLowerCase() })
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async updatePriceInSmartMawaid (_, data) {
      const userData = store.getters['auth/getUserInfoByKey']
      await axios.post(
        `${process.env.VUE_APP_SMP_BASE_URL}/api/ingredient/updateprice`,
        {
          companies: [
            {
              company_id: userData('company_id'),
              company_name: userData('company_name')
            }
          ],
          ingredientList: data
        }
      )
    },
    async fetchAllStockIns ({ commit }) {
      try {
        const stockInList = []
        const stockRef = await firebase.stockCollection().where('type', '==', 'STOCK_IN').orderBy('timeStamp', 'desc').get()
        if (stockRef.docs.length !== 0) {
          for (const doc of stockRef.docs) {
            const stock = doc.data()
            if (stock.price) {
              const finalPrice = stock.price / stock.qty * 100
              stock.price = parseFloat(finalPrice.toFixed(2))
            } else {
              stock.price = 0
            }
            stockInList.push(stock)
          }
        }
        commit('setAllStockIns', stockInList)
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async fetchAllUnits ({ commit }) {
      try {
        const unitList = []
        firebase.unitCollection().get().then((snapshot) => {
          snapshot.forEach((doc) => {
            const unit = doc.data()
            unitList.push(unit)
          })
          commit('setAllUnits', unitList)
        })
      } catch (e) {

      }
    },
    async addUnit ({ commit }, form) {
      try {
        await firebase.unitCollection().doc(form.unitId).set(form)
        commit('addToUnitList', form)
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async updateUnit ({ commit }, form) {
      try {
        await firebase.unitCollection().doc(form.unitId).update(form)
        commit('updateUnitList', form)
        return true
      } catch (e) {
        return false
      }
    },
    async deleteUnitById ({ commit }, unitId) {
      try {
        await firebase.unitCollection().doc(unitId).delete()
        commit('deleteUnit', unitId)
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async fetchAllCategories ({ commit }) {
      try {
        const categoryList = []
        await firebase.categoryCollection().get().then((snapshot) => {
          snapshot.forEach((doc) => {
            const category = doc.data()
            categoryList.push(category)
          })
          commit('setAllCategories', categoryList)
        })
      } catch (e) {
      }
    },
    async addCategory ({ commit }, form) {
      try {
        await firebase.categoryCollection().doc(form.categoryId).set(form)
        commit('addToCategoryList', form)
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async updateCategory ({ commit }, form) {
      try {
        await firebase.categoryCollection().doc(form.categoryId).update(form)
        commit('updateCategoryList', form)
        return true
      } catch (e) {
        return false
      }
    },
    async deleteCategoryById ({ dispatch }, categoryId) {
      try {
        const batch = firebase.db.batch()
        const categoryDoc = firebase.categoryCollection().doc(categoryId)
        batch.delete(categoryDoc)
        await firebase.productCollection()
          .where('categoryId', '==', categoryId)
          .get()
          .then((response) => {
            response.docs.forEach((doc) => {
              const docRef = firebase.productCollection().doc(doc.id)
              batch.update(docRef, { categoryId: null })
            })
          })
        batch.commit().then(() => {
          dispatch('fetchAllCategories')
        })
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async fetchAllBrands ({ commit }) {
      try {
        const brandList = []
        await firebase.brandCollection().get().then((snapshot) => {
          snapshot.forEach((doc) => {
            const brand = doc.data()
            brandList.push(brand)
          })
        })
        commit('setAllBrands', brandList)
      } catch (e) {
      }
    },
    async addBrand ({ commit }, form) {
      try {
        await firebase.brandCollection().doc(form.brandId).set(form)
        commit('addToBrandList', form)
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async updateBrand ({ commit }, form) {
      try {
        const data = JSON.parse(JSON.stringify(form))
        await firebase.brandCollection().doc(data.brandId).update(data)
        commit('updateBrandList', data)
        return true
      } catch (e) {
        return false
      }
    },
    async deleteBrandById ({ commit }, brandId) {
      try {
        const batch = firebase.db.batch()
        const branchRef = await firebase.brandCollection().doc(brandId)
        batch.delete(branchRef)
        await firebase.productCollection()
          .where('brandId', '==', brandId)
          .get()
          .then((response) => {
            response.docs.forEach((doc) => {
              const docRef = firebase.productCollection().doc(doc.id)
              batch.update(docRef, { brandId: null })
            })
          })
        batch.commit().then(() => {
          commit('deleteBrand', brandId)
        })
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    /*       async fetchAllUserDefinedProperties ({ commit }) {
      try {
        const userDefinedProperties = []
        firebase.userDefinedProperties().get().then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            const userDefinedProperty = doc.data()
            userDefinedProperties.push(userDefinedProperty)
          })
          commit('setAllUserDefinedProperties', userDefinedProperties)
        })
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async addUserDefinedProperty ({ commit }, form) {
      try {
        const batch = firebase.db.batch()
        const propertyRef = await firebase.userDefinedProperties().doc(
          form.propertyId
        )
        batch.set(propertyRef, form)
        /!* const productSnapshot = await firebase.productCollection().get()
        for (const doc of productSnapshot.docs) {
          const propertyRef = await doc.ref
            .collection('userDefinedProperty')
            .doc(form.propertyId)
          batch.set(propertyRef, {
            propertyId: form.propertyId,
            propertyValue: ''
          })
        } *!/
        await batch.commit().then(() => {
          commit('setAllUserDefinedProperties', form)
        })
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async updateUserDefinedProperty ({ commit }, form) {
      try {
        await firebase.userDefinedProperties().doc(form.propertyId).update(form)
        commit('updateUserDefinedPropertyList', form)
        return true
      } catch (e) {
        return false
      }
    },
    async deleteUserDefinedPropertyById ({ commit }, propertyId) {
      try {
        const batch = firebase.db.batch()
        const userPropertyRef = await firebase.userDefinedProperties().doc(
          propertyId
        )
        batch.delete(userPropertyRef)
        await firebase.productCollection().get().then(async (response) => {
          for (const doc of response.docs) {
            const userDefinedPropertyCollection = doc.ref.collection(
              'userDefinedProperty'
            )
            await userDefinedPropertyCollection
              .where('propertyId', '==', propertyId)
              .get()
              .then((response) => {
                response.docs.forEach((doc) => {
                  batch.delete(doc.ref)
                })
              })
          }
        })
        batch.commit().then(() => {
          commit('removeUserDefinedPropertyById', propertyId)
        })
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    }, */
    async fetchAllIngredients ({ commit }) {
      try {
        const list = []
        await firebase.ingredientCollection().get().then((snapshot) => {
          snapshot.forEach((doc) => {
            const ingredient = doc.data()
            list.push(ingredient)
          })
          commit('setAllIngredients', list)
        })
      } catch (e) {
      }
    },
    async addIngredient ({ commit }, form) {
      try {
        form.totalQty = 0
        await firebase.ingredientCollection().doc(form.ingredientId).set(form)
        commit('addToIngredientList', form)
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async updateIngredient ({ commit }, form) {
      try {
        await firebase.ingredientCollection().doc(form.ingredientId).update(form)
        commit('updateIngredientList', form)
        return true
      } catch (e) {
        return false
      }
    },
    async deleteProduct ({ commit, dispatch }, product) {
      try {
        const batch = firebase.db.batch()
        const productDoc = firebase.productCollection().doc(product.productId)
        batch.delete(productDoc)
        batch.commit().then(() => {
          commit('deleteProduct', product.productId)
        })
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async deleteIngredientById ({ dispatch }, ingredientId) {
      try {
        const batch = firebase.db.batch()
        const categoryDoc = firebase.ingredientCollection().doc(ingredientId)
        batch.delete(categoryDoc)
        await firebase.productCollection()
          .where('ingredientId', '==', ingredientId)
          .get()
          .then((response) => {
            response.docs.forEach((doc) => {
              const docRef = firebase.productCollection().doc(doc.id)
              batch.update(docRef, { ingredientId: null })
            })
          })
        batch.commit().then(() => {
          dispatch('fetchAllIngredients')
        })
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async fetchMenuManifest ({ commit }, { date }) {
      const userData = store.getters['auth/getUserInfoByKey']
      const res = await axios.post(
        `${process.env.VUE_APP_SMP_BASE_URL}/api/menu/get`,
        {
          company_id: userData('company_id'),
          date
        }
      )
      // Token dependent
      if (res.data.status) {
        const data = res.data.data
        commit('setMenuManifest', data)
        commit('setDisplayManifest', true)
      } else {
        commit('setDisplayManifest', false)
      }
    },
    async refreshMenuManifest ({ commit }) {
      commit('refreshManifest')
    },
    async updateStockoutInManifest ({ commit, dispatch }, { id }) {
      try {
        const userData = store.getters['auth/getUserInfoByKey']
        const res = await axios.post(
          `${process.env.VUE_APP_SMP_BASE_URL}/api/menu/stockout`,
          {
            company_id: userData('company_id'),
            id
          }
        )
        if (res.data.status) {
          commit('updateManifest', '1')
        }
      } catch (e) {
        console.log(e)
      }
    }
  },

  mutations: {
    refreshManifest (state) {
      state.menuManifest = {
        menu: {},
        ingredients: [],
        recipes: []
      }
      state.displayManifest = false
    },
    addToProductList (state, val) {
      state.productList.push(val)
    },
    updateProductList (state, val) {
      const index = state.productList.findIndex(
        (item) => item.productId === val.productId
      )
      state.productList.splice(index, 1, val)
    },
    addToScannedProduct (state, val) {
      state.productData = val
    },
    addToCategoryList (state, val) {
      state.categoryList.push(val)
    },
    addToIngredientList (state, val) {
      state.ingredientList.push(val)
    },
    addToUnitList (state, val) {
      state.unitList.push(val)
    },
    addToBrandList (state, val) {
      state.brandList.push(val)
    },
    updateCategoryList (state, val) {
      const index = state.categoryList.findIndex(
        (item) => item.categoryId === val.categoryId
      )
      state.categoryList.splice(index, 1, val)
    },
    updateIngredientList (state, val) {
      const index = state.ingredientList.findIndex(
        (item) => item.ingredientId === val.ingredientId
      )
      state.ingredientList.splice(index, 1, val)
    },
    /* updateUserDefinedPropertyList (state, val) {
      const index = state.userDefinedProperties.findIndex(
        (item) => item.propertyId === val.propertyId
      )
      state.userDefinedProperties.splice(index, 1, val)
      const headerIndex = state.headers.findIndex(
        (item) => item.id === val.propertyId
      )
      state.headers.splice(headerIndex, 1, {
        id: val.propertyId,
        text: val.propertyName,
        sortable: true,
        value: val.propertyName,
        width: '150px'
      })
    }, */
    updateUnitList (state, val) {
      const index = state.unitList.findIndex(
        (item) => item.unitId === val.unitId
      )
      state.unitList.splice(index, 1, val)
    },
    updateBrandList (state, val) {
      const index = state.brandList.findIndex(
        (item) => item.brandId === val.brandId
      )
      state.brandList.splice(index, 1, val)
    },
    setAllProducts (state, val) {
      state.productList = val
    },
    setAllCategories (state, val) {
      state.categoryList = val
    },
    setAllIngredients (state, val) {
      state.ingredientList = val
    },
    setAllUnits (state, val) {
      state.unitList = val
    },
    setAllStockIns (state, val) {
      state.stockInList = val
    },
    setAllBrands (state, val) {
      state.brandList = val
    },
    deleteCategory (state, categoryId) {
      const index = state.categoryList.findIndex(
        (item) => item.categoryId === categoryId
      )
      state.categoryList.splice(index, 1)
    },
    deleteIngredient (state, ingredientId) {
      const index = state.ingredientList.findIndex(
        (item) => item.ingredientId === ingredientId
      )
      state.ingredientList.splice(index, 1)
    },
    deleteUnit (state, unitId) {
      const index = state.unitList.findIndex((item) => item.unitId === unitId)
      state.unitList.splice(index, 1)
    },
    deleteProduct (state, productId) {
      const index = state.productList.findIndex((item) => item.productId === productId)
      state.productList.splice(index, 1)
    },
    deleteBrand (state, brandId) {
      const index = state.brandList.findIndex((item) => item.brandId === brandId)
      state.brandList.splice(index, 1)
    },
    /* removeUserDefinedPropertyById (state, propertyId) {
      const headerIndex = state.headers.findIndex(
        (header) => header.id === propertyId
      )
      state.headers.splice(headerIndex, 1)
      const propertyIndex = state.userDefinedProperties.findIndex(
        (property) => property.propertyId === propertyId
      )
      state.userDefinedProperties.splice(propertyIndex, 1)
    },
     setAllUserDefinedProperties (state, val) {
      if (Array.isArray(val)) {
        state.userDefinedProperties = val
        val.forEach((userProperty) => {
          const index = state.headers.findIndex(
            (header) => header.text === userProperty.propertyName
          )
          if (index === -1) {
            state.headers.splice(
              state.headers.length - 1,
              0,
              ...val.map((element) => {
                return {
                  id: element.propertyId,
                  text: element.propertyName,
                  sortable: true,
                  value: element.propertyName,
                  width: '150px'
                }
              })
            )
          }
        })
      } else {
        state.userDefinedProperties.push(val)
        state.headers.splice(state.headers.length - 1, 0, {
          id: val.propertyId,
          text: val.propertyName,
          sortable: true,
          value: val.propertyName,
          width: '150px'
        })
      }
    }, */
    setMenuManifest (state, val) {
      state.menuManifest = val
    },
    setDisplayManifest (state, val) {
      state.displayManifest = val
    },
    updateManifest (state, val) {
      state.menuManifest.menu.stocked_out = val
    }
  },
  getters: {
    getIngredientsByCompany: (state, _, rootState) => {
      const recipeIngredients = rootState.recipe.recipes.flatMap(recipe => recipe.ingredients).flatMap(ingredient => ingredient.ingredientId)
      return state.ingredientList.filter(ingredient => recipeIngredients.includes(ingredient.ingredientId))
    },
    isManifestAvailable: (state) => {
      return state.displayManifest
    },
    getPriceByProductId: (state) => (productId) => {
      return state.stockInList.find(stock => stock.productId === productId).price
    },
    getAllProducts: (state, _, rootState) => {
      const recipeIngredients = rootState.recipe.recipes.flatMap(recipe => recipe.ingredients).flatMap(ingredient => ingredient.ingredientId)
      return state.productList.filter(product => recipeIngredients.includes(product.ingredientId))
    },
    getProductsByIngredientId: (state) => (ingredientId) => {
      return state.productList.filter((product) => product.ingredientId === ingredientId)
    },
    getProductById: (state) => (id) => {
      return state.productList.find((product) => product.productId === id)
    },
    getAllCategories: (state) => {
      return state.categoryList
    },
    getAllIngredients: (state) => {
      return state.ingredientList
    },
    getAllUnits: (state) => {
      return state.unitList
    },
    getProductData: (state) => {
      return state.productData
    },
    getAllBrands: (state) => {
      return state.brandList
    },
    getUniqueBrandsForIngredientId: (state) => (ingredientId) => {
      return state.brandList.filter((brand) => state.productList.findIndex(product =>
        product.ingredientId === ingredientId && product.brandId === brand.brandId
      ) === -1)
    },
    getCategoryById: (state) => (id) => {
      return state.categoryList.find((category) => category.categoryId === id)
    },
    getUnitById: (state) => (id) => {
      return state.unitList.find((unit) => unit.unitId === id)
    },
    getIngredientById: (state) => (id) => {
      return state.ingredientList.find(
        (category) => category.ingredientId === id
      )
    },
    getIngredientByName: (state) => (name) => {
      const data = state.ingredientList.find(
        (category) => category.ingredientName.toLowerCase() === name.toLowerCase()
      )
      if (data === undefined) {
        return {}
      } else {
        return data
      }
    },
    getAllIngredientsByCategoryId: (state) => (id) => {
      return state.ingredientList.filter(
        (ingredient) => ingredient.categoryId === id
      )
    },
    getBrandById: (state) => (id) => {
      return state.brandList.find((brand) => brand.brandId === id)
    },
    /*    getUserDefinedPropertyById: (state) => (id) => {
      return state.userDefinedProperties.find(
        (property) => property.propertyId === id
      )
    },
    getAllProductProperties: (state) => {
      return state.productProperties
    },
    getAllUserDefinedProperties: (state) => {
      return state.userDefinedProperties
    },
    getProductPropertiesById: (state) => (productId, propertyId) => {
      return state.productProperties[productId].find(
        (property) => property.propertyId === propertyId
      )
    }, */
    getHeaders: (state) => {
      return state.headers
    },
    getMenuManifest: (state) => {
      return state.menuManifest
    }
  }
}
