<template>
  <v-main class="hero">
    <v-container fluid>
      <v-row
        align="center"
        justify="center"
        style="height: 100vh"
      >
        <v-col cols="6">
          <v-card class="mx-10">
            <div class="card-box">
              <v-container>
                <v-row class="mb-3 d-flex align-center justify-center">
                  <h1 class="font-weight-bold font-36">
                    SM Pantry
                  </h1>
                </v-row>
                <v-row class="my-2 mx-5 d-flex">
                  <span class="font-weight-bold font-20">Sign In</span>
                </v-row>
                <v-row class="my-2 mx-5 mb-7 d-flex">
                  <span class="font-16">Please signin to continue</span>
                </v-row>
                <v-form
                  ref="loginForm"
                  v-model="valid"
                >
                  <v-row class="mx-5">
                    <v-text-field
                      v-model="email"
                      label="Email"
                      outlined
                      required
                      :rules="emailRules"
                      type="email"
                    />
                  </v-row>
                  <v-row class="mx-5">
                    <v-text-field
                      v-model="password"
                      label="Password"
                      outlined
                      required
                      :rules="passwordRules"
                      type="password"
                    />
                  </v-row>
                  <v-row class="d-flex mx-5 mb-5 align-center justify-center">
                    <v-btn
                      block
                      large
                      :loading="formLoader"
                      class="primary py-3"
                      @click="login"
                    >
                      Sign in
                    </v-btn>
                  </v-row>
                </v-form>
              </v-container>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      formLoader: false,
      socialLoader: false,
      valid: true,
      email: '',
      password: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'E-mail must be valid'
      ],
      passwordRules: [(v) => !!v || 'Password is required']
    }
  },
  methods: {
    reset () {
      this.$refs.loginForm.reset()
    },
    async login () {
      this.formLoader = true
      try {
        if (this.$refs.loginForm.validate()) {
          await this.$store.dispatch('auth/signUserIn', {
            email: this.email,
            password: this.password
          })
          this.reset()
        }
        this.formLoader = false
      } catch (e) {
        this.formLoader = false
      }
    }
  }
}
</script>

<style scoped>
.hero {
  background: url("../../assets/images/bg-new.jpg");
  background-size: cover;
  height: 95vh
}
/* "scoped" attribute limit the CSS to this component only */
.social-button {
  width: 55px;
  background: white;
  padding: 10px;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  outline: 0;
  border: 0;
}
.social-button:active {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.social-button img {
  width: 100%;
}
</style>
