<template>
  <v-card class="mx-auto">
    <v-row class="d-flex justify-center my-3">
      <v-col v-if="!isLoading">
        <v-row @click="showDialog">
          <v-col
            cols="4"
            class="d-flex justify-center"
          >
            <v-icon
              size="50"
              class="font-color"
            >
              mdi-alpha-x-box-outline
            </v-icon>
          </v-col>
          <v-col
            cols="8"
            class=""
          >
            <h2 class="font-color">
              {{
                deadStockIngredients.length
              }}
            </h2>
            <p class="text-uppercase mb-1 font-13 font-weight-bold font-color">
              Dead Stock
            </p>
            <p class="mb-1 font-11 font-italic font-weight-bold">
              Non Moving Ingredients..
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-progress-circular
        v-if="isLoading"
        class="my-3"
        indeterminate
        color="green"
      />
    </v-row>
    <IngredientsTableDialog
      title="dead-stock-list"
      :ingredients="deadStockIngredients"
      :dialog="dialog"
      :is-loading="isLoading"
      @closeDialog="closeDialog"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import IngredientsTableDialog from '@/views/Dashboard/components/IngredientsTableDialog'

export default {
  name: 'DeadStock',
  components: { IngredientsTableDialog },
  props: {
    isLoading: Boolean
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      ingredients: 'inventory/getAllIngredients',
      stockedOutIngredients: 'stockOut/stockedOutIngredients',
      getUserInfoByKey: 'auth/getUserInfoByKey'
    }),
    deadStockIngredients () {
      return this.ingredients.filter((ingredient) => {
        return ingredient.totalQty > 0 && !this.stockedOutIngredients.includes(ingredient.ingredientId)
      })
    }
  },
  methods: {
    showDialog () {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.my-3 {
  padding: 1.25rem;
}

.font-color {
  color: darkred;
  cursor: pointer;
}
</style>
