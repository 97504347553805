import moment from 'moment'

const apiMixin = {
  async mounted () {
    await this.$store.dispatch('inventory/fetchAllProducts')
    await this.$store.dispatch('inventory/fetchAllBrands')
    await this.$store.dispatch('inventory/fetchAllStockIns')
    await this.$store.dispatch('stockOut/fetchStockedOutIngredientsByDate', parseInt(this.getUserInfoByKey('dead_stock_days')))
    await this.$store.dispatch('recipe/fetchAllRecipes')
    await this.$store.dispatch('inventory/fetchAllIngredients')
    const userInfo = this.$store.getters['auth/getUserInfoByKey']
    const format = userInfo('date_format')
    const dateRange = [
      moment().subtract(7, 'days').format(format),
      moment().format(format)
    ]
    await this.$store.dispatch('reports/getHighlyPurchasedIngredients', { dateRange: dateRange, format: format })
    const expirationRange = [
      moment().format(format),
      moment().add(7, 'days').format(format)
    ]
    await this.$store.dispatch('reports/getIngredientsOfExpiration', { dateRange: expirationRange, format: format })
  }
}

export default apiMixin
