<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      style="z-index: 20001"
      transition="dialog-bottom-transition"
      @keydown.esc="dialog = false"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          dense
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Bulk Stock In
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          <v-btn
            dark
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span class="dialog-header">Bulk Stock In</span>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-container>
          <v-stepper v-model="stepper">
            <v-stepper-header>
              <v-stepper-step
                :complete="stepper > 1"
                step="1"
              >
                Upload CSV
              </v-stepper-step>

              <v-divider />

              <v-stepper-step
                :complete="stepper > 2"
                step="2"
              >
                Preview
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card>
                  <v-row
                    align="center"
                    justify="center"
                  >
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-row no-gutters>
                        <p>
                          To download sample file goto <b>Stock Estimate By Menu or Recipe</b> and download CSV file <br><br>
                          * Only the ingredients present in the csv will be parsed. To add new ingredients please add a
                          new ingredient in Recipe section.
                        </p>
                      </v-row>
                      <v-row no-gutters>
                        <v-btn
                          class="primary mx-2 my-2"
                          @click="linkToSampleFile"
                        >
                          <span>Link to Sample File</span>
                          <v-icon class="ml-1">
                            mdi-cloud-download
                          </v-icon>
                        </v-btn>
                      </v-row>
                      <v-divider />
                      Please select the CSV / Excel file
                    </v-col>
                    <v-col
                      cols="12"
                      justify="center"
                      md="6"
                      offset="3"
                    >
                      <v-row>
                        <v-file-input
                          v-model="file"
                          :full-width="false"
                          :show-size="1000"
                          accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          color="primary"
                          counter

                          label="File input"
                          outlined
                          placeholder="Select your file"
                          prepend-icon=""
                          suffix="Browse"
                        >
                          <template #selection="{ text }">
                            <v-chip
                              color="primary"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                        <v-btn
                          :disabled="!file || csvLoader"
                          :loading="csvLoader"
                          class="primary mx-2"
                          @click="parseFile"
                        >
                          Upload File
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col />
                  </v-row>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card>
                  <v-row>
                    <v-col cols="12">
                      <span>List of Parsed Products to Stock In</span>
                    </v-col>
                    <v-col cols="12">
                      <v-form
                        ref="stockInForm"
                        v-model="validityStatus"
                      >
                        <v-data-table
                          :headers="headers"
                          :items="parsedData"
                          hide-default-footer
                          :items-per-page="1000"
                          :search="search"
                          class="px-3 table-striped table-bordered dt-responsive"
                        >
                          <template #top>
                            <v-row class="d-flex align-start justify-start mt-2">
                              <v-col
                                class="d-flex justify-center align-center"
                                cols="7"
                              >
                                <v-text-field
                                  v-model="search"
                                  clear-icon="mdi-close-circle-outline"
                                  clearable
                                  label="Search"
                                  prepend-inner-icon="mdi-magnify"
                                  solo
                                />
                              </v-col>
                            </v-row>
                          </template>
                          <template #item.ingredient="{ item }">
                            <v-autocomplete
                              v-if="
                                item.reason.includes(INGREDIENT_ERROR['EMPTY']) ||
                                  item.reason.includes(
                                    INGREDIENT_ERROR['NOT_FOUND']
                                  )
                              "
                              v-model="item.ingredient"
                              :items="ingredientList"
                              :rules="rules.required"
                              class="ingredient-text-field"
                              dense
                              item-text="ingredientName"
                              item-value="ingredientName"
                              outlined
                              @blur="checkIngredient(item)"
                              @change="checkIngredient(item)"
                            />
                            <span v-else>{{ item.ingredient }}</span>
                          </template>
                          <template #item.alias="{item}">
                            {{ getAlias(item) }}
                          </template>
                          <template #item.unit="{ item }">
                            <v-select
                              v-if="
                                item.reason.includes(UNIT_ERROR['EMPTY']) ||
                                  item.reason.includes(
                                    UNIT_ERROR['NOT_FOUND']
                                  )
                              "
                              v-model="item.unit"
                              :items="unitList(item.ingredientId)"
                              :rules="rules.required"
                              class="ingredient-text-field"
                              dense
                              outlined
                              @change="checkUnit(item)"
                            />
                            <span v-else>{{ item.unit }}</span>
                          </template>
                          <template #item.product_name="{ item }">
                            <v-autocomplete
                              v-if="
                                item.reason.includes(PRODUCT_ERROR['EMPTY']) ||
                                  item.reason.includes(
                                    PRODUCT_ERROR['NOT_FOUND']
                                  )
                              "
                              v-model="item.product_name"
                              :disabled="!item.ingredientId"
                              dense
                              :items="getProductsByIngredientId(item.ingredientId)"
                              item-text="productName"
                              item-value="productName"
                              label="Product*"

                              :rules="rules.required"
                              class="ingredient-text-field"
                              outlined
                              return-object
                              required
                              @change="addProduct($event,item)"
                            >
                              <template #item="{ item: product }">
                                {{ product.productName }}
                                <v-spacer />
                                <v-list-item-action @click.stop>
                                  Brand : {{ getBrandById(product.brandId).brandName }}
                                </v-list-item-action>
                              </template>
                              <template #append-item>
                                <v-divider class="mb-2" />
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <ProductDialog class="m-1" />
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                            <span v-else>{{ item.product_name }}</span>
                          </template>
                          <template #item.price="{ item }">
                            {{ formatNumber(item.price) }}
                          </template>
                          <template #item.reason="{ item }">
                            <ul
                              v-for="reason in item.reason"
                            >
                              <li class="reason-text">
                                {{ reason }}
                              </li>
                            </ul>
                          </template>
                          <template #item.type="{ item }">
                            <v-icon
                              v-if="item.reason.length === 0"
                              color="green darken-2"
                            >
                              mdi-check-circle
                            </v-icon>
                            <v-icon
                              v-else
                              color="red darken-2"
                            >
                              mdi-alert-circle
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-form>
                    </v-col>
                  </v-row>
                  <v-row class="py-5 mx-2">
                    <v-btn
                      :disabled="!validityStatus || !checkForValidIngredients || stockInLoader"
                      :loading="stockInLoader"
                      class="mx-2"
                      color="primary"
                      @click="stockIn"
                    >
                      Stock In
                    </v-btn>
                    <v-btn
                      class="mx-2"
                      text
                      @click="stepper = 1"
                    >
                      Go back
                    </v-btn>
                  </v-row>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-container>
      </v-card>
      <v-snackbar
        v-model="snackbar"
        :color="
          snackType === 'success'
            ? 'green'
            : snackType === 'error'
              ? 'red'
              : 'yellow'
        "
        :timeout="1500"
      >
        {{ snackText }}
      </v-snackbar>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Papa from 'papaparse'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import ProductDialog from '../../Inventory/components/ProductDialog'
import configureMeasurements, { mass, volume } from 'convert-units'
import { volumeToML, weightToGram } from '@/utilities/convertToGramsUtil'
import * as XLSX from 'xlsx'
import { getFileName } from '@/utilities/fileNameUtil'
import { numberFormatter } from '@/utilities/numberFormatter'

const JSONToCSV = require('json2csv').parse
const convert = configureMeasurements({
  volume,
  mass
})
export default {
  name: 'BulkImportDialog',
  components: { ProductDialog },
  props: {
    open: Boolean
  },
  data () {
    return {
      snackbar: false,
      snackText: '',
      search: '',
      csvLoader: false,
      stockInLoader: false,
      rules: {
        required: [(v) => !!v || 'Required']
      },

      stepper: 1,
      validityStatus: false,
      file: null,
      snackType: '',
      nameRules: [(v) => !!v || 'Name is required'],
      dialog: false,
      parsedData: [],
      DATE_ERROR: {
        INVALID: 'Date is not as per correct format of' + this.dateFormat,
        DATE_PREVIOUS: 'Expiry Date cannot be earlier than current date',
        DATE_AFTER: 'Expiry Date cannot be later than 1 year from current date'
      },
      INGREDIENT_ERROR: {
        NOT_FOUND: 'Ingredient not found in database',
        EMPTY: 'Ingredient is empty'
      },
      PRODUCT_ERROR: {
        EMPTY: 'Product name is empty',
        NOT_FOUND: 'Product name with given brand not found in database'
      },
      BRAND_ERROR: {
        EMPTY: 'Brand name is empty',
        NOT_FOUND: 'Brand name not found in database'
      },
      UNIT_ERROR: {
        EMPTY: 'Unit is empty',
        NOT_FOUND: 'Unit not found in database'
      },
      PRICE_ERROR: {

        NEGATIVE: 'Price is invalid',
        NOT_FOUND: 'Unit not found in database'

      },
      massUnits: ['kg', 'g', 'oz', 't'],
      volumeUnits: ['l', 'ml']
    }
  },
  computed: {
    ...mapGetters({
      ingredientList: 'inventory/getIngredientsByCompany',
      productList: 'inventory/getAllProducts',
      getProductsByIngredientId: 'inventory/getProductsByIngredientId',
      getIngredientById: 'inventory/getIngredientById',
      getUnitById: 'inventory/getUnitById',
      getBrandById: 'inventory/getBrandById',
      brandList: 'inventory/getAllBrands',
      getUserInfoByKey: 'auth/getUserInfoByKey'
    }),
    dateFormat () {
      return this.getUserInfoByKey('date_format')
    },
    checkForValidIngredients () {
      return this.parsedData.filter(data => data.reason.length > 0).length === 0
    },
    headers () {
      return [
        {
          text: 'Ingredient',
          align: 'start',
          sortable: true,
          value: 'ingredient'
        },
        {
          text: 'Local Alias',
          sortable: true,
          value: 'local_alias'
        },
        {
          text: 'Product Name',
          sortable: true,
          value: 'product_name'
        },
        {
          text: 'Brand Name',
          sortable: true,
          value: 'brand_name'
        },
        {
          text: 'Expiry Date',
          sortable: true,
          value: 'expiry_date'
        },
        {
          text: 'Qty to Stock-in',
          sortable: true,
          value: 'qty'
        },
        {
          text: 'Minimum Qty',
          sortable: true,
          value: 'minQty'
        },
        {
          text: 'Unit',
          sortable: false,
          value: 'unit'
        },
        {
          text: `Price (${this.currency})`,
          sortable: false,
          value: 'price'
        },
        {
          text: 'Valid',
          value: 'type'
        },
        {
          text: 'Reason',
          sortable: false,
          value: 'reason'
        }
      ]
    },
    currency () {
      return this.getUserInfoByKey('currency')
    }
  },
  mounted () {
  },
  created () {
  },
  destroyed () {
  },

  methods: {
    getAlias (item) {
      if (item.alias && item.alias.length > 0) {
        return item.alias.join(', ')
      } else {
        return ''
      }
    },
    getItemsPerPage () {
      return 1000
    },
    convertedValue (ingredientId, unit, qty) {
      const ingredient = this.getIngredientById(ingredientId)
      const unitLowerCase = unit.toLowerCase().trim()
      const unitInUOM = ingredient.uom.find(u => u.unit.toLowerCase() === unitLowerCase)
      if (unit.toLowerCase() === 'count') {
        return parseInt(qty)
      } else if (unit.toLowerCase() === 'dozen') {
        return parseInt(qty * 12)
      } else if (unitInUOM !== undefined) {
        return qty * parseFloat(unitInUOM.weight).toFixed(2)
      } else {
        try {
          if (ingredient.measure_type === 'V') {
            const unitType = convert.list('volume').find(u => u.abbr.toLowerCase() === unitLowerCase || u.singular.toLowerCase() === unitLowerCase || u.plural.toLowerCase() === unitLowerCase).abbr
            return volumeToML(unitType, qty)
          } else {
            const unitType = convert.list('mass').find(u => u.abbr.toLowerCase() === unitLowerCase || u.singular.toLowerCase() === unitLowerCase || u.plural.toLowerCase() === unitLowerCase).abbr
            return weightToGram(unitType, qty)
          }
        } catch (e) {
          return qty
        }
      }
    },
    unitList (ingredientId) {
      if (ingredientId) {
        const ingredient = this.ingredientList.find(
          (ingredient) => ingredient.ingredientId === ingredientId
        )
        if (ingredient.measure_type === 'W') {
          return convert.list('mass').filter((element) => this.massUnits.includes(element.abbr)).map(unit => unit.plural)
        } else {
          return convert.list('volume').filter((element) => this.volumeUnits.includes(element.abbr)).map(unit => unit.plural)
        }
      }
    },
    getAvailableQty (id) {
      if (
        this.getIngredientById(id) && Object.keys(this.getIngredientById(id)).length > 0
      ) {
        return 'Qty in stock : ' + this.getIngredientById(id).totalQty || 0
      } else {
        return 'Qty in stock : 0'
      }
    },
    showSnackNotification (text, type) {
      this.snackText = text
      this.snackType = type
      this.snackbar = true
    },
    linkToSampleFile () {
      window.open('https://docs.google.com/spreadsheets/d/1UK3AR2q906i_9kh4-fWU1ydM86k5eLY1bTOTqjwNPn8/edit#gid=249858619', '_blank')
    },
    async generateCsv () {
      const vm = this
      const head = JSON.parse(JSON.stringify(vm.headers.map((header) => header.value)))
      head.pop()
      head.pop()
      const data = []
      this.ingredientList.forEach((ingredient) => {
        let rowData = {}
        const products = vm.getProductsByIngredientId(ingredient.ingredientId)
        if (products.length > 0) {
          products.forEach(product => {
            rowData.ingredient = ingredient.ingredientName
            if (ingredient.alias && ingredient.alias.length > 0) {
              rowData.local_alias = ingredient.alias.join(', ')
            } else {
              rowData.local_alias = ingredient.alias
            }
            rowData.unit = vm.getUnit(ingredient)
            rowData.qty = 100
            rowData.expiry_date = moment().add(7, 'days').format(this.getUserInfoByKey('date_format'))
            rowData.product_name = product ? product.productName : ''
            rowData.brand_name = product ? vm.getBrandById(product.brandId).brandName : ''
            rowData.price = 0
            rowData.minQty = ingredient.minQty
            data.push(rowData)
            rowData = {}
          })
        } else {
          rowData.ingredient = ingredient.ingredientName
          if (ingredient.alias && ingredient.alias.length > 0) {
            rowData.local_alias = ingredient.alias.join(', ')
          } else {
            rowData.local_alias = ingredient.alias
          }
          rowData.unit = vm.getUnit(ingredient)
          rowData.qty = 100
          rowData.price = 0
          rowData.expiry_date = moment().add(7, 'days').format(this.getUserInfoByKey('date_format'))
          rowData.product_name = '-'
          rowData.brand_name = '-'
          rowData.minQty = ingredient.minQty
          data.push(rowData)
          rowData = {}
        }
      })
      const csv = JSONToCSV(data, { fields: head })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = getFileName('stockin-sample', 'csv')
      anchor.click()
    },
    generateExcel () {
      const vm = this
      const head = JSON.parse(JSON.stringify(vm.headers.map((header) => header.value)))
      head.pop()
      head.pop()
      const data = []
      this.ingredientList.forEach((ingredient) => {
        let rowData = {}
        const products = vm.getProductsByIngredientId(ingredient.ingredientId)
        if (products.length > 0) {
          products.forEach(product => {
            rowData.ingredient = ingredient.ingredientName
            if (ingredient.alias && ingredient.alias.length > 0) {
              rowData.local_alias = ingredient.alias.join(', ')
            } else {
              rowData.local_alias = ingredient.alias
            }
            rowData.unit = vm.getUnit(ingredient)
            rowData.qty = 100
            rowData.expiry_date = moment().add(7, 'days').format(this.getUserInfoByKey('date_format'))
            rowData.product_name = product ? product.productName : ''
            rowData.brand_name = product ? vm.getBrandById(product.brandId).brandName : ''
            rowData.price = 0
            rowData.minQty = ingredient.minQty
            data.push(rowData)
            rowData = {}
          })
        } else {
          rowData.ingredient = ingredient.ingredientName
          if (ingredient.alias && ingredient.alias.length > 0) {
            rowData.local_alias = ingredient.alias.join(', ')
          } else {
            rowData.local_alias = ingredient.alias
          }
          rowData.unit = vm.getUnit(ingredient)
          rowData.qty = 100
          rowData.price = 0
          rowData.expiry_date = moment().add(7, 'days').format(this.getUserInfoByKey('date_format'))
          rowData.product_name = '-'
          rowData.brand_name = '-'
          rowData.minQty = ingredient.minQty
          data.push(rowData)
          rowData = {}
        }
      })
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      const filename = `${this.getUserInfoByKey('company_name_slug')}_stockin_sample_${moment().format(this.getUserInfoByKey('date_format'))}.xlsx`
      XLSX.writeFile(wb, filename)
    },
    getUnit (ingredient) {
      if (ingredient.measure_type === 'W') {
        return 'gram'
      } else if (ingredient.measure_type === 'V') {
        return 'ml'
      } else {
        return 'Count'
      }
    },
    parseFile () {
      if (this.file.type === 'text/csv') {
        this.parseCSV()
      } else {
        this.parseExcel()
      }
    },
    parseExcel () {
      const vm = this
      const reader = new FileReader()
      reader.onload = function (e) {
        this.csvLoader = true
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'binary', cellDates: true })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const finalJsonData = XLSX.utils.sheet_to_json(worksheet)
        finalJsonData.forEach(ingredient => {
          ingredient.qty = ingredient.qty || ingredient['Estimated Total Qty'] || ingredient.stockin_qty
        })
        vm.parsedData = finalJsonData.filter((ingredientData) => ingredientData.qty > 0)
        for (const ingredientData of vm.parsedData) {
          ingredientData.reason = []
          vm.checkIngredient(ingredientData)
          vm.checkProductName(ingredientData)
          vm.checkUnit(ingredientData)
          vm.checkDate(ingredientData)
        }
        this.csvLoader = false
        vm.checkHeaders(vm.parsedData[0])
      }
      reader.readAsBinaryString(this.file)
    },
    parseCSV () {
      const vm = this
      this.csvLoader = true
      Papa.parse(this.file, {
        worker: true,
        header: true,
        dynamicTyping: true,
        complete: function (results) {
          results.data.forEach(ingredient => {
            ingredient.qty = ingredient.qty || ingredient['Estimated Total Qty'] || ingredient.stockin_qty
          })
          vm.parsedData = results.data.filter((ingredientData) => ingredientData.qty > 0)
          for (const ingredientData of vm.parsedData) {
            ingredientData.reason = []
            // console.log(typeof (ingredientData.price))
            // if (+ingredientData.price < 0) {
            //   alert('Negativ price')
            //   // return
            // } else {
            vm.checkIngredient(ingredientData)
            vm.checkProductName(ingredientData)
            vm.checkUnit(ingredientData)
            vm.checkDate(ingredientData)
            vm.checkPrice(ingredientData)
            // }
          }
          vm.checkHeaders(vm.parsedData[0])
        }
      })
      this.csvLoader = false
    },
    checkHeaders (data) {
      const dataHeaders = Object.keys(data)
      const headers = JSON.parse(JSON.stringify(this.headers.map((header) => header.value)))
      headers.pop()
      headers.pop()
      const isTwoArraysEqual = headers.every(val => dataHeaders.includes(val))
      if (isTwoArraysEqual) {
        this.stepper = 2
      } else {
        this.showSnackNotification('Headers are not as per the given format. Please check and try again', 'error')
      }
    },
    addProduct (event, ingredientData) {
      ingredientData.productId = event.productId
      ingredientData.product_name = event.productName
      ingredientData.brand_name = this.getBrandById(event.brandId).brandName
      const index1 = ingredientData.reason.findIndex(
        (err) => err === this.PRODUCT_ERROR.NOT_FOUND
      )
      const index2 = ingredientData.reason.findIndex(
        (err) => err === this.PRODUCT_ERROR.EMPTY
      )
      if (index1 !== -1) {
        ingredientData.reason.splice(index1, 1)
      }
      if (index2 !== -1) {
        ingredientData.reason.splice(index2, 1)
      }
    },
    checkProductName (ingredientData) {
      if (!ingredientData.product_name) {
        ingredientData.product_name = ''
        ingredientData.type = 'Invalid'
        if (ingredientData.reason.length === 0) {
          ingredientData.reason.push(this.PRODUCT_ERROR.EMPTY)
        }
      } else {
        const brand = this.brandList.find((brand) => brand.brandName.toLowerCase() === ingredientData.brand_name.toLowerCase())
        let index = -1
        if (brand) {
          index = this.productList.findIndex((product) => product.productName.toLowerCase() === ingredientData.product_name.toLowerCase() && product.brandId === brand.brandId)
        }
        if (index === -1) {
          ingredientData.type = 'Invalid'
          ingredientData.product_name = ''
          if (ingredientData.reason.length === 0) {
            ingredientData.reason.push(this.PRODUCT_ERROR.NOT_FOUND)
          }
        } else {
          ingredientData.productId = this.productList[index].productId
          const index1 = ingredientData.reason.findIndex(
            (err) => err === this.PRODUCT_ERROR.NOT_FOUND
          )
          const index2 = ingredientData.reason.findIndex(
            (err) => err === this.PRODUCT_ERROR.EMPTY
          )
          if (index1 !== -1) {
            ingredientData.reason.splice(index1, 1)
          }
          if (index2 !== -1) {
            ingredientData.reason.splice(index2, 1)
          }
        }
      }
    },
    checkIngredient (ingredientData) {
      if (!ingredientData.ingredient) {
        ingredientData.ingredient = ''
        ingredientData.type = 'Invalid'
        if (ingredientData.reason.length === 0) {
          ingredientData.reason.push(this.INGREDIENT_ERROR.EMPTY)
        }
      } else {
        const index = this.ingredientList.findIndex(
          (ingredient) =>
            ingredient.ingredientName.toLowerCase() === ingredientData.ingredient.toLowerCase()
        )
        if (index === -1) {
          ingredientData.type = 'Invalid'
          ingredientData.ingredient = ''
          if (ingredientData.reason.length === 0) {
            ingredientData.reason.push(this.INGREDIENT_ERROR.NOT_FOUND)
          }
        } else {
          ingredientData.ingredientId = this.ingredientList[index].ingredientId
          const index1 = ingredientData.reason.findIndex(
            (err) => err === this.INGREDIENT_ERROR.NOT_FOUND
          )
          const index2 = ingredientData.reason.findIndex(
            (err) => err === this.INGREDIENT_ERROR.EMPTY
          )
          if (index1 !== -1) {
            ingredientData.reason.splice(index1, 1)
          }
          if (index2 !== -1) {
            ingredientData.reason.splice(index2, 1)
          }
        }
      }
    },
    checkDate (ingredientData) {
      const index1 = ingredientData.reason.findIndex(
        (err) => err === this.DATE_ERROR.DATE_PREVIOUS
      )
      const index2 = ingredientData.reason.findIndex(
        (err) => err === this.DATE_ERROR.DATE_AFTER
      )
      if (index1 !== -1) {
        ingredientData.reason.splice(index1, 1)
      }
      if (index2 !== -1) {
        ingredientData.reason.splice(index2, 1)
      }
      if (ingredientData.expiry_date) {
        ingredientData.expiry_date = moment(ingredientData.expiry_date,
          ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'M-D-YYYY', 'D-M-YYYY', 'M-DD-YYYY', 'DD-M-YYYY'
          ])
          .format(this.dateFormat)

        const date = moment(ingredientData.expiry_date,
          ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'M-D-YYYY', 'D-M-YYYY', 'M-DD-YYYY', 'DD-M-YYYY'
          ])
        if (date.isBefore(moment().format())) {
          ingredientData.type = 'Invalid'
          ingredientData.expiry_date = ''
          ingredientData.reason.push(this.DATE_ERROR.DATE_PREVIOUS)
        } else if (date.isAfter(moment().add(1, 'year').format())) {
          ingredientData.type = 'Invalid'
          ingredientData.expiry_date = ''
          ingredientData.reason.push(this.DATE_ERROR.DATE_AFTER)
        }
      } else {
        ingredientData.expiry_date = ''
      }
    },
    checkUnit (ingredientData) {
      if (!ingredientData.unit) {
        ingredientData.unit = ''
        ingredientData.type = 'Invalid'
        if (ingredientData.reason.length === 0) {
          ingredientData.reason.push(this.UNIT_ERROR.EMPTY)
        }
      } else {
        const index = this.ingredientList.findIndex(
          (ingredient) => ingredient.ingredientName.toLowerCase() === ingredientData.ingredient.toLowerCase()
        )
        if (index !== -1) {
          const measureType = this.ingredientList[index].measure_type
          let availableUnits = []
          if (measureType === 'C') {
            return
          }
          if (measureType === 'W') {
            availableUnits = convert.list('mass')
          } else if (measureType === 'V') {
            availableUnits = convert.list('volume')
            availableUnits.push({
              abbr: 'l',
              measure: 'volume',
              plural: 'Liters',
              singular: 'Liter',
              system: 'metric'
            })
          }
          const filterList = availableUnits.filter(unit => unit.abbr.toLowerCase() === ingredientData.unit.toLowerCase() ||
            unit.singular.toLowerCase() === ingredientData.unit.toLowerCase() ||
            unit.plural.toLowerCase() === ingredientData.unit.toLowerCase())
          if (filterList.length === 0) {
            const dbIngredient = this.getIngredientById(ingredientData.ingredientId)
            const uomData = dbIngredient.uom.filter(uom => uom.unit.toString().toLowerCase() === ingredientData.unit.toLowerCase())
            if (uomData.length === 0) {
              ingredientData.reason.push(this.UNIT_ERROR.NOT_FOUND)
            }
          } else {
            ingredientData.ingredientId = this.ingredientList[index].ingredientId
            const index1 = ingredientData.reason.findIndex(
              (err) => err === this.UNIT_ERROR.NOT_FOUND
            )
            const index2 = ingredientData.reason.findIndex(
              (err) => err === this.UNIT_ERROR.EMPTY
            )
            if (index1 !== -1) {
              ingredientData.reason.splice(index1, 1)
            }
            if (index2 !== -1) {
              ingredientData.reason.splice(index2, 1)
            }
          }
        } else {
          ingredientData.unit = ''
          ingredientData.reason.push(this.UNIT_ERROR.EMPTY)
        }
      }
    },
    checkPrice (ingredientData) {
      if (ingredientData.price < 0) {
        // ingredientData.price = 0 // set to 0 or any default value
        ingredientData.type = 'Invalid'
        if (ingredientData.reason.length === 0) {
          ingredientData.reason.push(this.PRICE_ERROR.NEGATIVE)
        } else {

        }
      }
    },

    async stockIn () {
      this.stockInLoader = true
      let stockInData = []
      if (this.$refs.stockInForm.validate() && this.checkForValidIngredients) {
        for (const data of this.parsedData) {
          const stockInItem = {}
          stockInItem.id = uuidv4()
          stockInItem.type = 'STOCK_IN'
          stockInItem.rawQty = data.qty
          stockInItem.qty = this.convertedValue(data.ingredientId, data.unit, data.qty)
          stockInItem.minQty = data.minQty
          stockInItem.minQtyUnit = data.unit
          stockInItem.ingredientId = data.ingredientId
          stockInItem.expiryDate = this.checkAndAssignDate(data.expiry_date)
          stockInItem.productId = data.productId
          stockInItem.price = parseFloat(data.price)
          // console.log(stockInItem.price)
          // console.log(stockInItem.price.toFixed(2))
          stockInItem.unit = data.unit
          stockInData.push(stockInItem)
        }
        const response = await this.$store.dispatch('stockIn/addStockInItem', stockInData)
        if (response) {
          this.parsedData = []
          stockInData = []
          this.showSnackNotification('Ingredients successfully stocked in.', 'success')
          window.location.replace('/dashboard')
        } else {
          this.showSnackNotification('Error while stocking in ingredients.', 'error')
        }
        this.stockInLoader = false
      }
    },
    checkAndAssignDate (expiryDate) {
      if (expiryDate) {
        return expiryDate
      } else {
        return ''
      }
    },
    formatNumber (price) {
      return numberFormatter(price)
    }
  }
}
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0 !important;
}

.dialog-header {
  font-size: 1.25rem;
  font-weight: 500;
}

.center-items {
  align-items: center;
  justify-content: center;
}

.qty-text-field {
  padding-top: 13px;
  max-width: 90px;
}

.ingredient-text-field {
  padding-top: 13px;
  max-width: 150px;
}

.reason-text {
  color: red;
}
</style>
