<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    style="z-index: 20001;"
    transition="dialog-bottom-transition"
    @keydown.esc="closeDialog"
  >
    <template #activator="{ on, attrs }">
      <div>
        <v-icon
          small
          v-bind="attrs"
          v-on="on"
        >
          mdi-eye
        </v-icon>
      </div>
    </template>
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-btn
          dark
          icon
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="dialog-header">Menu Manifest</span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items v-if="isManifestAvailable">
          <v-btn
            v-if="menuManifest.menu.stocked_out === '0'"
            :loading="saveStockLoader"
            :disabled="!canStockout"
            color="white"
            text
            @click="stockOut"
          >
            Stock Out
          </v-btn>
          <v-btn
            text
            :loading="formLoader"
            color="white"
            @click="generatePDF"
          >
            Download PDF
          </v-btn>
          <v-btn
            text
            :loading="formLoader"
            color="white"
            @click="generateCsv"
          >
            Download CSV
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-row
        v-if="isManifestAvailable"
        class="pa-2"
      >
        <v-col
          cols="3"
        >
          <v-card-text class="font-weight-bold font-18 px-5">
            <v-row>Menu Name: {{ menuManifest.menu.menu_name }}</v-row>
          </v-card-text>
          <v-card-text>
            <span :class="menuManifest.menu.response_received === 1 ?'response-success':'response-error'">● </span>
            {{ menuManifest.menu.response_received === 1 ? 'App Response Received' : 'App Response Not Received' }}<br>
            <span :class="menuManifest.menu.stocked_out === '1'?'response-success':'response-error'">● </span>
            {{ menuManifest.menu.stocked_out === '1' ? 'Stockout Completed' : 'Stockout Incomplete' }}
          </v-card-text>
          <v-card-text>
            <h5>
              Statistics
            </h5><br>
            <span><strong>No of Adults </strong>: {{ menuManifest.menu.adult_count }}</span> <br><br>
            <span><strong>No of Children </strong> : {{ menuManifest.menu.child_count }}</span>
            <v-divider />
            <h5>Recipes</h5><br>
            <ul v-for="recipe in menuManifest.recipes">
              <li>
                {{ recipe }}
              </li>
            </ul>
          </v-card-text>
        </v-col>

        <v-divider vertical />

        <v-col cols="9">
          <v-card-text class="font-weight-bold font-18">
            Ingredients
          </v-card-text>
          <v-data-table
            :headers="header"
            calculate-widths
            :items="menuManifest.ingredients"
            :search="search"
            :items-per-page="1000"
            multi-sort
            class="px-3 table-striped table-bordered dt-responsive"
          >
            <template #top>
              <v-row class="mt-2 d-flex align-start justify-start">
                <v-col
                  class="d-flex justify-end align-center"
                  cols="7"
                >
                  <v-text-field
                    v-model="search"
                    clear-icon="mdi-close-circle-outline"
                    clearable
                    dense
                    label="Search Ingredients"
                    prepend-inner-icon="mdi-magnify"
                    solo
                  />
                </v-col>
              </v-row>
            </template>
            <template #item.qty="{item}">
              {{ getTotalQty(item.measure_type, item.qty) }}
            </template>
            <template #item.alias="{item}">
              {{ removeDuplicateAlias(item.alias) }}
            </template>
            <template #item.unit="{item}">
              {{ getUnit(item.measure_type, item.qty) }}
            </template>
            <template #item.status="{item}">
              {{ checkIngredients(item) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row
        v-else-if="isLoading"
        class="pa-2 my-auto"
        style="height: 500px;"
        justify="center"
        align="center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </v-row>
      <v-row
        v-else
        class="pa-2 my-auto"
        style="height: 500px;"
        justify="center"
        align="center"
      >
        <span class="text-h5"><em>Manifest not generated!</em></span>
      </v-row>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :color="
        snackType === 'success'
          ? 'green'
          : snackType === 'error'
            ? 'red'
            : 'yellow'
      "
      :timeout="1500"
    >
      {{ snackText }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { parse as JSONToCSV } from 'json2csv'
import moment from 'moment'
import JsPdf from 'jspdf'
import { gramToKilogram, mlToLitre } from '@/utilities/convertToGramsUtil'
import roleMixin from '../utilities/mixins/roleMixin'
import { getFileName } from '@/utilities/fileNameUtil'

export default {
  name: 'MenuManifest',
  components: {},
  mixins: [roleMixin],
  props: {
    value: {
      type: String,
      default: new Date().toString()
    }
  },
  data: () => ({
    snackbar: false,
    snackText: '',
    snackType: '',
    search: '',
    saveStockLoader: false,
    header: [
      {
        id: uuidv4(),
        text: 'Ingredient Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        id: uuidv4(),
        text: 'Local Alias',
        align: 'start',
        sortable: true,
        value: 'alias'
      },
      {
        id: uuidv4(),
        text: 'Qty',
        sortable: true,
        value: 'qty'
      },
      {
        id: uuidv4(),
        text: 'Unit',
        sortable: true,
        value: 'unit'
      },
      {
        id: uuidv4(),
        text: 'Status',
        sortable: true,
        value: 'status'
      }

    ],
    valid: false,
    items: [],
    dialog: false,
    formLoader: false,
    isLoading: false
  }),
  computed: {
    ...mapGetters({
      getUserInfoByKey: 'auth/getUserInfoByKey',
      allIngredients: 'inventory/getAllIngredients',
      getIngredientById: 'inventory/getIngredientById',
      menuManifest: 'inventory/getMenuManifest',
      isManifestAvailable: 'inventory/isManifestAvailable'
    }),
    canStockout () {
      return this.valid && this.isAccessible
    }
  },
  watch: {
    value () {
      this.$emit('input', this.value)
    },
    dialog: {
      handler (newVal, oldVal) {
        if (newVal === true) {
          this.fetchData()
        }
      },
      deep: true
    }
  },
  mounted () {
    this.fetchData()
  },
  updated () {
    this.validForStockOut()
  },
  methods: {
    async fetchData () {
      try {
        this.isLoading = true
        await this.$store.dispatch('inventory/fetchMenuManifest', {
          date: this.value
        })
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    },
    generatePDF () {
      const columns = [
        { title: 'Ingredient', dataKey: 'ingredientName' },
        { title: 'Alias', dataKey: 'alias' },
        { title: 'Total Quantity', dataKey: 'totalQty' },
        { title: 'Available in Pantry', dataKey: 'status' }
      ]
      const doc = new JsPdf({
        theme: 'grid',
        orientation: 'portrait',
        unit: 'in',
        format: 'letter'
      })
      // text is placed using x, y coordinates
      doc.setTextColor('#cb8037')
      doc.setFont('helvetica', 'bold').setFontSize(26).text(`${this.getUserInfoByKey('company_name')} Menu Manifest Report`, 0.5, 1.0)
      doc.setLineWidth(0.03).line(0.5, 1.3, 8.0, 1.3)
      // create a line under heading
      doc.setFont('helvetica', 'bold').setFontSize(20).text(`Date:  ${moment().format(this.getUserInfoByKey('date_format'))}`, 0.5, 2.0)
      // create a line under heading
      doc.setLineWidth(0.01).line(0.5, 2.2, 8.0, 2.2)
      // Using autoTable plugin
      const data = this.menuManifest.ingredients.map((element) => {
        const ingredientName = element.name
        const alias = Array.from(new Set(element.alias.split(','))).toString()
        const totalQty = `${this.getTotalQty(element.measure_type, element.qty)}   (${this.getUnit(element.measure_type, element.qty)})`
        const status = this.checkIngredients(element)
        return { ingredientName: ingredientName, totalQty: totalQty, status: status, alias: alias }
      })
      doc.autoTable({
        headStyles: { fillColor: '#cb8037' },
        columns,
        body: data,
        margin: { left: 0.5, top: 2.5, bottom: 2 },
        didDrawPage: function (data) {
          data.settings.margin.top = 1
        }
      })
      this.addFooters(doc)
      // Creating footer and saving file
      doc.save(getFileName('menu-manifest-report', 'pdf'))
    },
    addFooters (doc) {
      const pageCount = doc.internal.getNumberOfPages()

      doc.setFont('helvetica')
      doc.setFontSize(16)
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.setLineWidth(0.1).line(0.5, doc.internal.pageSize.height - 1, 8.0, doc.internal.pageSize.height - 1)
        doc.text(
          `\u00A9 ${moment().year()} — SM Pantry`,
          0.5,
          doc.internal.pageSize.height - 0.5
        )
        doc.text(
          `Page ${i}`,
          7.0,
          doc.internal.pageSize.height - 0.5, 'left'
        )
      }
    },
    async generateCsv () {
      const head = JSON.parse(JSON.stringify(this.header.map((header) => header.value)))
      const data = this.menuManifest.ingredients.map((ingredient) => {
        const unit = this.getUnit(ingredient.measure_type, ingredient.qty)
        return {
          name: ingredient.name,
          alias: Array.from(new Set(ingredient.alias.split(','))).toString(),
          qty: this.getTotalQty(ingredient.measure_type, ingredient.qty),
          unit: unit,
          status: this.checkIngredients(ingredient)
        }
      })
      const csv = JSONToCSV(data, { fields: head })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = getFileName('menu-manifest', 'csv')
      anchor.click()
    },
    async stockOut () {
      this.saveStockLoader = true
      const ingredients = JSON.parse(JSON.stringify(this.menuManifest.ingredients))
      if (this.canStockout) {
        for (let i = ingredients.length - 1; i >= 0; i--) {
          ingredients[i].id = uuidv4()
          ingredients[i].type = 'STOCK_OUT'
          ingredients[i].ingredientName = ingredients[i].name
          delete ingredients[i].name
          delete ingredients[i].date
          await this.$store.dispatch('stockOut/addStockOutItem', ingredients[i])
        }
        this.saveStockLoader = false
        await this.$store.dispatch('inventory/fetchAllIngredients')
        await this.$store.dispatch('inventory/updateStockoutInManifest', { id: this.menuManifest.menu.id })
        this.showSnackNotification('Ingredients successfully stocked out.', 'success')
      }
    },
    removeDuplicateAlias (alias) {
      return Array.from(new Set(alias.split(','))).toString()
    },
    getTotalQty (measureType, qty) {
      if (measureType === 'C') {
        return qty
      }
      if (measureType === 'W') {
        if (qty >= 1000 || qty <= -1000) {
          return gramToKilogram(qty)
        } else {
          return qty
        }
      } else {
        if (qty >= 1000 || qty <= -1000) {
          return mlToLitre(qty)
        } else {
          return qty
        }
      }
    },
    getUnit (unitType, qty) {
      if (unitType === 'C') {
        return 'Count'
      }
      if (unitType === 'W') {
        if (qty >= 1000 || qty <= -1000) {
          return 'kg'
        } else {
          return 'gram'
        }
      } else {
        if (qty >= 1000 || qty <= -1000) {
          return 'liter'
        } else {
          return 'ml'
        }
      }
    },
    validForStockOut () {
      this.valid = this.menuManifest.ingredients.filter(ingredient => ingredient.status === 'Not Available').length === 0
    },
    checkIngredients (item) {
      const pantryIngredient = this.allIngredients.find(pantryIngredient => pantryIngredient.ingredientId === item.ingredientId)
      if (pantryIngredient && Object.keys(pantryIngredient) && pantryIngredient.totalQty >= item.qty) {
        item.status = 'Available'
        return 'Available'
      } else {
        item.status = 'Not Available'
        return 'Not Available'
      }
    },
    showSnackNotification (text, type) {
      this.snackText = text
      this.snackType = type
      this.snackbar = true
    },
    reset () {
      this.$refs.form.reset()
    },
    async closeDialog () {
      await this.$store.dispatch('inventory/refreshMenuManifest')
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.response-success {
  color: green;
  font-size: 25px;
}

.response-error {
  color: red;
  font-size: 25px;
}

</style>
