<template>
  <v-dialog
    v-model="dialog"
    persistent
    style="z-index: 20001"
    max-width="800px"
    transition="dialog-bottom-transition"
    @keydown.esc="closeDialog"
  >
    <template #activator="{ on, attrs }">
      <div>
        <v-btn
          v-if="isAccessible"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            left
            dark
          >
            mdi-plus
          </v-icon>
          {{ dialogType === 'new'? 'New Product' : 'Edit Product' }}
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-btn
          dark
          icon
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="dialog-header">Add / Edit Product</span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            :loading="formLoader"
            :disabled="!valid"
            color="white"
            text
            @click="saveProduct"
          >
            Save
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <v-row class="my-2 mx-2">
          <span class="font-weight-bold font-24">Product Properties</span>
        </v-row>
        <v-row class="my-2 mx-2">
          <span>*indicates required field</span>
        </v-row>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col
              class="pa-10"
              cols="6"
            >
              <v-row>
                <v-text-field
                  v-model="productData.barcodeId"
                  autofocus
                  dense
                  label="Barcode ID"
                  outlined
                  required
                />
              </v-row>
              <!--  <v-row>
                  <v-select
                    v-model="productData.categoryId"
                    :items="categoryList"
                    :loading="categoryLoader"
                    :rules="categoryIdRules"
                    item-text="categoryName"
                    item-value="categoryId"
                    label="Category*"
                    outlined
                    required
                  >
                    <template #append-item>
                      <v-divider class="mb-2" />
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <CategoryDialog />
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-row>-->
              <v-row>
                <v-autocomplete
                  :value="productData.ingredientId"
                  :items="allIngredients"
                  dense
                  :rules="ingredientRules"
                  item-text="ingredientName"
                  item-value="ingredientId"
                  label="Ingredient*"
                  outlined
                  required
                  @input="setIngredientAndProduct"
                >
                  <!-- <template #append-item>
                      <v-divider class="mb-2" />
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <IngredientDialog />
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>-->
                </v-autocomplete>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="productData.productName"
                  dense
                  disabled
                  :rules="nameRules"
                  label="Product Name*"
                  outlined
                  required
                />
              </v-row>
              <v-row>
                <v-autocomplete
                  v-model="productData.brandId"
                  dense
                  :items="getBrands(productData.ingredientId)"
                  :loading="brandLoader"
                  :disabled="!productData.ingredientId"
                  :rules="brandRules"
                  item-text="brandName"
                  item-value="brandId"
                  label="Brand*"
                  outlined
                  required
                >
                  <template #append-item>
                    <v-divider class="mb-2" />
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          <BrandDialog class="m-1" />
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-row>
            </v-col>
            <v-col
              class="px-5"
              cols="6"
            >
              <v-row>
                <v-col cols="12">
                  <span class="mb-2 dialog-header">Product Perishable</span>
                </v-col>
                <v-divider />
                <v-col cols="12">
                  <v-radio-group
                    v-model="productData.isPerishable"
                    row
                    mandatory
                  >
                    <v-radio
                      label="Perishable"
                      :value="true"
                    />
                    <v-radio
                      label="Non Perishable"
                      :value="false"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
              <span class="mb-3 mt-5 dialog-header">Price Details</span>
              <v-divider class="mb-10" />
              <v-row>
                <v-col cols="12">
                  <span class="subtitle-1 black--text">Current Price : </span> <span class="black--text">{{ currency + ' ' + currentPrice.price + ' / ' + currentPrice.priceUnit }}</span>
                  <span>
                    <v-btn icon>
                      <v-icon
                        class="ml-2"
                        small
                        @click.stop="() => editPrice = !editPrice"
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </span>
                </v-col>
                <v-col
                  v-if="editPrice"
                  cols="12"
                >
                  <v-text-field
                    v-model.number="productData.price"
                    :prefix="currency"
                    type="number"
                    dense
                    outlined
                    label="Price"
                  >
                    <template #append>
                      <v-autocomplete
                        v-model="productData.priceUnit"
                        :items="getUnit(getIngredientById(productData.ingredientId))"
                        item-text="unit"
                        label="Unit"
                        item-value="unit"
                      />
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :color="
        snackType === 'success'
          ? 'green'
          : snackType === 'error'
            ? 'red'
            : 'yellow'
      "
      :timeout="1500"
    >
      {{ snackText }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'
// import CategoryDialog from './CategoryDialog'
// import IngredientDialog from './IngredientDialog'
import BrandDialog from './BrandDialog'
import roleMixin from '../../../utilities/mixins/roleMixin'

export default {
  name: 'ProductDialog',
  components: {
    //  IngredientDialog,
    // CategoryDialog,
    BrandDialog
  },
  mixins: [roleMixin],
  props: {
    open: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    snackbar: false,
    snackText: '',
    editPrice: false,
    categoryLoader: false,
    brandLoader: false,
    snackType: '',
    nameRules: [(v) => !!v || 'Name is required'],
    // categoryIdRules: [(v) => !!v || 'Category is required'],
    ingredientRules: [(v) => !!v || 'Ingredient is required'],
    brandRules: [(v) => !!v || 'Brand is required'],
    valid: true,
    dialog: false,
    formLoader: false,
    dialogType: 'new',
    properties: {},
    unitList: ['Gram', 'Kilogram', 'Ton', 'Mililitre', 'Litre', 'Count'],
    currentPrice: {
      price: 0,
      priceUnit: 'Gram'
    },
    productData: {
      productId: uuidv4(),
      barcodeId: '',
      productName: '',
      categoryId: '',
      ingredientId: '',
      brandId: '',
      price: 0,
      priceUnit: '',
      isPerishable: false
    }
  }),
  computed: {
    ...mapGetters({
      getUserInfoByKey: 'auth/getUserInfoByKey',
      categoryList: 'inventory/getAllCategories',
      scannedData: 'inventory/getProductData',
      allIngredients: 'inventory/getIngredientsByCompany',
      uniqueBrands: 'inventory/getUniqueBrandsForIngredientId',
      allBrands: 'inventory/getAllBrands',
      getUnitById: 'inventory/getUnitById',
      getIngredientById: 'inventory/getIngredientById',
      getAllIngredientsByCategoryId: 'inventory/getAllIngredientsByCategoryId'
    }),
    ingredientList () {
      return (id) => this.getAllIngredientsByCategoryId(id)
    },
    currency () {
      return this.getUserInfoByKey('currency')
    }
  },
  watch: {
    scannedData (newVal, oldVal) {
      this.productData = newVal
    }
  },
  async mounted () {

  },
  created () {
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned)
  },
  destroyed () {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy()
  },
  methods: {
    setIngredientAndProduct (ingredientId) {
      this.productData.ingredientId = ingredientId
      this.productData.productName = this.getIngredientById(ingredientId).ingredientName
    },
    getBrands (ingredientId) {
      if (this.dialogType === 'new') {
        return this.uniqueBrands(ingredientId)
      } else {
        return this.allBrands
      }
    },
    showSnackNotification (text, type) {
      this.snackText = text
      this.snackType = type
      this.snackbar = true
    },
    reset () {
      this.$refs.form.reset()
      this.dialog = false
    },
    closeDialog () {
      this.dialog = false
      this.dialogType = 'new'
      this.productData = {
        productId: uuidv4(),
        barcodeId: '',
        productName: '',
        categoryId: '',
        ingredientId: '',
        price: 0,
        priceUnit: '',
        brandId: '',
        isPerishable: false
      }
    },
    async getScannedProduct (barcode) {
      if (barcode) {
        await this.$store.dispatch(
          'inventory/fetchProductByBarcodeId',
          barcode
        )
      }
    },
    /* async uploadProductImage () {
      this.productData.imageUrl = await fileUpload(
        this.imageFile,
        'productImages',
        this.productData.productId + '_' + this.productData.productName
      )
    }, */
    openDialog (item) {
      item.price = parseFloat(item.price).toFixed(2)
      this.currentPrice = {
        price: item.price,
        priceUnit: item.priceUnit
      }
      this.productData = JSON.parse(JSON.stringify(item))
      this.dialog = true
    },
    async saveProduct () {
      this.formLoader = true
      try {
        if (this.$refs.form.validate()) {
          let payload = Object.create({})
          if (this.dialogType === 'new') {
            payload = {
              productData: JSON.parse(JSON.stringify(this.productData)),
              type: 'new'
            }
          } else {
            payload = {
              productData: JSON.parse(JSON.stringify(this.productData)),
              type: 'edit'
            }
          }
          const response = await this.$store.dispatch(
            'inventory/addProduct',
            payload
          )
          if (response) {
            this.reset()
            this.formLoader = false
            if (this.dialogType === 'new') {
              this.showSnackNotification('Product added successfully', 'success')
            } else {
              this.showSnackNotification('Product updated successfully', 'success')
            }
          } else {
            this.showSnackNotification('Error while adding product', 'error')
          }
        }
      } catch (e) {
        this.showSnackNotification('Error while adding product', 'error')
        this.formLoader = false
      }
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned (barcode) {
      this.getScannedProduct(barcode)
      // do something...
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode () {
      this.$barcodeScanner.getPreviousCode()
      // do something...
    },
    getUnit (ingredient) {
      if (ingredient.measure_type === 'C') {
        return ['count', 'dozen']
      } else {
        return ingredient.uom.filter(unit =>
          unit.unit === 'gram' ||
          unit.unit === 'kg' ||
          unit.unit === 'ml' ||
          unit.unit === 'liter' ||
          unit.unit === 'fl-oz' ||
          unit.unit === 'pound'
        )
      }
    }
  }
}
</script>

<style scoped>
    .v-input--selection-controls {
        margin-top: 0 !important;
    }

    .dialog-header {
        font-size: 1.25rem;
        font-weight: 500;
    }

    .center-items {
        align-items: center;
        justify-content: center;
    }
</style>
