import * as firebase from '../../firebase.config'
export const recipe = {
  namespaced: true,
  state: {
    recipes: []
  },
  actions: {
    async fetchAllRecipes ({ commit }) {
      try {
        const recipeList = []
        const recipeSnapshot = await firebase.recipeCollection().get()
        if (!recipeSnapshot.empty) {
          recipeSnapshot.docs.forEach(doc => recipeList.push(JSON.parse(JSON.stringify(doc.data()))))
        }
        commit('setAllRecipes', recipeList)
      } catch (e) {
        console.error(e)
      }
    }
  },

  mutations: {
    setAllRecipes (state, val) {
      state.recipes = val.sort()
    }
  },
  getters: {
    getAllRecipes: (state) => {
      return state.recipes.sort(function (a, b) {
        return a.recipe_title.localeCompare(b.recipe_title)
      })
    }
  }
}
