<template>
  <div id="wrapper">
    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card
          id="v-step-4"
          class="mx-auto mt-5"
        >
          <v-row class="mx-auto">
            <div class="d-flex align-center text-center">
              <h4 class="pl-7">
                Stock Out By Menu
              </h4>
            </div>
            <v-spacer />
            <div>
              <v-breadcrumbs
                :items="links"
                divider=">"
              />
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card class="mx-auto">
          <div class="card-box">
            <div>
              <v-row class="d-flex justify-end">
                <v-spacer />
                <StockOutByMenuForm @calculateStock="(rp) => calculateStockEstimate(rp,'menu')" />
              </v-row>
              <v-row
                justify="center"
              >
                <v-col cols="12">
                  <v-data-table
                    id="v-step-3"
                    :headers="headers"
                    :item-class="outOfStockBg"
                    :items="estimatedIngredientsForPurchase"
                    :search="search"
                    class="px-3 table-striped table-bordered dt-responsive"
                  >
                    <template #top>
                      <v-row class="d-flex align-start justify-content-between mt-2">
                        <v-col
                          class="d-flex align-center"
                          cols="6"
                        >
                          <v-text-field
                            v-model="search"
                            dense
                            clear-icon="mdi-close-circle-outline"
                            clearable
                            label="Search"
                            prepend-inner-icon="mdi-magnify"
                            solo
                          />
                        </v-col>
                        <v-col class="d-flex align-center">
                          <div>
                            <v-menu
                              v-model="downloadMenu"
                              :close-on-content-click="false"
                              :nudge-width="300"
                              offset-y
                            >
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  outlined
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon
                                    left
                                    dark
                                  >
                                    mdi-export
                                  </v-icon>
                                  Export
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-form
                                    ref="downloadForm"
                                    v-model="validDownloadForm"
                                  >
                                    <span class="font-weight-bold text-subtitle-1">Export Type</span>
                                    <v-divider />
                                    <v-radio-group
                                      v-model="downloadForm.exportType"
                                      row
                                    >
                                      <v-radio
                                        label="CSV"
                                        value="csv"
                                      >
                                        CSV
                                      </v-radio>
                                      <v-radio
                                        label="PDF"
                                        value="pdf"
                                      >
                                        PDF
                                      </v-radio>
                                    </v-radio-group>
                                    <span class="font-weight-bold text-subtitle-1">Select Headers</span>
                                    <v-divider />
                                    <v-select
                                      v-model="downloadForm.selectedHeaders"
                                      multiple
                                      label="Select Headers"
                                      :items="headers"
                                      dense
                                      return-object
                                      :rules="notEmptyRule"
                                      item-text="text"
                                      item-value="value"
                                      outlined
                                    >
                                      <template #selection="{ item,index }">
                                        <span v-if="index > 0 && index <=2">,</span>
                                        <span v-if="index <= 2">{{ item.text }}</span>
                                        <span
                                          v-if="index === 3"
                                          class="grey--text text-caption"
                                        >
                                          (+{{ downloadForm.selectedHeaders.length - 3 }} others)
                                        </span>
                                      </template>
                                      <template #prepend-item>
                                        <v-text-field
                                          v-model="headerSearchTerm"
                                          outlined
                                          autofocus
                                          class="mx-2 px-2"
                                          label="Search Header"
                                          dense
                                        />
                                        <v-divider class="my-2" />
                                        <v-list-item
                                          ripple
                                          @mousedown.prevent
                                          @click="toggle"
                                        >
                                          <v-list-item-action>
                                            <v-icon :color="downloadForm.selectedHeaders.length > 0 ? 'primary' : ''">
                                              {{ icon }}
                                            </v-icon>
                                          </v-list-item-action>
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              Select All
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2" />
                                      </template>
                                    </v-select>
                                  </v-form>
                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer />

                                  <v-btn
                                    text
                                    @click="downloadMenu = false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    :disabled="downloadForm.selectedHeaders.length === 0"
                                    @click="exportData"
                                  >
                                    Export
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-menu>
                          </div>
                          <div class="ml-2">
                            <v-btn
                              :disabled="estimatedIngredientsForPurchase.length === 0"
                              color="primary"
                              :loading="saveStockLoader"
                              @click="stockOut"
                            >
                              Stock Out
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                    <template #item.alias="{item}">
                      {{ getAlias(item) }}
                    </template>
                    <template #item.qty="{item}">
                      {{ getTotalQty(item, 'qty') }}
                      {{ getUnit(item, 'qty') }}
                    </template>
                    <template #item.perPersonQty="{ item }">
                      {{ getTotalQty(item, 'perPersonQty') }}
                      {{ getUnit(item, 'perPersonQty') }}
                    </template>
                    <template #item.currentQty="{ item }">
                      {{ getTotalQty(item) }}
                      {{ getUnit(item) }}
                    </template>
                    <!-- <template #item.deducedQty="{ item }">
                      {{ getTotalQty(item, 'deducedQty') }}
                      {{ getUnit(item, 'deducedQty') }}
                    </template> -->
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="confirmDialog"
      max-width="500px"
      style="z-index:20001;"
    >
      <v-card>
        <v-card-title>
          <v-col>
            <v-row class="font-18">
              Are you sure you want to Stock Out items?
            </v-row>
            <v-row
              class="font-13 font-weight-bold"
            >
              Some items in your list contains negative value. Are you sure you want to still stock out?
            </v-row>
          </v-col>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="confirmDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="saveStockLoader"
            @click="confirmAndStockout"
          >
            Confirm & Stock Out
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      :color="error ? 'red': 'green'"
    >
      {{ snackText }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import apiMixin from '../../utilities/mixins/apiMixin'
import { parse as JSONToCSV } from 'json2csv'
import JsPdf from 'jspdf'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import StockOutByMenuForm from '@/views/Stockout/components/StockOutByMenuForm'
import { v4 as uuidv4 } from 'uuid'
import { getFileName } from '@/utilities/fileNameUtil'
import { getTotalQty, getUnit } from '@/utilities/unitConverter'

export default {
  name: 'StockOutMenu',
  components: {
    StockOutByMenuForm

  },
  mixins: [apiMixin],
  data () {
    return {
      confirmDialog: false,
      headerSearchTerm: '',
      valid: false,
      notEmptyRule: [(v) => !!v || ' This field is required'],
      countRule: [(v) => !!v || 'This field is required', (v) => v > 0 || 'Value should be greater than 0'],
      errorSnackBar: false,
      snackbar: false,
      snackText: '',
      selectedRecipes: [],
      search: '',
      headers: [
        {
          text: 'Item Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Local Alias',
          sortable: true,
          value: 'alias'
        },
        {
          text: 'Qty (per person)',
          sortable: true,
          value: 'perPersonQty'
        },
        {
          text: 'Total Qty to Stockout',
          sortable: true,
          value: 'qty'
        }
        // {
        //   text: 'Current Qty',
        //   sortable: true,
        //   value: 'currentQty'
        // },
        // {
        //   text: 'Qty After Stockout',
        //   sortable: true,
        //   value: 'deducedQty'
        // }
      ],
      links: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/dashboard'
        },
        {
          text: 'Stock Out By Menu',
          disabled: false,
          href: '/stock-out-menu'
        }
      ],
      recipeLoader: false,
      calculationType: 'menu',
      saveStockLoader: false,
      error: false,
      errorIngredientList: [],
      validDownloadForm: false,
      downloadMenu: false,
      downloadForm: {
        exportType: 'csv',
        selectedHeaders: []
      }
    }
  },
  computed: {
    ...mapGetters({
      recipes: 'recipe/getAllRecipes',
      estimatedIngredients: 'estimate/getEstimateByType',
      getUserInfoByKey: 'auth/getUserInfoByKey',
      productList: 'inventory/getAllProducts'

    }),
    allHeadersSelected () {
      return this.headers.length === this.downloadForm.selectedHeaders.length
    },
    estimatedIngredientsForPurchase () {
      return this.estimatedIngredients('purchase')
    },
    icon () {
      if (this.allHeadersSelected) return 'mdi-close-box'
      if (!this.allHeadersSelected && this.downloadForm.selectedHeaders.length > 0) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  watch: {},
  created () {

  },
  destroyed () {

  },
  async mounted () {
    await this.$store.dispatch('inventory/fetchAllIngredients')
    await this.$store.dispatch('recipe/fetchAllRecipes')
    this.$store.commit('estimate/setStockEstimate', { finalIngredientList: [], ingredientsByRecipeList: [] })
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.allHeadersSelected) {
          this.downloadForm.selectedHeaders = []
        } else {
          this.downloadForm.selectedHeaders = this.headers.slice()
        }
      })
    },
    getAlias (item) {
      if (item.alias && item.alias.length > 0) {
        return item.alias.join(', ')
      } else {
        return ''
      }
    },
    async calculateStockEstimate (recipes, type) {
      this.selectedRecipes = recipes
      this.calculationType = type
      await this.$store.dispatch('estimate/calculateStockEstimate', {
        recipeList: recipes,
        type
      })
    },
    getTotalQty (ingredient, qtyKey) {
      // console.log(ingredient, qtyKey)
      return getTotalQty(ingredient, qtyKey)
    },
    getUnit (ingredient, qtyKey) {
      // console.log(ingredient, qtyKey)

      return getUnit(ingredient, qtyKey)
    },
    async generateCsv () {
      const vm = this
      // let head = JSON.parse(JSON.stringify(vm.downloadForm.selectedHeaders.map((header) => header.text)))
      // head = head.filter(header => header !== 'Item Name' && header !== 'Local Alias' && header !== 'Total Qty to Stockout')

      const head = vm.downloadForm.selectedHeaders.map((header) => header.text).filter((header) => !['Item Name', 'Local Alias', 'Total Qty to Stockout'].includes(header))

      head.push(...['product_name', 'brand_name', 'expiry_date', 'min_qty', 'qty', 'unit', 'price'])
      head.unshift(...['ingredient', 'local_alias'])
      const data = []
      this.estimatedIngredientsForPurchase.forEach((ingredient) => {
        const ing = this.productList.find(ing => ing.ingredientId === ingredient.ingredientId)
        const rowData = {}
        rowData.ingredient = ingredient.name
        rowData.product_name = ingredient.name
        rowData.brand_name = 'Generic'
        rowData.expiry_date = ''
        rowData.min_qty = 100
        rowData.price = ing.price + '/' + ing.priceUnit
        rowData.unit = `${this.getUnit(ingredient, 'qty')}`
        rowData.local_alias = this.getAlias(ingredient)
        rowData['Qty (per person)'] = `${this.getTotalQty(ingredient, 'perPersonQty')} ${this.getUnit(ingredient, 'perPersonQty')}`
        rowData.qty = `${this.getTotalQty(ingredient, 'qty')}`
        rowData['Current Qty'] = `${this.getTotalQty(ingredient)} ${this.getUnit(ingredient)}`
        rowData['Qty After Stockout'] = `${this.getTotalQty(ingredient, 'deducedQty')} ${this.getUnit(ingredient, 'deducedQty')}`
        data.push(rowData)
      })
      const csv = JSONToCSV(data, { fields: head })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      anchor.download = getFileName('stockout-sample', 'csv')
      anchor.click()
    },
    generatePDF () {
      const vm = this
      const columns = cloneDeep(this.downloadForm.selectedHeaders.map(header => {
        const data = {}
        data.title = header.text
        data.dataKey = header.text
        return data
      }))
      const recipeColumns = [
        {
          title: 'Recipe Name',
          dataKey: 'recipeName'
        }, {
          title: 'No of People',
          dataKey: 'noOfPeople'
        }
      ]
      const doc = new JsPdf({
        theme: 'grid',
        orientation: 'portrait',
        unit: 'in',
        format: 'letter'
      })
      // text is placed using x, y coordinates
      doc.setTextColor('#cb8037')
      doc.setFont('helvetica', 'bold').setFontSize(22).text(`${this.getUserInfoByKey('company_name')} Stock Out Report`, 0.5, 0.5)
      doc.setLineWidth(0.01).line(0.5, 0.7, 8.0, 0.7)
      // create a line under heading
      doc.setFont('helvetica').setFontSize(10).text(`Date:  ${moment().format(this.getUserInfoByKey('date_format'))}`, 7.0, 0.3)

      doc.setFont('helvetica', 'bold').setFontSize(15).text('Recipes', 0.5, 1.3)
      doc.setLineWidth(0.01).line(0.5, 1.5, 4.0, 1.5)

      const recipeData = cloneDeep(this.selectedRecipes.map((element) => {
        const rowData = {}
        if (vm.calculationType === 'menu') {
          rowData.recipeName = `${element.recipe_title}`
        } else {
          rowData.recipeName = `${element.selectedRecipe.recipe_title} X ${element.noOfDays}`
        }
        rowData.noOfPeople = element.noOfPeople
        return rowData
      }))
      doc.autoTable({
        headStyles: { fillColor: '#cb8037' },
        columns: recipeColumns,
        body: recipeData,
        margin: { left: 0.5, top: 1.8, bottom: 2.0 },
        didDrawPage: function (data) {
          data.settings.margin.top = 1
        }
      })
      // Using autoTable plugin
      const data = cloneDeep(this.estimatedIngredientsForPurchase.map((element) => {
        const rowData = {}
        rowData['Item Name'] = element.name
        rowData['Local Alias'] = element.alias
        rowData['Qty (per person)'] = `${this.getTotalQty(element, 'perPersonQty')} ${this.getUnit(element, 'perPersonQty')}`
        rowData['Total Qty to Stockout'] = `${this.getTotalQty(element, 'qty')} ${this.getUnit(element, 'qty')}`
        rowData['Current Qty'] = `${this.getTotalQty(element)} ${this.getUnit(element)}`
        rowData['Qty After Stockout'] = `${this.getTotalQty(element, 'deducedQty')} ${this.getUnit(element, 'deducedQty')}`
        return rowData
      }))
      doc.autoTable({
        headStyles: { fillColor: '#cb8037' },
        columns,
        body: data,
        margin: { left: 0.5, top: 2.5, bottom: 2 },
        didDrawPage: function (data) {
          data.settings.margin.top = 1
        }
      })
      this.addFooters(doc)
      // Creating footer and saving file
      doc.save(getFileName('stockout-report', 'pdf'))
    },
    addFooters (doc) {
      const pageCount = doc.internal.getNumberOfPages()

      doc.setFont('helvetica')
      doc.setFontSize(16)
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.setLineWidth(0.1).line(0.5, doc.internal.pageSize.height - 1, 8.0, doc.internal.pageSize.height - 1)
        doc.text(
          `\u00A9 ${moment().year()} — SM Pantry`,
          0.5,
          doc.internal.pageSize.height - 0.5
        )
        doc.text(
          `Page ${i}`,
          7.0,
          doc.internal.pageSize.height - 0.5, 'left'
        )
      }
    },
    async confirmAndStockout () {
      this.confirmDialog = false
      await this.stockoutIngredients()
    },
    async stockOut () {
      this.confirmDialog = this.estimatedIngredientsForPurchase.filter(item => {
        const qty = this.getTotalQty(item, 'deducedQty')
        return parseInt(qty) < 0
      }).length > 0
      if (!this.confirmDialog) {
        await this.stockoutIngredients()
      }
    },
    async stockoutIngredients () {
      this.saveStockLoader = true
      for (let i = this.estimatedIngredientsForPurchase.length - 1; i >= 0; i--) {
        delete this.estimatedIngredientsForPurchase[i].alias
        const data = this.estimatedIngredientsForPurchase[i]
        const response = await this.$store.dispatch('stockOut/addStockOutItem', {
          id: uuidv4(),
          ingredientId: data.ingredientId,
          ingredientName: data.name,
          mealType: 'MENU',
          qty: data.qty,
          type: 'STOCK_OUT'

        })
        if (!response) {
          this.saveStockLoader = false
          this.error = true
          this.snackText = 'Error while stocking out some ingredients.Please check the error file for more information'
          this.snackbar = true
          this.errorIngredientList.push(data)
        }
        this.deleteFromStockOutList(this.estimatedIngredientsForPurchase[i])
      }
      this.saveStockLoader = false
      await this.$store.dispatch('inventory/fetchAllIngredients')
      this.error = false
      this.snackText = 'Ingredients successfully stocked out.'
      this.snackbar = true
      if (this.errorIngredientList.length > 0) {
        this.downloadErrorFile(this.errorIngredientList)
      }
    },
    downloadErrorFile (ingredientList) {
      let data = ''
      for (const ingredient of ingredientList) {
        data += 'Ingredient Name: ' + ingredient.name + '\n'
        data += 'Ingredient Id: ' + ingredient.ingredientId + '\n'
      }
      const blob = new Blob([data], { type: 'text;charset=utf-8;' })
      const link = document.createElement('a')
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', 'error')
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    outOfStockBg (item) {
      const qty = this.getTotalQty(item, 'deducedQty')
      return parseInt(qty) < 0 ? 'outofstock-style' : ''
    },
    deleteFromStockOutList (item) {
      const index = this.estimatedIngredientsForPurchase.findIndex(
        (cat) => cat.ingredientId === item.ingredientId
      )
      this.estimatedIngredientsForPurchase.splice(index, 1)
    },
    exportData () {
      if (this.downloadForm.selectedHeaders.length > 0) {
        if (this.downloadForm.exportType === 'pdf') {
          this.generatePDF()
        } else {
          this.generateCsv()
        }
      }
    }
  }
}
</script>

<style>
.outofstock-style {
  color: #c93030;
}

</style>
