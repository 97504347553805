<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      class="overflow-hidden"
      fullscreen
      persistent
      style="z-index: 20001"
      transition="dialog-bottom-transition"
      @keydown.esc="dialog = false"
    >
      <template #activator="{ on, attrs }">
        <div class="d-flex pb-3 pt-3">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Add / Edit Brand
          </v-btn>
        </div>
      </template>
      <v-toolbar
        color="primary"
        dark
      >
        <v-btn
          dark
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Brands</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-container>
          <v-row class="my-2 mx-2">
            <span class="font-weight-bold font-24">Add Brand</span>
          </v-row>
          <v-row class="my-2 mx-2">
            <span>*indicates required field</span>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-form
                ref="brandForm"
                v-model="valid"
              >
                <v-row class="my-2 mx-2">
                  <v-text-field
                    v-model="brandName"
                    label="Brand Name*"
                    outlined
                    required
                    :rules="nameRules"
                  />
                </v-row>
                <v-row class="my-2 mx-2 d-none">
                  <v-text-field
                    v-model="brandName"
                    label="Brand Name*"
                    outlined
                    required
                    :rules="nameRules"
                  />
                </v-row>
                <v-row class="my-2 mx-2 d-flex justify-center">
                  <v-btn
                    class="primary"
                    block
                    @click="saveBrand"
                  >
                    Save
                  </v-btn>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="my-5 mx-2">
            <span class="font-weight-bold font-24"> Existing Brands </span>
          </v-row>
          <v-data-table
            id="datatable"
            :headers="headers"
            :items="brands"
            class="px-3 table-striped table-bordered dt-responsive"
            :search="search"
          >
            <template #top>
              <v-row class="d-flex justify-start align-start mt-2">
                <v-col
                  class="d-flex justify-center align-center"
                  cols="7"
                >
                  <v-text-field
                    v-model="search"
                    clear-icon="mdi-close-circle-outline"
                    clearable
                    label="Search Brand"
                    solo
                  />
                </v-col>
              </v-row>
            </template>
            <template #item.actions="{ item }">
              <v-icon
                class="mr-2"
                size="25"
                @click="editBrand(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                size="25"
                @click="deleteBrand(item)"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="dialogDelete = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItemConfirm"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEdit"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Edit Brand</v-card-title>
        <v-card-text>
          <v-form
            ref="brandEditingForm"
            v-model="editingFormValid"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="editingBrand.brandName"
                label="Brand Name"
                outlined
                required
                :rules="nameRules"
              />
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="dialogEdit = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateBrand(editingBrand)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'

export default {
  name: 'BrandDialog',
  data: () => ({
    valid: false,
    editingFormValid: false,
    nameRules: [(v) => !!v || 'Brand name is required'],
    dialog: false,
    formLoader: false,
    brandName: '',
    search: '',
    dialogEdit: false,
    dialogDelete: false,
    editingBrand: {},
    headers: [
      {
        text: 'Brand Name',
        align: 'start',
        sortable: true,
        value: 'brandName'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      }
    ]
  }),
  computed: {
    ...mapGetters({ brands: 'inventory/getAllBrands' })
  },
  async mounted () {
    await this.$store.dispatch('inventory/fetchAllBrands')
  },
  methods: {
    resetAddBrandForm () {
      this.$refs.brandForm.reset()
    },
    resetEditBrandForm () {
      this.$refs.brandEditingForm.reset()
    },
    showEdit (brand, event) {
      brand.editBtnDisplay = brand.brandName === event
    },
    async updateBrand (brand) {
      this.formLoader = true
      try {
        if (this.$refs.brandEditingForm.validate()) {
          await this.$store.dispatch('inventory/updateBrand', brand)
          this.resetEditBrandForm()
          this.dialogEdit = false
        }
        this.formLoader = false
      } catch (e) {
        this.formLoader = false
      }
    },
    async deleteItemConfirm () {
      this.formLoader = true
      await this.$store.dispatch(
        'inventory/deleteBrandById',
        this.editingBrand.brandId
      )
      this.dialogDelete = false
      this.formLoader = false
    },
    async saveBrand () {
      this.formLoader = true
      try {
        if (this.$refs.brandForm.validate()) {
          await this.$store.dispatch('inventory/addBrand', {
            brandName: this.brandName,
            brandId: uuidv4()
          })
          this.resetAddBrandForm()
        }
        this.formLoader = false
      } catch (e) {
        this.formLoader = false
      }
    },
    closeDialog () {
      this.dialog = false
    },
    editBrand (item) {
      this.editingBrand = JSON.parse(JSON.stringify(item))
      this.dialogEdit = true
    },
    deleteBrand (item) {
      this.dialogDelete = true
      this.editingBrand = item
    }
  }
}
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0 !important;
}

.dialog-header {
  font-size: 1.25rem;
  font-weight: 500;
}
</style>
