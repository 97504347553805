<template>
  <v-card class="mx-auto">
    <v-row class="d-flex justify-center my-3">
      <v-col v-if="!isLoading">
        <v-row @click="showDialog">
          <v-col
            cols="4"
            class="d-flex justify-center"
          >
            <v-icon
              size="50"
              class="font-color"
            >
              mdi-alert-decagram-outline
            </v-icon>
          </v-col>
          <v-col
            cols="8"
            class=""
          >
            <h2 class="font-color">
              {{
                outOfStockIngredients.length
              }}
            </h2>
            <p class="text-uppercase mb-1 font-13 font-weight-bold font-color">
              Out of Stock
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-progress-circular
        v-if="isLoading"
        class="my-3"
        indeterminate
        color="#c93030"
      />
    </v-row>
    <IngredientsTableDialog
      title="out-of-stock-list"
      :ingredients="outOfStockIngredients"
      :is-loading="isLoading"
      :dialog="dialog"
      @closeDialog="closeDialog"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import IngredientsTableDialog from '@/views/Dashboard/components/IngredientsTableDialog'

export default {
  name: 'OutOfStock',
  components: { IngredientsTableDialog },
  props: {
    isLoading: Boolean
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapGetters({ ingredients: 'inventory/getIngredientsByCompany' }),
    outOfStockIngredients () {
      return this.ingredients.filter((ingredient) => {
        return ingredient.totalQty <= 0
      })
    }
  },
  methods: {
    showDialog () {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.my-3 {
  padding: 1.25rem;
}
.font-color {
  color: #c93030;
  cursor: pointer;
}
</style>
