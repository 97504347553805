import * as firebase from '../../firebase.config'
import moment from 'moment'
import * as Sentry from '@sentry/browser'

export const stockOut = {
  namespaced: true,
  state: {
    pendingStockOutList: [],
    remarkList: [],
    stockedOutIngredients: []
  },
  mutations: {
    setAllPendingStockOuts (state, payload) {
      payload.forEach(ingredient => { ingredient.date = moment(ingredient.timeStamp.toDate()).format('YYYY-MM-DD') })
      state.pendingStockOutList = payload
    },
    setAllRemarks (state, val) {
      state.remarkList = val
    },
    setStockedOutIngredients (state, payload) {
      state.stockedOutIngredients = payload
    }
  },
  actions: {
    async fetchAllRemarks ({ commit }) {
      try {
        const remarkList = []
        firebase.remarkCollection().get().then((snapshot) => {
          snapshot.forEach((doc) => {
            const remark = doc.data()
            remarkList.push(remark)
          })
          commit('setAllRemarks', remarkList)
        })
      } catch (e) {}
    },
    async addRemark ({ dispatch }, form) {
      try {
        await firebase.remarkCollection().doc(form.remarkId).set(form)
        dispatch('fetchAllRemarks')
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async updateRemark ({ dispatch }, form) {
      try {
        await firebase.remarkCollection().doc(form.remarkId).update(form)
        dispatch('fetchAllRemarks')
        return true
      } catch (e) {
        return false
      }
    },
    async deleteRemarkById ({ dispatch }, remarkId) {
      try {
        await firebase.remarkCollection().doc(remarkId).delete()
        dispatch('fetchAllRemarks')
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async deleteStockOutItem ({ dispatch }, id) {
      try {
        await firebase.stockCollection().doc(id).delete()
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async fetchAllPendingStockOuts ({ commit }) {
      try {
        const pendingStockItems = []
        firebase.stockCollection().where('type', '==', 'UNVERIFIED')
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              const remark = doc.data()
              pendingStockItems.push(remark)
            })
            commit('setAllPendingStockOuts', pendingStockItems)
          })
      } catch (e) {}
    },
    async addStockOutItem ({ commit, dispatch, state }, stockOutItem) {
      try {
        const batch = firebase.db.batch()
        const decrement = firebase.fieldValue.increment(-stockOutItem.qty)
        const ingredientRef = await firebase.ingredientCollection().doc(
          stockOutItem.ingredientId
        )
        batch.update(ingredientRef, { totalQty: decrement })
        const stockOutRef = await firebase.stockCollection().doc(stockOutItem.id)
        if (stockOutItem.unit) {
          delete stockOutItem.unit
        }
        batch.set(stockOutRef, {
          timeStamp: firebase.firestoreNow(),
          ...stockOutItem,
          qty: Math.abs(stockOutItem.qty)
        })
        await batch.commit()
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async addAuditItem ({ commit, dispatch, state }, auditItem) {
      try {
        const batch = firebase.db.batch()
        const decrement = firebase.fieldValue.increment(-auditItem.qty)
        const ingredientRef = await firebase.ingredientCollection().doc(
          auditItem.ingredientId
        )
        batch.update(ingredientRef, { totalQty: decrement })
        const stockOutRef = await firebase.stockCollection().doc(auditItem.id)
        delete auditItem.unit
        if (!auditItem.remarkId) {
          delete auditItem.remarkId
        }
        batch.set(stockOutRef, {
          timeStamp: firebase.firestoreNow(),
          ...auditItem,
          qty: Math.abs(auditItem.qty)
        })
        await batch.commit()
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
    async fetchStockedOutIngredientsByDate ({ commit }, period) {
      try {
        const ingredientList = []
        const todaysDate = moment().add(1, 'days')
        const fromDate = moment().subtract(period + 1, 'days')
        const stockSnapshot = await firebase.stockCollection().where('type', '==', 'STOCK_OUT').get()
        const stockOutList = stockSnapshot.docs.map(doc => {
          return doc.data()
        })
        stockOutList.forEach(data => {
          const date = data.timeStamp.toDate()
          if (moment(date).isBetween(fromDate, todaysDate, 'day')) {
            ingredientList.push(data.ingredientId)
          }
        })
        commit('setStockedOutIngredients', ingredientList)
      } catch (e) {
        Sentry.captureException(e)
      }
    }
  },
  getters: {
    getPendingStockOutList (state) {
      return state.pendingStockOutList
    },
    getAllRemarks: (state) => {
      return state.remarkList
    },
    getRemarkById: (state) => (id) => {
      return state.remarkList.find((remark) => remark.remarkId === id)
    },
    stockedOutIngredients: (state) => {
      return state.stockedOutIngredients
    }
  }
}
