import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueBarcodeScanner from 'vue-barcode-scanner'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import * as firebase from '../firebase.config'
import cors from 'cors'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { CanvasRenderer } from 'echarts/renderers'
import VueTour from 'vue-tour'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import moment from 'moment-timezone'
import axios from 'axios'

import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from 'echarts/components'
import { BarChart, LineChart, PieChart, RadarChart } from 'echarts/charts'
require('vue-tour/dist/vue-tour.css')

use([
  LegendComponent,
  TooltipComponent,
  CanvasRenderer,
  GridComponent,
  ToolboxComponent,
  TitleComponent,
  BarChart,
  LineChart,
  PieChart,
  RadarChart
])

Vue.config.productionTip = false
Vue.component('VChart', ECharts)
Vue.use(VueBarcodeScanner)
Vue.use(VueTour)
Vue.use(cors)
Vue.use(VueLodash, { name: '$_', lodash })

Sentry.init({
  Vue,
  dsn: 'https://42a4bb82eeaf4b15ad142c37d28df293@o928411.ingest.sentry.io/5877765',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'pantry.smartmawaid.com', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  logErrors: true
})
router.beforeEach(async (to, from, next) => {
  const user = JSON.parse(localStorage.getItem('user')) || []

  const timeZone = moment.tz.guess()

  const date = moment().tz(timeZone).format('DD MMMM YYYY, hh:mm A')

  const logMessageObject = {

    clogMessage: `${user.name} from ${user.company_name} was on path ${to.path} on ${date} ${timeZone}`
  }

  const logObject = {
    page_name: 'Pantry',
    class_name: 'Router',
    function_name: 'Route_Log',
    method: 'GET',
    user_Id: user.smId,
    jamaat_Id: user.smId,
    logData: logMessageObject
  }

  const apiUrl = `${process.env.VUE_APP_SMP_BASE_URL}/api/api/addlog`
  await axios.post(apiUrl, logObject)

  next()
})

let app
firebase.auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})
