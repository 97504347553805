<template>
  <div id="wrapper">
    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card
          id="v-step-4"
          class="mx-auto mt-5"
        >
          <v-row class="mx-auto">
            <div class="d-flex align-center text-center">
              <h4 class="pl-7">
                Stock In
              </h4>
            </div>
            <v-spacer />
            <div>
              <v-breadcrumbs
                :items="links"
                divider=">"
              />
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mx-auto">
      <v-col
        cols="10"
        offset-md="1"
      >
        <v-card class="mx-auto">
          <div class="card-box">
            <div>
              <v-row class="d-flex align-center justify-center">
                <v-col
                  id="v-step-0"
                  cols="12"
                  lg="4"
                  md="4"
                  sm="12"
                  xs="12"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="barcodeId"
                        autofocus
                        dense
                        v-bind="attrs"
                        append-icon="mdi-barcode-scan"
                        label="Barcode ID"
                        outlined
                        required
                        v-on="on"
                      />
                    </template>
                    <span>Handheld Scanner</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  lg="1"
                  md="1"
                  sm="12"
                >
                  <div class="d-flex justify-center ml-2 mb-5 font-weight-bold font-18">
                    OR
                  </div>
                </v-col>
                <v-col
                  id="v-step-1"
                  cols="6"
                  md="2"
                  lg="2"
                  class="d-flex justify-center"
                >
                  <v-dialog
                    v-model="addDialog"
                    max-width="500px"
                    persistent
                    style="z-index: 20001"
                    transition="dialog-bottom-transition"
                    @keydown.esc="addDialog = false"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        dense
                        class="primary mb-6"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Add To Stock
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>Add Stock In Details</v-card-title>
                      <v-form
                        ref="form"
                        v-model="valid"
                      >
                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              <v-autocomplete
                                v-model="selectedIngredient"
                                dense
                                :items="ingredientList"
                                :rules="ingredientRule"
                                item-text="ingredientName"
                                item-value="ingredientValue"
                                label="Ingredient*"
                                outlined
                                required
                                return-object
                              >
                                <!-- <template #append-item>
                                  <v-divider class="mb-2" />
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <IngredientDialog class="m-1" />
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>-->
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                              <v-autocomplete
                                v-model="selectedProduct"
                                dense
                                :disabled="Object.keys(selectedIngredient).length === 0"
                                :items="getProductsByIngredientId(selectedIngredient.ingredientId)"
                                item-text="productName"
                                item-value="productId"
                                :rules="productRule"
                                label="Product*"
                                outlined
                                return-object
                                required
                              >
                                <template #item="{ item }">
                                  {{ item.productName }}
                                  <v-spacer />
                                  <v-list-item-action @click.stop>
                                    Brand : {{ getBrandById(item.brandId).brandName }}
                                  </v-list-item-action>
                                  <v-list-item-action @click.stop>
                                    <v-btn
                                      icon
                                      @click="openEditProductDialog(item)"
                                    >
                                      <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </template>
                                <template #selection="{ item }">
                                  {{ item.productName }}
                                  {{
                                    Object.keys(item).length > 0 ? '( ' + getBrandById(item.brandId).brandName + ' )' : ''
                                  }}
                                </template>
                                <template #append-item>
                                  <v-divider class="mb-2" />
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <ProductDialog
                                          ref="productDialog"
                                          class="m-1"
                                        />
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="expiryDate"
                                dense
                                :rules="expiryDateRule"
                                label="Expiration Date"
                                outlined
                                :placeholder="getUserInfoByKey('date_format')"
                                required
                                type="date"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model.number="stockInQuantity"
                                dense
                                label="Stock in Quantity"
                                :rules="qtyRules"
                                outlined
                                required
                                type="number"
                              >
                                <template #append>
                                  <v-autocomplete
                                    v-model="unitType"
                                    dense
                                    :items="selectedIngredient.uom"
                                    item-text="unit"
                                    item-value="unit"
                                    label="Unit"
                                  />
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <h5 class="mb-4 mx-2">
                                {{ getAvailableQty() }}
                              </h5>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model.number="price"
                                dense
                                label="Total Price"
                                :rules="priceRule"
                                outlined
                                :prefix="getUserInfoByKey('currency')"
                                required
                                type="number"
                              />
                              <h5 class="mb-4 mx-2">
                                {{
                                  getHintText()
                                }}
                              </h5>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model.number="selectedIngredient.minQty"
                                dense
                                label="Minimum Quantity"
                                :rules="qtyRules"
                                outlined
                                required
                                type="number"
                              >
                                <template #append>
                                  <v-autocomplete
                                    v-model="selectedIngredient.minQtyUnit"
                                    :items="selectedIngredient.uom"
                                    item-text="unit"
                                    label="Unit"
                                    item-value="unit"
                                  />
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-form>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          text
                          @click="closeDialog"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          :disabled="!valid"
                          @click="addToStockList"
                        >
                          Add to List
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col
                  id="v-step-2"
                  cols="6"
                  md="2"
                  lg="2"
                  class="mb-6 d-flex justify-center"
                >
                  <BulkImportDialog ref="bulkImport" />
                </v-col>
                <v-spacer />
              </v-row>
              <v-row
                justify="center"
              >
                <v-col cols="12">
                  <v-data-table
                    id="v-step-3"
                    :headers="headers"
                    :items="stockInList"
                    :search="search"
                    class="px-3 table-striped table-bordered dt-responsive"
                  >
                    <template #top>
                      <v-row class="d-flex align-start justify-start mt-2">
                        <v-col
                          class="d-flex justify-center align-center"
                          cols="7"
                        >
                          <v-text-field
                            v-model="search"
                            clear-icon="mdi-close-circle-outline"
                            clearable
                            label="Search"
                            prepend-inner-icon="mdi-magnify"
                            solo
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <template #item.productId="{ item }">
                      {{ getProductById(item.productId).productName }}
                    </template>
                    <template #item.price="{ item }">
                      {{ currency + ' ' + formatNumber(item.price) }}
                    </template>
                    <template #item.expiryDate="{ item }">
                      {{ getDaysToExpire(item.expiryDate) }}
                    </template>
                    <template #item.alias="{item}">
                      {{ getAlias(item) }}
                    </template>
                    <template #item.qty="{item}">
                      {{ getTotalQty(item) }} {{ getUnit(item) }}
                    </template>
                    <template #item.minQty="{item}">
                      {{ item.minQty.toFixed(2) }}
                      {{ item.minQtyUnit }}
                    </template>
                    <template #item.action="{ index }">
                      <v-icon
                        small
                        @click="deleteFromStockInList(index)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row class="d-flex align-center justify-center">
                <v-btn
                  :disabled="stockInList.length === 0"
                  :loading="saveStockLoader"
                  class="mt-10 primary"
                  @click="stockIn"
                >
                  Save & Update Stock
                </v-btn>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="1500"
      color="green"
    >
      {{ snackText }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import BulkImportDialog from './components/BulkImportDialog'
import ProductDialog from '../Inventory/components/ProductDialog'
import moment from 'moment'
import { getHintValue } from '@/utilities/convertToGramsUtil'
import apiMixin from '../../utilities/mixins/apiMixin'
import { getTotalQty, getUnit } from '@/utilities/unitConverter'
import { numberFormatter } from '@/utilities/numberFormatter'

export default {
  name: 'StockIn',
  components: {
    ProductDialog,
    BulkImportDialog

  },
  mixins: [apiMixin],
  data () {
    return {
      valid: false,
      priceRule: [(v) => !!v || 'Price is required', (v) => v > 0 || 'Invalid value'],
      qtyRules: [(v) => !!v || 'Quantity is required', (v) => v > 0 || 'Invalid value'],
      ingredientRule: [(v) => !!Object.keys(v).length > 0 || 'Ingredient is required'],
      productRule: [(v) => !!Object.keys(v).length > 0 || 'Product is required'],
      unitType: 'g',
      errorSnackBar: false,
      snackbar: false,
      snackText: '',
      addDialog: false,
      stockInQuantity: 0,
      minQty: 0,
      price: 0,
      barcodeId: '',
      expiryDate: null,
      selectedIngredient: {},
      selectedProduct: {},
      stockInList: [],
      search: '',
      headers: [
        {
          text: 'Item Name',
          align: 'start',
          sortable: true,
          value: 'ingredientName'
        },
        {
          text: 'Local Alias',
          sortable: true,
          value: 'alias'
        },
        {
          text: 'Product Name',
          sortable: true,
          value: 'productId'
        },
        {
          text: 'Days to Expiry',
          sortable: true,
          value: 'expiryDate'
        },
        {
          text: 'Qty to stock in',
          sortable: true,
          value: 'qty'
        },
        {
          text: 'Minimum Quantity',
          sortable: true,
          value: 'minQty'
        },
        {
          text: 'Price',
          sortable: true,
          value: 'price'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action'
        }
      ],
      links: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/dashboard'
        },
        {
          text: 'Stock In',
          disabled: false,
          href: '/stock-in'
        }
      ],
      saveStockLoader: false,
      expiryDateRule: [
        (value) => {
          if (this.expiryDate && moment(this.expiryDate).isBefore(moment().format(), 'day')) {
            return 'Expiry date cannot be earlier than current date'
          }
          if (this.expiryDate && moment(this.expiryDate).isAfter(moment().add(1, 'year').format())) {
            return 'Expiry date cannot be 1 year later than current date'
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ingredientList: 'inventory/getIngredientsByCompany',
      getProductsByIngredientId: 'inventory/getProductsByIngredientId',
      getIngredientById: 'inventory/getIngredientById',
      getBrandById: 'inventory/getBrandById',
      getProductById: 'inventory/getProductById',
      getUserInfoByKey: 'auth/getUserInfoByKey'
    }),
    currency () {
      return this.getUserInfoByKey('currency')
    }
  },
  watch: {},
  created () {

  },
  destroyed () {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy()
  },
  async mounted () {
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned)
    // this.$tours.stockin.start()
  },
  methods: {
    formatNumber (price) {
      return numberFormatter(price)
    },
    getAlias (item) {
      if (item.alias && item.alias.length > 0) {
        return item.alias.join(', ')
      } else {
        return ''
      }
    },
    closeDialog () {
      this.stockInQuantity = 0
      this.price = 0
      this.barcodeId = ''
      this.expiryDate = ''
      this.selectedIngredient = {}
      this.selectedProduct = {}
      this.addDialog = false
    },
    openEditProductDialog (product) {
      this.$refs.productDialog.dialogType = 'edit'
      this.$refs.productDialog.openDialog(JSON.parse(JSON.stringify(product)))
    },
    getAvailableQty () {
      if (!this.selectedIngredient) {
        return ''
      }
      return `Total Available Qty : ${this.getTotalQty(this.selectedIngredient)} ${this.getUnit(this.selectedIngredient)}`
    },
    getHintText () {
      return `${getHintValue(this.unitType, this.stockInQuantity, this.selectedIngredient)}`
    },
    convertedValue (unitType, qty) {
      try {
        if (unitType.toLowerCase() === 'count') {
          return parseInt(qty)
        }
        if (unitType.toLowerCase() === 'dozen') {
          return parseInt(qty * 12)
        }
        return parseFloat(qty) * parseFloat(this.selectedIngredient.uom.find(unit => unit.unit.toLowerCase() === unitType.toLowerCase()).weight)
      } catch (e) {
        return qty
      }
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned (barcode) {
      this.getScannedProduct(barcode)
      // do something...
    },
    async getScannedProduct (barcode) {
      let product = {}
      if (barcode) {
        product = await this.$store.dispatch(
          'stockIn/fetchProductByBarcodeId',
          barcode
        )
        if (Object.keys(product).length > 0) {
          this.selectedIngredient = this.getIngredientById(product.ingredientId)
          this.selectedProduct = product
          this.addDialog = true
        }
      }
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode () {
      this.$barcodeScanner.getPreviousCode()
      // do something...
    },
    getDaysToExpire (date) {
      if (date) {
        const expiryDate = moment(date, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'M-D-YYYY', 'D-M-YYYY', 'M-DD-YYYY', 'DD-M-YYYY'
        ])
        if (!expiryDate.isValid()) {
          return 'NA'
        } else {
          const current = moment().startOf('day')
          // Difference in number of days
          return moment.duration(expiryDate.diff(current)).asDays()
        }
      }
      return 'NA'
    },
    getTotalQty (ingredient) {
      if (ingredient) {
        return getTotalQty(ingredient)
      } else {
        return 0
      }
    },
    getUnit (ingredient) {
      if (ingredient) {
        return getUnit(ingredient)
      } else {
        return 'NA'
      }
    },
    stockIn () {
      this.saveStockLoader = true
      this.stockInList.forEach(data => delete data.alias)
      this.$store.dispatch('stockIn/addStockInItem', this.stockInList)
      this.stockInList = []
      this.saveStockLoader = false
      this.snackText = 'Ingredients successfully stocked in.'
      this.snackbar = true
    },
    deleteFromStockInList (index) {
      this.stockInList.splice(index, 1)
    },
    addToStockList () {
      if (
        this.$refs.form.validate()
      ) {
        const stockInItem = {}
        stockInItem.id = uuidv4()
        stockInItem.type = 'STOCK_IN'
        stockInItem.totalQty = this.convertedValue(this.unitType, this.stockInQuantity)
        stockInItem.qty = this.convertedValue(this.unitType, this.stockInQuantity)
        stockInItem.rawQty = this.stockInQuantity
        stockInItem.unit = this.unitType
        stockInItem.minQtyUnit = this.selectedIngredient.minQtyUnit
        stockInItem.expiryDate = this.expiryDate !== null ? this.expiryDate : ''
        stockInItem.productId = this.selectedProduct.productId
        stockInItem.ingredientId = this.selectedIngredient.ingredientId
        stockInItem.ingredientName = this.selectedIngredient.ingredientName
        stockInItem.price = this.price
        stockInItem.alias = this.selectedIngredient.alias
        stockInItem.minQty = this.selectedIngredient.minQty
        stockInItem.measure_type = this.selectedIngredient.measure_type
        this.stockInList.push(stockInItem)
        this.selectedIngredient = ''
        this.selectedProduct = ''
        this.expiryDate = null
        this.addDialog = false
      }
    }
  }
}
</script>

<style scoped></style>
