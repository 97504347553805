<template>
  <v-tour
    v-if="onboardingStatus('stockoutOnboarding')"
    name="stockout"
    :options="options"
    :steps="steps"
    :callbacks="callbacks"
  />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'StockOut',
  data () {
    return {
      callbacks: {
        onFinish: this.finishOnboarding,
        onSkip: this.finishOnboarding
      },
      options: {
        useKeyboardNavigation: true,
        startTimeout: 1000,
        labels: {
          buttonSkip: 'Skip',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish'
        }
      },
      steps: [
        {
          target: '#v-step-0',
          header: {
            title: 'Click to stock out'
          },
          content: 'Fill in the ingredient, meal type and quantity to record a stock out',
          params: {
            placement: 'right',
            enableScrolling: false
          }
        },
        {
          target: '#v-step-1',
          header: {
            title: 'Stock out many items at once!'
          },
          content: 'Click on bulk stockout, download the csv and provide details of items to be stocked out',
          params: {
            placement: 'left',
            enableScrolling: false
          }
        },
        {
          target: '#v-step-2',
          header: {
            title: 'A final look at what products are being stocked out!'
          },
          content: 'After manual stock-out, the product will appear in this list for a quick glance before saving',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
      onboardingStatus: 'config/getOnboardingStatus'
    })
  },
  mounted: function () {

  },
  methods: {
    async finishOnboarding () {
      await this.$store.dispatch('config/finishOnboarding', {
        key: 'stockoutOnboarding',
        companyId: this.user.company_name_slug + '_' + this.user.company_id
      })
    }
  }
}
</script>

<style scoped>

</style>
