<template>
  <v-row no-gutters>
    <v-select
      :items="remarkList"
      :loading="remarkLoader"
      :rules="remarkRule"
      :value="value"
      eager
      item-text="remarkName"
      item-value="remarkId"
      label="Remark*"
      outlined
      required
      @input="$emit('input', $event)"
    >
      <template
        v-if="canAccess"
        #append-item
      >
        <v-btn
          block
          @click="openAddRemarkDialog()"
        >
          + Add new remark
        </v-btn>
      </template>
      <template #item="{ index, item }">
        <v-list-item>
          {{ item.remarkName }}
        </v-list-item>
        <v-spacer />
        <v-list-item-action
          v-if="canAccess"
          @click.stop
        >
          <v-btn
            icon
            @click.stop.prevent="edit(index, item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </v-select>
    <v-dialog
      v-model="dialogEdit"
      max-width="500px"
      @keydown.esc="dialogEdit = false"
    >
      <v-card>
        <v-card-title>
          {{
            editingType === "new" ? "Add Remark" : "Edit Remark"
          }}
        </v-card-title>
        <v-card-text>
          <v-form
            ref="editingRemarkForm"
            v-model="valid"
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="editingRemark.remarkName"
                :rules="nameRules"
                autofocus
                label="Remark"
                outlined
                required
              />
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="dialogEdit = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="editingType === 'new' ? saveRemark() : updateRemark()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'

export default {
  name: 'RemarkDialog',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    nameRules: [(v) => !!v || 'Name is required'],
    remarkRule: [(v) => !!v || 'Remark is required'],
    valid: true,
    editingRemark: {},
    editingType: 'new',
    dialogEdit: false,
    remarkLoader: false
  }),
  computed: {
    canAccess () {
      // const key = this.getUserInfoByKey('admin_type')
      return true
    },
    ...mapGetters({ remarkList: 'stockOut/getAllRemarks', getUserInfoByKey: 'auth/getUserInfoByKey' })
  },
  mounted () {
    this.fetchRemarks()
  },
  methods: {
    async updateRemark () {
      this.remarkLoader = true
      await this.$store.dispatch('stockOut/updateRemark', this.editingRemark)
      this.remarkLoader = false
      this.dialogEdit = false
    },
    async fetchRemarks () {
      this.remarkLoader = true
      await this.$store.dispatch('stockOut/fetchAllRemarks')
      this.remarkLoader = false
    },
    edit (index, item) {
      this.editingType = 'edit'
      this.editingRemark = item
      this.dialogEdit = true
    },
    async saveRemark () {
      this.remarkLoader = true
      try {
        if (this.$refs.editingRemarkForm.validate()) {
          await this.$store.dispatch('stockOut/addRemark', this.editingRemark)
          this.reset()
          this.dialogEdit = false
        }
        this.remarkLoader = false
      } catch (e) {
        this.remarkLoader = false
      }
    },
    reset () {
      this.$refs.editingRemarkForm.reset()
    },
    openAddRemarkDialog () {
      this.editingType = 'new'
      this.editingRemark = {}
      this.editingRemark.remarkName = ''
      this.editingRemark.remarkId = uuidv4()
      this.dialogEdit = true
    }
  }
}
</script>

<style scoped></style>
