<template>
  <v-tour
    v-if="onboardingStatus('stockinOnboarding')"
    name="stockin"
    :options="options"
    :steps="steps"
    :callbacks="callbacks"
  />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Stockin',
  data () {
    return {
      callbacks: {
        onFinish: this.finishOnboarding,
        onSkip: this.finishOnboarding
      },
      options: {
        useKeyboardNavigation: true,
        startTimeout: 1000,
        labels: {
          buttonSkip: 'Skip',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish'
        }
      },
      steps: [
        {
          target: '#v-step-0',
          header: {
            title: 'Scan barcode for quick stock in!'
          },
          content: 'Product details will appear on successful barcode scan',
          params: {
            placement: 'top',
            enableScrolling: false
          }
        },
        {
          target: '#v-step-1',
          header: {
            title: 'Manual Stock In'
          },
          content: 'Fill in product details, price , date of expiry and quantity to record a stock in',
          params: {
            placement: 'right',
            enableScrolling: false
          }
        },
        {
          target: '#v-step-2',
          header: {
            title: 'Stock in many items at once!'
          },
          content: 'Click on bulk stockin, download the csv and provide details of items to be stocked in',
          params: {
            placement: 'left',
            enableScrolling: false
          }
        },
        {
          target: '#v-step-3',
          header: {
            title: 'A final look at what products are being stocked in!'
          },
          content: 'After manual stock-in, the product will appear in this list for a quick glance before saving',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
      onboardingStatus: 'config/getOnboardingStatus'
    })
  },
  mounted: function () {

  },
  methods: {
    async finishOnboarding () {
      await this.$store.dispatch('config/finishOnboarding', {
        key: 'stockinOnboarding',
        companyId: this.user.company_name_slug + '_' + this.user.company_id
      })
    }
  }
}
</script>

<style scoped>

</style>
