import configureMeasurements from 'convert-units'

function volumeToML (unitType, qty) {
  const converter = configureMeasurements(qty || 0)
  return converter.from(unitType).to('ml').toFixed(2)
}

function weightToGram (unitType, qty) {
  const converter = configureMeasurements(qty || 0)
  return converter.from(unitType).to('g').toFixed(2)
}

function convertToNewUnit (measureType, newUnit, qty) {
  const converter = configureMeasurements(qty || 0)
  let unit = ''
  if (measureType === 'V') {
    if (isMass(newUnit)) {
      newUnit = 'ml'
    }
    unit = 'ml'
  } else {
    unit = 'g'
  }
  return converter.from(unit).to(newUnit).toFixed(2)
}

function isMass (unitType) {
  const values = configureMeasurements().possibilities('mass')
  return values.includes(unitType)
}

function gramToKilogram (qty) {
  const converter = configureMeasurements(qty || 0)
  return converter.from('g').to('kg').toFixed(2)
}

function mlToLitre (qty) {
  const converter = configureMeasurements(qty || 0)
  return converter.from('ml').to('l').toFixed(2)
}

function getHintValue (unitType, qty, selectedIngredient) {
  if (unitType.toLowerCase() === 'count' || unitType.toLowerCase() === 'dozen') {
    return `The value that will be added is ${qty} ${unitType.toLowerCase()}`
  } else {
    let measurementWeight = 0
    let totalGrams = 0
    if (typeof selectedIngredient !== 'undefined' && selectedIngredient.uom && selectedIngredient.uom.length > 0) {
      const selectedUom = selectedIngredient.uom.find(unit => unit.unit.toLowerCase() === unitType.toLowerCase())
      if (typeof selectedUom !== 'undefined') {
        measurementWeight = selectedUom.weight
      }
      totalGrams = qty * parseFloat(measurementWeight)
    }
    return `The value that will be added is ${getTotalQty(selectedIngredient.measure_type, totalGrams)} ${getUnit(selectedIngredient.measure_type, totalGrams)}`
  }
}
function getTotalQty (measureType, qty) {
  if (measureType === 'C') {
    return qty
  }
  if (measureType === 'W') {
    if (qty >= 1000) {
      return gramToKilogram(qty)
    } else {
      return qty
    }
  } else {
    if (qty >= 1000) {
      return mlToLitre(qty)
    } else {
      return qty
    }
  }
}
function getUnit (measureType, qty) {
  // console.log(measureType, qty)
  if (measureType === 'C') {
    return 'Count'
  }
  if (measureType === 'W') {
    if (qty >= 1000) {
      return 'kg'
    } else {
      return 'gram'
    }
  } else {
    if (qty >= 1000) {
      return 'liter'
    } else {
      return 'ml'
    }
  }
}

function getRemoveHintValue (unitType, qty) {
  if (unitType.toLowerCase() === 'count' || unitType.toLowerCase() === 'dozen') {
    return `The value that will be removed is ${qty} ${unitType.toLowerCase()}`
  } else {
    return `The value that will be removed is ${qty} ${configureMeasurements().describe(unitType).plural || 'NA'}`
  }
}

export {
  volumeToML,
  getHintValue,
  weightToGram,
  gramToKilogram,
  mlToLitre,
  convertToNewUnit,
  getRemoveHintValue,
  isMass,
  getTotalQty,
  getUnit
}
